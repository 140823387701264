import React from 'react';

import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';

import { ProtocolLoader } from 'client/app/apps/protocols/dialogs/ProtocolDetailsDialog/ProtocolLoader';
import Colors from 'common/ui/Colors';
import { DialogProps } from 'common/ui/hooks/useDialog';

type Props = DialogProps<void> & { id: ProtocolId; version: ProtocolVersion };

export function ProtocolDetailsDialog({ isOpen, onClose, id, version }: Props) {
  return (
    <StyledDialog
      open={isOpen}
      onClose={() => onClose()}
      maxWidth="lg"
      fullWidth
      TransitionProps={{
        unmountOnExit: true,
      }}
    >
      <ProtocolLoader id={id} version={version} onClose={onClose} />
    </StyledDialog>
  );
}

const StyledDialog = styled(Dialog)(({ theme: { spacing } }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: Colors.GREY_5,
    padding: spacing(6),
    height: '100%',
  },
}));
