import React, { useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { TypographyProps } from '@mui/material/Typography';

import DisplayName from 'client/app/apps/protocols/annotations/DisplayName';
import { NameEditor } from 'client/app/apps/protocols/annotations/NameEditor';

type Props = Pick<TypographyProps, 'sx'> & {
  name: string;
  /** tooltip to show when not editing the name */
  tooltip?: string;
  onSave: (newName: string) => void;
};

/**
 * Shows the name of something with an icon to edit
 */
export default function EditableDisplayName({ sx, name, tooltip, onSave }: Props) {
  const [isEditing, setIsEditing] = useState(false);

  const handleSave = (value: string) => {
    setIsEditing(false);
    onSave(value);
  };

  return (
    <EditableStack direction="row">
      {!isEditing ? (
        <>
          <DisplayName sx={sx} name={name} tooltip={tooltip} />
          <IconButton onClick={() => setIsEditing(true)}>
            <StyledEditIcon />
          </IconButton>
        </>
      ) : (
        <NameEditor name={name} onSave={handleSave} sx={sx} />
      )}
    </EditableStack>
  );
}

const EditableStack = styled(Stack)({
  flexShrink: 1,
  minWidth: 0,
  width: '100%',
  alignItems: 'baseline',
});

const StyledEditIcon = styled(EditIcon)(({ theme: { spacing, palette } }) => ({
  width: spacing(5),
  height: spacing(5),
  color: palette.text.primary,
}));
