import React, { useMemo, useState } from 'react';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import DeviceParams from 'client/app/apps/simulation-details/instructions/DeviceParams';
import DilutionTable from 'client/app/apps/simulation-details/instructions/DilutionTable';
import PlateMap from 'client/app/apps/simulation-details/instructions/PlateMap';
import { SerialDilutionDirectionIndicator } from 'client/app/apps/simulation-details/instructions/SerialDilutionDirectionIndicator';
import { GroupsByStep } from 'client/app/apps/simulation-details/instructions/types';
import { PlateType } from 'common/types/plateType';
import { LayerPlate, Step, WellState } from 'common/types/steps';
import Tabs, { TabsInfo } from 'common/ui/components/Tabs';
import { useTrackActive } from 'common/ui/components/TrackActive';

type Props = {
  step: Step;
  plates: Record<string, PlateType>;
  numbers?: Record<string, number>;
  showConcentration: (well: WellState) => boolean;
  groups: GroupsByStep;
  isPrint?: boolean;
};

enum Tab {
  DILUTION_TABLE,
  PLATE_MAPS,
}

const tabsInfo: TabsInfo<Tab> = [
  {
    label: 'Plate Map',
    value: Tab.PLATE_MAPS,
  },
  {
    label: 'Dilution Table',
    value: Tab.DILUTION_TABLE,
  },
];

const DEFAULT_STEP_NUMBERS = { '': 1 };

export default function InstructionsStep({
  step,
  plates,
  numbers = DEFAULT_STEP_NUMBERS,
  showConcentration,
  groups,
  isPrint = false,
}: Props) {
  const { register } = useTrackActive();

  const [activeTab, setActiveTab] = useState(Tab.PLATE_MAPS);
  const isRunDevice = step.type === 'runDevice';
  const isDilution = step.type === 'normalization' || step.type === 'directDilution';

  const elementGroup = groups[step.id]?.element;
  const repeatGroup = groups[step.id]?.repeat;
  const isLastInRepeat = repeatGroup?.memberIds.at(-1) === step.id;

  const titles =
    elementGroup?.memberIds.length === 1
      ? { name: elementGroup.name, subname: step.name }
      : { name: step.name };

  const layersForDisplay = useStepLayers(step);
  const plateMaps = layersForDisplay.map((layer, layerIndex) =>
    Object.entries(layer.plates).map(([plate, content]) => (
      <React.Fragment key={plate}>
        {isRunDevice && <DeviceParams params={layer.deviceParams} />}

        <PlateMap
          name={plate}
          plate={plates[content.id]}
          content={content}
          showConcentration={showConcentration}
          showLocalSources={step.type === 'serialDilution'}
          gridDecorators={
            step.type === 'serialDilution' && layerIndex === 1 ? (
              <SerialDilutionDirectionIndicator
                plate={content}
                direction={step.meta?.direction}
              />
            ) : undefined
          }
          isPrint={isPrint}
        />
      </React.Fragment>
    )),
  );

  const allTabsPrintContent = (
    <>
      {plateMaps}
      {isDilution && (
        <>
          <Typography variant="h3">Dilution Table for Step {numbers[step.id]}</Typography>
          <DilutionTable step={step} />
        </>
      )}
    </>
  );
  let activeTabContent: React.ReactNode | null = null;

  switch (activeTab) {
    case Tab.DILUTION_TABLE: {
      activeTabContent = <DilutionTable step={step} />;
      break;
    }
    case Tab.PLATE_MAPS:
    default: {
      activeTabContent = plateMaps;
      break;
    }
  }

  return (
    <StepWrapper id={step.id} ref={register}>
      <Typography variant="h1">
        Step {numbers[step.id]}: {titles.name}
        {titles.subname && <StepType variant="body2">{titles.subname}</StepType>}
      </Typography>

      {step.type === 'prompt' && step.meta?.message && (
        <Typography variant="h5">{step.meta?.message}</Typography>
      )}

      {isDilution && !isPrint && (
        <Tabs tabsInfo={tabsInfo} activeTab={activeTab} onChangeTab={setActiveTab} />
      )}

      {isPrint ? allTabsPrintContent : activeTabContent}
      {isLastInRepeat && (
        <Typography variant="h1">
          Repeat {repeatGroup.meta.repeats} times from Step{' '}
          {numbers[repeatGroup.memberIds[0]]}
        </Typography>
      )}
    </StepWrapper>
  );
}

function useStepLayers(step: Step) {
  return useMemo(() => {
    // Serial dilution steps separate each transfer into a separate layer,
    // but we want to combine them for display purposes.
    if (step.type === 'serialDilution') {
      const [diluentLayer, ...dilutedLayers] = step.layers;

      const mergedDilutedLayer = dilutedLayers.reduce((acc, curr) => ({
        ...acc,
        plates: mergePlateMaps(acc.plates, curr.plates),
      }));

      return [diluentLayer, mergedDilutedLayer];
    }

    return step.layers;
  }, [step.layers, step.type]);
}

export function mergePlateMaps(
  a: Record<string, LayerPlate>,
  b: Record<string, LayerPlate>,
) {
  const names = new Set([...Object.keys(a), ...Object.keys(b)]);

  const result: Record<string, LayerPlate> = {};

  names.forEach(name => {
    result[name] = {
      ...(a[name] ?? b[name]),
      wells: {
        ...a[name]?.wells,
        ...b[name]?.wells,
      },
    };
  });

  return result;
}

const StepWrapper = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(6),

  '@media print': {
    pageBreakAfter: 'always',
  },
}));

const StepType = styled(Typography)(({ theme: { palette, spacing } }) => ({
  color: palette.text.secondary,
  marginTop: spacing(3),
}));
