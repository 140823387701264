import { sanitize } from 'dompurify';
import produce from 'immer';
import entries from 'lodash/entries';
import groupBy from 'lodash/groupBy';

import { State } from 'client/app/apps/policy-library/components/PolicyDialogStateContext';
import {
  addColumn,
  getTableStateFromConfig,
  initDataTable,
  initTableConfig,
} from 'client/app/apps/policy-library/components/ruleTableConfig';
import {
  allConditions,
  allConsequences,
  getAllowedUnits,
  RuleMetaKey,
} from 'client/app/apps/policy-library/RulesMeta';
import {
  ConditionParameter,
  ConditionParser,
} from 'client/app/apps/policy-library/validations/';
import {
  ConsequenceParameter,
  ConsequenceParser,
} from 'client/app/apps/policy-library/validations/ConsequenceParser';
import formatRuleConsequenceValueRecursive from 'client/app/components/Parameters/Policy/components/formatRuleConsequenceValueRecursive';
import { ArrayElement, LiquidPolicy, LiquidPolicyInput } from 'client/app/gql';
import { formatMeasurementObj, getOrderOperatorExpression } from 'common/lib/format';
import { LIQUID_TYPE_VAR, RULE_NAME_FIELD } from 'common/lib/liquidPolicies';
import { Rule } from 'common/types/mix';
import { CellValue, Row } from 'common/types/spreadsheetEditor';

export const RULES_TABLE_UPDATE_DEBOUNCE_MS = 500;

export { buildPolicyInput, buildPolicyState, getConditionCellValue };

/**
 * Transforms dialog state for Liquid Policy into a graphql input where `rule`
 * are compatible with the `customPolicyRuleSet` object format
 */
function buildPolicyInput(policy: State['policy']): LiquidPolicyInput {
  if (!policy.name.value) {
    throw new Error('Liquid policy name is not defined');
  }
  if (!policy.description.value) {
    throw new Error('Liquid policy description is not defined');
  }
  const policyName = sanitize(policy.name.value.trim());
  const policyDesc = sanitize(policy.description.value.trim());
  const rules = buildRules(policy.rules.dataTable.data, policyName);

  return {
    name: policyName,
    description: policyDesc,
    rules,
  };
}

/**
 * Transforms rules from DataTable into format compatible with `customPolicyRuleSet`
 */
function buildRules(rows: Row[], policyName: string): Rule[] {
  return rows.map(row => {
    const name = row[RULE_NAME_FIELD] as string;
    const conditions: Rule['conditions'] = [
      {
        variable: LIQUID_TYPE_VAR,
        values: [policyName],
      },
    ];
    const consequences: Rule['consequences'] = {};

    for (const key in row) {
      const cellValue = row[key];

      if (cellValue == null) continue;

      // Process conditions
      if (key in allConditions) {
        const allowedUnits = getAllowedUnits(key);
        const parser = new ConditionParser(key as ConditionParameter, allowedUnits);
        const result = parser.parse(cellValue as string);
        conditions.push(...result);

        // Process consequences
      } else if (key in allConsequences) {
        const parser = new ConsequenceParser(key as ConsequenceParameter);
        const result = parser.parse(cellValue);
        consequences[key] = result;
      }
    }
    return {
      name,
      source: 'user',
      conditions,
      consequences,
    };
  });
}

/**
 * Transforms LiquidPolicy object into the EditorDialog input
 */
function buildPolicyState(policy: LiquidPolicy): State['policy'] {
  const sortedRules = [...policy.rules].sort((a, b) => a.ruleOrder - b.ruleOrder);
  return {
    name: {
      value: policy.name,
      error: undefined,
    },
    description: {
      value: policy.description,
      error: undefined,
    },
    rules: buildRulesTable(sortedRules.map(r => r.ruleData)),
  };
}

/**
 * Transforms liquid policy rules into a `DataTable` with `TableConfiguration` and state
 * @param rules sorted rules of the given liquid policy
 */
function buildRulesTable(rules: Rule[]): State['policy']['rules'] {
  const { dataTable, tableConfig } = produce(
    { dataTable: initDataTable, tableConfig: initTableConfig },
    draft => {
      draft.dataTable.data = rules.map(rule => {
        const row: Record<string, CellValue> = { [RULE_NAME_FIELD]: rule.name };

        // Start populating with conditions
        entries(
          groupBy(
            rule.conditions?.filter(condition => condition.variable !== LIQUID_TYPE_VAR),
            'variable',
          ),
        ).forEach(([variable, conditions]) => {
          const existConditionColumn = draft.dataTable.schema.fields.some(
            field => field.name === variable,
          );

          if (!existConditionColumn) {
            addColumn({
              table: draft.dataTable,
              config: draft.tableConfig,
              column: {
                type: 'condition',
                name: variable as RuleMetaKey,
              },
            });
          }
          row[variable] = getConditionCellValue(conditions);
        });

        // Populate consequences
        entries(rule.consequences).forEach(([name, value]) => {
          const existConsequenceColumn = draft.dataTable.schema.fields.some(
            field => field.name === name,
          );

          if (!existConsequenceColumn) {
            addColumn({
              table: draft.dataTable,
              config: draft.tableConfig,
              column: {
                type: 'consequence',
                name: name as RuleMetaKey,
              },
            });
          }

          let cellValue: CellValue;
          if (typeof value === 'object') {
            cellValue = formatRuleConsequenceValueRecursive(value);
          } else {
            cellValue = value;
          }
          row[name] = cellValue;
        });

        return row;
      });
    },
  );

  return {
    dataTable,
    tableConfig,
    tableState: getTableStateFromConfig(tableConfig),
  };
}

/**
 * Combines conditions into a single string value for the table cell.
 *
 * There might be multiple conditions related to the same condition variable:
 *
 * e.g. `destination_maxvolume: >= 50ul & <= 100ul`
 *
 * equals to
 *
 * ```
 * [
 *  { variable: "destination_maxvolume", value: { value: 50, unit: "ul" }, operator: "gte" },
 *  { variable: "destination_maxvolume", value: { value: 100, unit: "ul" }, operator: "lte" }
 * ]
 * ```
 */
function getConditionCellValue(conditions: NonNullable<Rule['conditions']>): CellValue {
  if (conditions.length > 1) {
    return conditions.map(mapSingleConditionValue).join(' & ');
  }
  return mapSingleConditionValue(conditions[0]);
}

function mapSingleConditionValue(
  condition: ArrayElement<NonNullable<Rule['conditions']>>,
) {
  if (condition.value && condition.operator) {
    if (typeof condition.value === 'number') {
      return `${getOrderOperatorExpression(condition.operator)} ${condition.value}`;
    }
    return `${getOrderOperatorExpression(condition.operator)} ${formatMeasurementObj(
      condition.value,
    )}`;
  }
  if (condition.values) {
    return condition.values.join(' ; ');
  }
  return null;
}
