import { useCallback, useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import { useQueryParam } from 'use-query-params';

import { MUTATION_CREATE_WORKFLOW_FROM_EXAMPLE } from 'client/app/api/gql/mutations';
import ExampleWorkflowsDialog from 'client/app/components/ExampleWorkflows/Dialog';
import { workflowRoutes } from 'client/app/lib/nav/actions';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import useDialog from 'common/ui/hooks/useDialog';

export const LSKEY_HIDE_EXAMPLE_WORKFLOWS_DIALOG_ON_NEW_WORKFLOW =
  'hide_example_workflows_dialog_on_new_workflow';
export const OPEN_EXAMPLE_WORKFLOWS_DIALOG_PARAM = 'open_example_workflows_dialog';

/**
 * Handles the logic to determine whether to open the ExampleWorkflowsDialog
 * as part of the Builder journey, using the OPEN_EXAMPLE_WORKFLOWS_DIALOG_PARAM
 * URL param.
 *
 * @returns The dialog component to return within the caller's JSX.
 */
export function useLaunchExampleWorkflowsDialogInBuilder(
  isLoadingWorkflow: boolean,
  isReadOnly: boolean,
  shouldShowConfirmationDialog?: boolean,
): {
  exampleWorkflowsDialog: JSX.Element | null;
} {
  const [openExampleDialog, setOpenExampleDialog] = useQueryParam<boolean | undefined>(
    OPEN_EXAMPLE_WORKFLOWS_DIALOG_PARAM,
  );
  const [exampleWorkflowsDialog, openExampleWorkflowsDialog] =
    useDialog(ExampleWorkflowsDialog);

  const isEnabledQuickStartActivation = useFeatureToggle('QUICK_START_ACTIVATION');

  useEffect(() => {
    if (!isEnabledQuickStartActivation) {
      return;
    }

    if (isLoadingWorkflow) {
      return;
    }

    if (isReadOnly) {
      // We don't open on readonly workflows, so if someone has added this param, or shared a link
      // to a workflow that includes that param, we will remove it.
      setOpenExampleDialog(undefined, 'replaceIn');
      return;
    }

    const openDialog = async () => {
      await openExampleWorkflowsDialog({
        shouldShowConfirmationDialog,
      });
    };

    if (openExampleDialog) {
      void openDialog();
    }
  }, [
    isEnabledQuickStartActivation,
    isLoadingWorkflow,
    isReadOnly,
    openExampleDialog,
    openExampleWorkflowsDialog,
    setOpenExampleDialog,
    shouldShowConfirmationDialog,
  ]);

  return {
    exampleWorkflowsDialog: isEnabledQuickStartActivation ? exampleWorkflowsDialog : null,
  };
}

/**
 * Creates a copy of the source workflow from an example workflow and navigates to that new
 * workflow in the Builder.
 *
 * @param exampleWorkflowId The example workflow id from which to copy the source workflow.
 * @param onWorkflowNavigation Handler that will run on successful navigation to the created worklfow.
 * @param shouldShowConfirmationDialog Show a confirmation dialog before creating the new workflow.
 * @returns
 */
export function useCreateWorkflowFromExampleAndNavigate(
  exampleWorkflowId: ExampleWorkflowId,
  onWorkflowNavigation?: () => void,
  shouldShowConfirmationDialog?: boolean,
): {
  handleCreateWorkflowFromExample: () => void;
  busy: boolean;
  confirmationDialog: JSX.Element | null;
} {
  const { showError } = useSnackbarManager();
  const navigation = useNavigation();
  const [createWorkflowFromExample, { loading: createWorkflowFromExampleLoading }] =
    useMutation(MUTATION_CREATE_WORKFLOW_FROM_EXAMPLE);

  const [confirmationDialog, openConfirmationDialog] = useDialog(ConfirmationDialog);

  const isBuilderInSavingState = useWorkflowBuilderSelector(state => state.isSaving);

  const [createdWorkflowId, setCreatedWorkflowId] = useState<WorkflowId | undefined>();

  useEffect(() => {
    // Navigation is blocked in Builder component by RouteLeavingGuard when the workflow
    // is saving. This is why the navigation here is performed as a side effect, rather than
    // within the callback below, to ensure we only navigate when workflow is not saving.
    if (createdWorkflowId && !isBuilderInSavingState) {
      navigation.navigate(workflowRoutes.openInWorkflowBuilder, {
        workflowId: createdWorkflowId,
      });
      setCreatedWorkflowId(undefined);
      onWorkflowNavigation?.();
    }
  }, [createdWorkflowId, isBuilderInSavingState, navigation, onWorkflowNavigation]);

  const handleCreateWorkflowFromExample = useCallback(async () => {
    try {
      if (shouldShowConfirmationDialog) {
        const isConfirmed = await openConfirmationDialog({
          action: 'use',
          omitThe: true,
          omitConfirmationQuestion: true,
          confirmButtonLabel: 'Use example',
          cancelButtonLabel: 'Close',
          object: 'example workflow',
          additionalMessage:
            "By using this example, you'll start a new workflow. You can still access your current workflow from the workflows page.",
        });
        if (!isConfirmed) {
          return;
        }
      }

      const { data } = await createWorkflowFromExample({
        variables: {
          exampleId: exampleWorkflowId,
        },
      });

      if (!data?.createWorkflowFromExample.id) {
        throw new Error('Could not create copy of example workflow.');
      }

      setCreatedWorkflowId(data.createWorkflowFromExample.id);
    } catch (error) {
      showError(error.message);
    }
  }, [
    createWorkflowFromExample,
    exampleWorkflowId,
    openConfirmationDialog,
    shouldShowConfirmationDialog,
    showError,
  ]);

  return {
    handleCreateWorkflowFromExample,
    busy: createWorkflowFromExampleLoading || isBuilderInSavingState,
    confirmationDialog,
  };
}
