import React, { useCallback, useState } from 'react';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import InputIcon from '@mui/icons-material/Input';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import ElementParameterFactorSwitch from 'client/app/components/Parameters/ElementParameterFactorSwitch';
import Colors from 'common/ui/Colors';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';

type Props = {
  displayName: string;
  contents?: JSX.Element;
  checked: boolean;
  onCheck: (isChecked: boolean) => void;
  onClick?: () => void;
  isDisabled: boolean;
  otherStepMembership?: string;
};

export function PanelItem({
  displayName,
  contents,
  checked,
  onCheck,
  isDisabled,
  otherStepMembership,
  onClick,
}: Props) {
  const hasContents = contents !== undefined;
  const [viewContents, setViewContents] = useState(false);

  const handleCheckChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => onCheck(e.target.checked),
    [onCheck],
  );

  return (
    <div onClick={onClick}>
      <ListItem
        disableGutters
        secondaryAction={
          <ElementParameterFactorSwitch
            checked={checked}
            onChange={handleCheckChange}
            disabled={isDisabled}
            label={<StyledInputIcon checked={checked} />}
          />
        }
      >
        {hasContents ? (
          <StyledListItemButton
            onClick={() => setViewContents(prev => !prev)}
            disableGutters
          >
            <ExpandIcon isExpanded={viewContents} />
            <Typography sx={{ fontSize: '12px' }}>{displayName}</Typography>
          </StyledListItemButton>
        ) : (
          <ListItem disableGutters>
            <Tooltip title="This parameter has no values to see">
              <HorizontalRuleIcon fontSize="small" color="disabled" />
            </Tooltip>
            <Typography sx={{ fontSize: '12px' }}>{displayName}</Typography>
          </ListItem>
        )}
      </ListItem>
      {otherStepMembership && (
        <HelperText>{`Selected in ${otherStepMembership}`}</HelperText>
      )}
      <Collapse in={viewContents}>{contents}</Collapse>
      <SpacingDivider />
    </div>
  );
}

function ExpandIcon({ isExpanded }: { isExpanded: boolean }) {
  const size = 'small';
  return isExpanded ? <ExpandLess fontSize={size} /> : <ExpandMore fontSize={size} />;
}

type StyledInputIconProps = {
  checked?: boolean;
};

const StyledInputIcon = styled(InputIcon, {
  shouldForwardProp: prop => prop !== 'disabled',
})<StyledInputIconProps>(({ checked }) => ({
  color: checked ? Colors.BACKGROUND : Colors.TEXT_PRIMARY,
}));

const HelperText = styled(TypographyWithTooltip)(({ theme }) => ({
  ...theme.typography.caption,
  padding: theme.spacing(3, 4),
  marginBottom: theme.spacing(3),
  height: '32px',
  width: '100%',
  background: Colors.GREY_10,
  borderRadius: theme.spacing(2),
}));

const StyledListItemButton = styled(ListItemButton)({
  '&:hover': { background: 'none' },
});

const SpacingDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
