import React from 'react';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import {
  DEFAULT_WIDTH,
  MAX_WIDTH,
  MIN_WIDTH,
  useResizablePanel,
} from 'client/app/apps/protocols/ResizableSidePanel/useResizablePanel';

type Props = {
  children: React.ReactNode;
};

const ResizableSidePanel: React.FC<Props> = ({ children }) => {
  const { width, handleMouseDown } = useResizablePanel();

  return (
    <SidePanelWrapper
      sx={{
        width: width,
      }}
    >
      {children}
      <ResizeHandle onMouseDown={handleMouseDown} />
    </SidePanelWrapper>
  );
};

const SidePanelWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: DEFAULT_WIDTH,
  minWidth: MIN_WIDTH,
  maxWidth: MAX_WIDTH,
  transition: 'width 0.1s ease-in-out',
  overflow: 'hidden',
  borderRight: `1px solid ${theme.palette.divider}`,
}));

const ResizeHandle = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  width: '10px',
  height: '100%',
  cursor: 'col-resize',
  zIndex: 100,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

export { ResizableSidePanel };
