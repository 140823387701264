import React, { useCallback } from 'react';

import '@remirror/styles/all.css';

import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { i18nFormat } from '@remirror/i18n';
import { EditorComponent, Remirror, useRemirror } from '@remirror/react';
import { ExtensionPriority } from 'remirror';
import {
  BoldExtension,
  BulletListExtension,
  ItalicExtension,
  LinkExtension,
  ListItemExtension,
  MarkdownExtension,
  OrderedListExtension,
  PlaceholderExtension,
  TrailingNodeExtension,
  UnderlineExtension,
} from 'remirror/extensions';

import { InlineEditorHelp } from 'client/app/components/Markdown/InlineEditorHelp';
import { OnChangeMarkdown } from 'client/app/components/Markdown/OnChangeMarkdown';
import Colors from 'common/ui/Colors';
import { MessagePreviewContainer } from 'common/ui/components/MessagePreview';

type Props = {
  placeholder?: string;
  initialContent: string;
  onChange: (s: string) => void;
  highlightOnHover?: boolean;
};

/**
 * This editor provides stripped down markdown rendering for inline text. For
 * example, it doesn't support tables but does support bold / italics / lists
 */
export function InlineMarkdownEditor({
  placeholder = '',
  initialContent,
  onChange,
  highlightOnHover = false,
}: Props) {
  const extensions = useCallback(
    () => [
      new LinkExtension({ autoLink: true }),
      new PlaceholderExtension({ placeholder }),
      new BoldExtension({}),
      new ItalicExtension(),
      new UnderlineExtension(),
      new TrailingNodeExtension({}),
      new MarkdownExtension({ copyAsMarkdown: false }),
      new BulletListExtension({}),
      new OrderedListExtension(),
      new ListItemExtension({
        priority: ExtensionPriority.High,
        enableCollapsible: true,
      }),
    ],
    [placeholder],
  );
  const { manager } = useRemirror({
    extensions,
    stringHandler: 'markdown',
  });

  return (
    <HighlightContainer highlightOnHover={highlightOnHover} paddingInline={2}>
      <StyledMessageContainer>
        <InlineEditorHelp className="inline-help" />
        <Remirror
          manager={manager}
          i18nFormat={i18nFormat}
          initialContent={initialContent}
          placeholder={placeholder}
        >
          <EditorComponent />
          <OnChangeMarkdown onChange={onChange} />
        </Remirror>
      </StyledMessageContainer>
    </HighlightContainer>
  );
}

const HighlightContainer = styled(Stack, {
  shouldForwardProp: props => props !== 'highlightOnHover',
})<{ highlightOnHover: boolean }>(({ highlightOnHover, theme }) => {
  const hoverOrFocus = highlightOnHover ? '&:focus-within, &:hover' : '&:focus-within';
  return {
    border: '1.5px solid transparent',
    [hoverOrFocus]: {
      border: `1.5px solid ${theme.palette.primary.main}`,
      borderRadius: '4px',
    },
  };
});

const StyledMessageContainer = styled(MessagePreviewContainer)({
  position: 'relative',
  '&:focus-within .inline-help': {
    visibility: 'visible',
  },
  '.remirror-editor [data-placeholder]::before': {
    content: 'attr(data-placeholder)',
    color: Colors.GREY_50,
  },
  '.remirror-editor:focus-within [data-placeholder]::before': {
    content: 'none',
  },
  '.remirror-editor:focus': {
    // this outline is a bit too close to the text and helper icon to be useful
    // so we disable it and instead use our own HighlightContainer with padding
    outline: 'none',
  },
});
