import React, { FC } from 'react';

import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

type Props = {
  onClick: () => void;
};

const AddTagButton: FC<Props> = ({ onClick }) => {
  return (
    <StyledButton size="small" onClick={onClick}>
      <AddIcon />
    </StyledButton>
  );
};

export default AddTagButton;

const StyledButton = styled(IconButton)(({ theme }) => ({
  margin: theme.spacing(0, 4),
  border: 'none',
  boxShadow: 'none',
  transition: 'transform 0.3s ease-in-out, background-color 0.3s',
  '&:hover': {
    transform: 'rotate(90deg) scale(1.1)',
    backgroundColor: theme.palette.action.hover,
  },
}));
