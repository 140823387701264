import React from 'react';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import doNothing from 'common/lib/doNothing';
import IconButtonWithPopper from 'common/ui/components/IconButtonWithPopper';

type Props = { title: string; help?: string };

export const SectionHeading = ({ title, help }: Props) => {
  return help ? (
    <Stack direction="row" alignItems="center">
      <Typography variant="body1" fontWeight={500}>
        {title}
      </Typography>
      <IconButtonWithPopper
        content={<Typography variant="caption">{help}</Typography>}
        iconButtonProps={{ size: 'xsmall', icon: <HelpOutlineIcon /> }}
        onClick={doNothing}
      />
    </Stack>
  ) : (
    <Typography variant="body1" fontWeight={500}>
      {title}
    </Typography>
  );
};
