import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import KeyboardOutlinedIcon from '@mui/icons-material/KeyboardOutlined';

import { useIsWorkflowReadonly } from 'client/app/apps/workflow-builder/lib/isWorkflowReadonly';
import ScreenContext from 'client/app/components/AppRouter/ScreenContext';
import CanvasStages from 'client/app/components/ElementPlumber/CanvasStages';
import HelpDialog from 'client/app/components/ElementPlumber/HelpDialog';
import WorkflowLayout from 'client/app/components/ElementPlumber/WorkflowLayout';
import { getNudgeDelta } from 'client/app/lib/layout/LayoutHelper';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import { Connection } from 'common/types/bundle';
import { Position2d } from 'common/types/Position';
import Workspace from 'common/ui/components/Workspace/Workspace';
import WorkspaceObserver from 'common/ui/components/Workspace/WorkspaceObserver';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import { undo } from 'common/ui/hooks/useUndoReducer';
import Keys from 'common/ui/lib/keyboard';

type Props = {
  status?: React.ReactElement;
  onPositionChange?: (
    pos: Position2d,
    zoom: number,
    width: number,
    height: number,
  ) => void;
  doeTemplateMode?: boolean;
  areElementsLoading: boolean;
  workspaceControlsPortal?: HTMLDivElement | null;
};

export default function ElementPlumber(props: Props) {
  const selectedObjectIds = useWorkflowBuilderSelector(state => state.selectedObjectIds);
  const elementInstances = useWorkflowBuilderSelector(state => state.elementInstances);
  const additionalPanel = useWorkflowBuilderSelector(state => state.additionalPanel);
  const elementGroups = useWorkflowBuilderSelector(state => state.elementGroups);
  const connections = useWorkflowBuilderSelector(state => state.InstancesConnections);
  const stages = useWorkflowBuilderSelector(state => state.stages);
  const selectedStageId = useWorkflowBuilderSelector(state => state.selectedStageId);

  const dispatch = useWorkflowBuilderDispatch();

  const { screenId } = useContext(ScreenContext);

  // Disable input if any of the following are true:
  // * There is a more recent version of this workflow
  // * The user is not the owner of this workflow
  // * The user is in the DOE Template Editor
  // * The workflow is a Form
  const isWorkflowReadonly = useIsWorkflowReadonly();
  const isReadonly = useWorkflowBuilderSelector(
    state => isWorkflowReadonly(state.editMode, state.source) || !!props.doeTemplateMode,
  );
  const mode = useWorkflowBuilderSelector(state => state.mode);

  const [showHelp, setShowHelp] = useState(false);

  const handleShowAll = useCallback(() => {
    logEvent('show-all', screenId as string);
    dispatch({ type: 'snapLayoutToOrigin' });
  }, [dispatch, screenId]);

  const handleShowHelp = useCallback(() => {
    logEvent('open-help-dialog', screenId as string);
    setShowHelp(true);
  }, [screenId]);

  const handleCloseHelp = useCallback(() => {
    logEvent('close-help-dialog', screenId as string);
    setShowHelp(false);
  }, [screenId]);

  const editMode = isReadonly || mode === 'DOE' ? 'readonly' : 'editable';

  const nudgeSelectedElementInstances = useCallback(
    (key: string, goFast: boolean) => {
      const delta = getNudgeDelta(key, goFast);
      if (delta) {
        dispatch({ type: 'nudgeSelectedObjects', payload: delta });
      }
    },
    [dispatch],
  );

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      // Don't respond to events that seem to be targeted at other elements
      // of the UI. The ElementPlumber key event listeners are targeted at
      // window, which uses document.body as their event target. Any other
      // target here would indicate something else in the UI is expecting
      // the key presses (i.e. editing text in an input, etc.).
      if (e.target !== document.body || editMode !== 'editable' || !!additionalPanel) {
        return;
      }

      const isModifierKeySelected = e.ctrlKey || e.metaKey;

      switch (e.key) {
        case Keys.QUESTION_MARK:
          handleShowHelp();
          break;
        case Keys.A:
          if (isModifierKeySelected) {
            dispatch({ type: 'selectAll' });
            e.preventDefault();
          } else {
            // This works in conjunction with the Workspace handler to ensure
            // that the layout is both at (0, 0) and the viewport is zoomed to
            // show all of it.
            dispatch({ type: 'snapLayoutToOrigin' });
          }
          break;
        case Keys.ARROW_UP:
        case Keys.ARROW_RIGHT:
        case Keys.ARROW_DOWN:
        case Keys.ARROW_LEFT:
          nudgeSelectedElementInstances(e.key, e.shiftKey);
          break;

        case Keys.BACKSPACE:
        case Keys.DELETE:
          if (selectedObjectIds.length > 0) {
            logEvent('delete-element-with-keystroke', screenId as string);
          }
          dispatch({ type: 'deleteSelectedObjects' });
          // In some browsers backspace navigates to previous page. This would be very irritating.
          e.preventDefault();
          break;
        case Keys.ESCAPE:
          dispatch({ type: 'deselectAll' });
          break;
        case Keys.Z:
          if (isModifierKeySelected) {
            dispatch(undo());
          }
          break;
        case Keys.G:
          if (isModifierKeySelected) {
            e.preventDefault();
            dispatch({ type: 'createElementGroupFromSelection' });
          }
          break;
        default:
          break;
      }
    },
    [
      editMode,
      additionalPanel,
      handleShowHelp,
      nudgeSelectedElementInstances,
      selectedObjectIds.length,
      dispatch,
      screenId,
    ],
  );

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);

  // Create a reference which will contain the workspace DOM element. This can then be
  // used to prevent dragging workflow elements outside the perimeter of the workspace.
  const boundaryRef = useRef(null);

  const setSelectedObjects = useCallback(
    (selectedClientIDs: string[]) => {
      dispatch({ type: 'setSelectedObjects', payload: selectedClientIDs });
    },
    [dispatch],
  );

  const deselectAll = useCallback(() => {
    dispatch({ type: 'deselectAll' });
  }, [dispatch]);

  const addConnection = useCallback(
    (connection: Connection) => {
      dispatch({ type: 'addConnection', payload: connection });
    },
    [dispatch],
  );

  const toggleSelectedObjects = useCallback(
    (clientIds: string[]) => {
      dispatch({ type: 'toggleSelectedObjects', payload: clientIds });
    },
    [dispatch],
  );

  const visibleArea = useWorkflowBuilderSelector(state => state.visibleCanvasArea);
  const centerArea = useWorkflowBuilderSelector(state => state.centerArea);

  useEffect(() => {
    const x = centerArea?.left;
    const y = centerArea?.top;
    if (x && y) {
      WorkspaceObserver.dispatch('AlignCenter', {
        centerPosition: { x, y },
      });
      // x can be 0 for the first stage - 0 is falsy so we need to guard agains that
    } else if (x !== undefined) {
      WorkspaceObserver.dispatch('MoveToX', {
        x,
      });
    }
  }, [centerArea]);

  const status = !isReadonly ? props.status : undefined;

  return (
    <Workspace
      isShowAllButtonVisible={elementInstances.length > 0 || elementGroups.length > 0}
      isShowHelpButtonVisible
      onShowAll={handleShowAll}
      onShowHelp={handleShowHelp}
      onPositionChange={props.onPositionChange}
      initialShowAll
      logCategory={screenId as string}
      ref={boundaryRef}
      canvasControlVariant="light_float"
      status={status}
      separateStatus={!isReadonly}
      variant="dots"
      disabled={mode === 'DOE'}
      visibleCanvasArea={visibleArea}
      showHelpIcon={<KeyboardOutlinedIcon />}
      showHelpTooltip="Keyboard shortcuts"
      unzoomedContent={
        <CanvasStages
          isReadonly={isReadonly}
          stages={stages}
          selectedStageId={selectedStageId}
        />
      }
      controlsPortal={props.workspaceControlsPortal}
    >
      <WorkflowLayout
        connections={connections}
        elementInstances={elementInstances}
        editMode={editMode}
        boundaryRef={boundaryRef}
        setSelectedObjects={setSelectedObjects}
        deselectAll={deselectAll}
        addConnection={addConnection}
        toggleSelectedObjects={toggleSelectedObjects}
        selectedObjectIds={selectedObjectIds}
        elementGroups={elementGroups}
        areElementsLoading={props.areElementsLoading}
      />

      <HelpDialog open={showHelp} onClose={handleCloseHelp} readonlyMode={isReadonly} />
    </Workspace>
  );
}
