import { graphql } from 'client/app/gql';

export const QUERY_PROTOCOL_DETAILS = graphql(/* GraphQL */ `
  query ProtocolDetails($id: ProtocolId!, $version: ProtocolVersion!) {
    protocol(id: $id, version: $version) {
      entity {
        id
        version
        editVersion
        name
        shortDescription
        protocol
        createdBy {
          id
          displayName
        }
        isDeleted
        isPublished
        isPublic
        exampleSimulation {
          id
          status
          actionsLayoutFiletreeLink
        }
      }
    }
  }
`);
