import React from 'react';

import { styled } from '@mui/material/styles';

import PlateLibrary from 'client/app/apps/plate-library/PlateLibrary';
import { ConfiguredDevicesProvider } from 'client/app/state/ConfiguredDevicesProvider/ConfiguredDevicesProvider';
import { ConfiguredDevice } from 'common/types/bundle';
import Colors from 'common/ui/Colors';

const NO_DEVICES: ConfiguredDevice[] = [];

export default function PlateLibraryApp() {
  return (
    <Wrapper>
      {/**
       * this app is not aware of specific configured devices, but other callers of
       * PlateLibrary are. Therefore, we need to wrap it in the ConfiguredDevicesProvider
       */}
      <ConfiguredDevicesProvider devices={NO_DEVICES}>
        <PlateLibrary />
      </ConfiguredDevicesProvider>
    </Wrapper>
  );
}

const Wrapper = styled('div')({
  backgroundColor: Colors.GREY_5,
  flex: 1,
  overflow: 'hidden',
});
