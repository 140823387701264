import React, { useCallback } from 'react';

import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import Update from '@mui/icons-material/Update';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import { useCreateDOETemplate } from 'client/app/api/DOETemplateApi';
import ExampleWorkflowDialog from 'client/app/apps/simulation-details/ExampleWorkflow/Dialog';
import useExampleWorkflowHandlers from 'client/app/apps/simulation-details/ExampleWorkflow/useExampleWorkflowHandlers';
import { SimulationQuery, WorkflowSourceEnum } from 'client/app/gql';
import useRequestHelpForSimulation from 'client/app/hooks/useRequestHelpForSimulation';
import { useUserProfile } from 'client/app/hooks/useUserProfile';
import {
  doeTemplateRoutes,
  graceRoutes,
  protocolsRoutes,
  templateWorkflowRoutes,
} from 'client/app/lib/nav/actions';
import { ScreenRegistry } from 'client/app/registry';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import MenuItemWithIcon from 'common/ui/components/Menu/MenuItemWithIcon';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import Tooltip from 'common/ui/components/Tooltip';
import useDialog from 'common/ui/hooks/useDialog';
import { ExampleWorkflowIcon } from 'common/ui/icons/';
import DOEIcon from 'common/ui/icons/DOEIcon';
import { GraceIcon } from 'common/ui/icons/GraceIcon';
import { TalkToUsIcon } from 'common/ui/icons/TalkToUs';
import { TemplateWorkflowIcon } from 'common/ui/icons/TemplateWorkflow';

type Props = {
  simulation: SimulationQuery['simulation'];
  onLoadingChange: (isLoading: boolean) => void;
  showCreateDOEButton: boolean;
  closeMenu: () => void;
};

export default function DefaultMenuContent({
  simulation,
  onLoadingChange,
  showCreateDOEButton,
  closeMenu,
}: Props) {
  const [updateExampleWorkflowDialog, openUpdateExampleWorflowDialog] =
    useDialog(ConfirmationDialog);

  const editInGracePath = graceRoutes.openSimulationInGrace.getPath({
    simulationId: simulation.id,
  });

  const snackbarManager = useSnackbarManager();
  const { navigate } = useNavigation();

  const createDOETemplate = useCreateDOETemplate();

  const handleCreateDOE = useCallback(async () => {
    closeMenu();
    onLoadingChange(true);
    try {
      const workflowId = await createDOETemplate(simulation.id);
      navigate(doeTemplateRoutes.edit, { workflowId });
    } catch (error) {
      snackbarManager.showError(
        `Could not create DOE template from this simulation: ${error.message}`,
      );
    } finally {
      onLoadingChange(false);
    }
  }, [
    closeMenu,
    createDOETemplate,
    navigate,
    onLoadingChange,
    simulation.id,
    snackbarManager,
  ]);

  const { requestHelpDialog, handleRequestHelp } = useRequestHelpForSimulation(
    ScreenRegistry.SIMULATION_DETAILS,
    simulation.id,
    simulation.name,
  );

  const closeMenuAndRequestHelp = () => {
    closeMenu();
    void handleRequestHelp();
  };
  const preventTemplateCreation = simulation.stages.length > 1;

  const exampleWorkflow = useExampleWorkflowHandlers(
    simulation.workflow.id,
    simulation.editableWorkflow?.id,
  );
  const [exampleWorkflowDialog, openExampleWorkflowsDialog] =
    useDialog(ExampleWorkflowDialog);

  const userProfile = useUserProfile();
  const isEnabledQuickStartActivation = useFeatureToggle('QUICK_START_ACTIVATION');
  const isProtocolsEnabled = useFeatureToggle('PROTOCOLS');
  const isSimulationBaseForWorkflowExample =
    exampleWorkflow.data?.workflowId === simulation.workflow.id;

  const handleUpdateExampleWorkflow = async () => {
    const isConfirmed = await openUpdateExampleWorflowDialog({
      action: 'update',
      object: 'example workflow',
      confirmButtonLabel: 'Confirm',
      omitConfirmationQuestion: true,
      additionalMessage:
        'Updating the example will change the snapshot an example workflow uses, to reflect the current simulation. Are you sure you want to proceed?',
    });
    if (isConfirmed) {
      await exampleWorkflow.update({});
    }
  };

  const exampleWorkflowMenu =
    isEnabledQuickStartActivation &&
    userProfile?.isExampleWorkflowsSourceOrg &&
    userProfile?.isSynthaceEmployee
      ? [
          <Divider key="divider" />,
          exampleWorkflow.loading ? (
            <Stack key="loader" alignItems="center" py={3}>
              <CircularProgress size={30} />
            </Stack>
          ) : exampleWorkflow.exist ? (
            [
              isSimulationBaseForWorkflowExample ? (
                <MenuItemWithIcon
                  key="edit"
                  icon={<Edit />}
                  text="Edit example"
                  onClick={() =>
                    openExampleWorkflowsDialog({
                      title: `Edit Example: ${exampleWorkflow.data?.name}`,
                      exampleWorkflow,
                    })
                  }
                />
              ) : (
                <MenuItemWithIcon
                  key="update"
                  icon={<Update />}
                  text="Update example"
                  onClick={handleUpdateExampleWorkflow}
                />
              ),
              <MenuItemWithIcon
                key="delete"
                icon={<Delete />}
                text="Delete example"
                onClick={() =>
                  openExampleWorkflowsDialog({
                    title: 'Delete Example',
                    exampleWorkflow,
                    deleteExample: true,
                  })
                }
              />,
            ]
          ) : (
            <MenuItemWithIcon
              key="create"
              icon={<ExampleWorkflowIcon />}
              text="Save as example"
              onClick={() =>
                openExampleWorkflowsDialog({
                  title: 'Create Example',
                  exampleWorkflow,
                })
              }
            />
          ),
        ]
      : null;

  return (
    <>
      {simulation.status === 'COMPLETED' &&
        simulation.workflow.source !== WorkflowSourceEnum.FORM_EDITOR &&
        (preventTemplateCreation ? (
          <Tooltip title="Template creation disabled for multi-stage workflows">
            <span>
              <MenuItemWithIcon
                icon={<TemplateWorkflowIcon />}
                text="Save as template"
                disabled
              />
            </span>
          </Tooltip>
        ) : (
          <MenuItemLink
            data-heap-tracking="simulation-save-as-template-button"
            to={templateWorkflowRoutes.createFromSimulation.getPath({
              simulationId: simulation.id,
            })}
          >
            <MenuItemWithIcon icon={<TemplateWorkflowIcon />} text="Save as template" />
          </MenuItemLink>
        ))}
      {isProtocolsEnabled && (
        <MenuItemLink
          to={protocolsRoutes.createFromWorkflow.getPath({
            id: simulation.workflow.id,
            name: simulation.workflow.name,
          })}
        >
          <MenuItemWithIcon icon={<TemplateWorkflowIcon />} text="Create protocol" />
        </MenuItemLink>
      )}
      {showCreateDOEButton && (
        <MenuItemWithIcon
          icon={<DOEIcon />}
          text="Create DOE"
          onClick={handleCreateDOE}
        />
      )}
      <MenuItemWithIcon
        icon={<TalkToUsIcon />}
        text="Request help"
        onClick={closeMenuAndRequestHelp}
      />
      {userProfile?.isSynthaceEmployee && simulation.id && (
        <a key="grace" target="_blank" rel="noopener noreferrer" href={editInGracePath}>
          <MenuItemWithIcon icon={<GraceIcon />} text="Edit with Grace" />
        </a>
      )}
      {exampleWorkflowMenu}
      {requestHelpDialog}
      {exampleWorkflowDialog}
      {updateExampleWorkflowDialog}
    </>
  );
}

const MenuItemLink = styled(Link)({
  // We want to make each menu item look like normal a clickable row,
  // even though it's a hyperlink.
  textDecoration: 'none',
  color: 'inherit',
});
