import { useMemo } from 'react';

import { isDefined } from 'common/lib/data';
import { ConfiguredDevice, ServerSideBundle } from 'common/types/bundle';
import {
  ConfiguredDeviceTail,
  getConfiguredDeviceTailExtraKeys,
  getElementId,
  getStageConfiguredDeviceTail,
  getStageId,
  isElementPath,
  isStageDevicesPath,
  Path,
  SchemaInput,
  StageDevicesPath,
} from 'common/types/schema';

/**
 * when protocol instance parameters are changed, the backend takes care of
 * mapping the changes. However, in the case of configuredDevices, we also want
 * to keep an up-to-date mapping so that the right context can be provided when
 * interacting with other parameters that are dependent on them
 */
export function useConfiguredDeviceMapping(
  workflow: ServerSideBundle,
  parameters: ParametersBlob,
) {
  const elementsToStages = useMemo(() => mapElementsToStages(workflow), [workflow]);
  const workflowStageDevices = useMemo(() => mapStagesToDevices(workflow), [workflow]);

  // parameters will often change so don't bother memo-ing
  const protocolStageDevices = applyParameterOverrides(
    workflowStageDevices,
    workflow.Schema?.inputs ?? [],
    parameters,
  );

  const configuredDevices = Object.values(protocolStageDevices).flatMap(v => v);

  const getConfigDeviceIds = (path: Path) => {
    let stageId = '';
    if (isElementPath(path)) {
      const elementInstanceId = getElementId(path)!;
      stageId = elementsToStages[elementInstanceId];
    } else {
      stageId = getStageId(path)!;
    }
    return protocolStageDevices[stageId]?.map(v => v.id) ?? [];
  };

  return {
    configuredDevices,
    getConfigDeviceIds,
  };
}

function mapElementsToStages({ Stages: stages = [] }: ServerSideBundle) {
  return stages.reduce<{ [elementId: string]: string }>((result, stage) => {
    return {
      ...result,
      ...stage.elementIds.reduce((v, elementId) => ({ ...v, [elementId]: stage.id }), {}),
    };
  }, {});
}

type ConfiguredDevicesByStage = { [stageId: string]: ConfiguredDevice[] };

function mapStagesToDevices(workflow: ServerSideBundle) {
  const stages = workflow.Stages ?? [];
  const initialDevices = workflow.Config.configuredDevices ?? [];
  return stages.reduce<ConfiguredDevicesByStage>(
    (result, stage) => ({
      ...result,
      [stage.id]: stage.configuredDevices
        .map(id => initialDevices.find(v => v.id === id))
        .filter(isDefined),
    }),
    {},
  );
}

function applyParameterOverrides(
  stageDevices: ConfiguredDevicesByStage,
  inputs: SchemaInput[],
  parameters: ParametersBlob,
) {
  const stageInputs = inputs.filter(input => isStageDevicesPath(input.path));
  if (stageInputs.length === 0) {
    return stageDevices;
  }

  const result = { ...stageDevices };
  for (const input of stageInputs) {
    updateStageDevices(result, input.path as StageDevicesPath, parameters[input.id]);
  }
  return result;
}

function updateStageDevices(
  stageDevices: ConfiguredDevicesByStage,
  path: StageDevicesPath,
  value: any,
) {
  const stageId = getStageId(path)!;
  const tail = getStageConfiguredDeviceTail(path);
  if (tail?.length) {
    const devices = stageDevices[stageId];
    stageDevices[stageId] = updateConfiguredDevices(devices, tail, value);
  } else {
    stageDevices[stageId] = value;
  }
}

function updateConfiguredDevices(
  devices: ConfiguredDevice[],
  tail: ConfiguredDeviceTail,
  value: any,
) {
  const configDeviceId = tail[0];
  const index = devices.findIndex(d => d.id === configDeviceId);
  if (index === -1) {
    throw new Error(`no workflow configured device with id "${configDeviceId}"`);
  }

  let newConfiguredDevice = devices[index];
  const extraKeys = getConfiguredDeviceTailExtraKeys(tail);
  if (extraKeys && extraKeys.length > 1) {
    throw new Error( // there's no immediate plan to support this, so error for the time being
      `more than one extra key for stage path device is not supported yet; got keys "${extraKeys}"`,
    );
  } else if (extraKeys) {
    newConfiguredDevice = { ...newConfiguredDevice, [extraKeys[0]]: value };
  } else {
    newConfiguredDevice = value;
  }

  return devices.toSpliced(index, 1, newConfiguredDevice);
}
