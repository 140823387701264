import React, { useCallback, useMemo, useRef } from 'react';

import List from '@mui/material/List';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';

import { CategoriesListItem } from 'client/app/components/CategoriesList/CategoriesListItem';
import { isDefined } from 'common/lib/data';
import { alphanumericCompare } from 'common/lib/strings';
import ContainerWithIntersectionBar from 'common/ui/components/ContainerWithIntersectionBar/ContainerWithIntersectionBar';

export const ALL_TAG_NAME = 'All';

type Props = {
  tags: string[] | null;
  selectedTag: string | undefined;
  onSelectTag: (tag: string) => void;
};

/**
 * Renders out a list of categories as buttons that can be selected.
 */
export function CategoriesList(props: Props) {
  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  const { tags, onSelectTag, selectedTag } = props;
  const handleSelectTag = useCallback(
    (tag: string) => {
      onSelectTag(tag === ALL_TAG_NAME ? '' : tag);
    },
    [onSelectTag],
  );

  const tagsListItems = useMemo(
    () => (
      <>
        <CategoriesListItem
          key={ALL_TAG_NAME}
          name={ALL_TAG_NAME}
          onClick={() => handleSelectTag(ALL_TAG_NAME)}
          selected={selectedTag === ALL_TAG_NAME || !selectedTag}
        />
        {tags
          ?.filter(isDefined)
          .sort(alphanumericCompare)
          .map(tagName => (
            <CategoriesListItem
              key={tagName}
              name={tagName}
              onClick={() => handleSelectTag(tagName)}
              selected={selectedTag === tagName}
            />
          ))}
      </>
    ),
    [handleSelectTag, selectedTag, tags],
  );

  return (
    <ContainerWithIntersectionBar
      scrollableRef={scrollableContainerRef}
      dense
      noHeader
      content={<CategoriesListItems>{tagsListItems}</CategoriesListItems>}
    />
  );
}

export function EmptyCategoriesList() {
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  return (
    <ContainerWithIntersectionBar
      scrollableRef={scrollableContainerRef}
      dense
      noHeader
      content={
        <CategoriesListItems>
          <CategoriesListItem key={ALL_TAG_NAME} name={ALL_TAG_NAME} selected />
        </CategoriesListItems>
      }
    />
  );
}

export function LoadingCategoriesList() {
  return (
    <CategoriesListItems>
      <Skeleton>
        <CategoriesListItem name="Skeleton1" />
      </Skeleton>
      <Skeleton>
        <CategoriesListItem name="Skeleton2Long" />
      </Skeleton>
      <Skeleton>
        <CategoriesListItem name="Skeleton2" />
      </Skeleton>
    </CategoriesListItems>
  );
}

const CategoriesListItems = styled(List)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  paddingRight: theme.spacing(3),
}));
