import React from 'react';

import Stack from '@mui/material/Stack';

import { MessagePreview, sxSmallerHeadings } from 'common/ui/components/MessagePreview';

type Props = {
  description?: string;
};

export default function DisplayDescription({ description }: Props) {
  if (!description) {
    return null;
  }

  return (
    <Stack paddingInline={2}>
      <MessagePreview
        message={description}
        messageType="markdown"
        sx={sxSmallerHeadings}
      />
    </Stack>
  );
}
