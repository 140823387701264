import { usePermissions } from 'client/app/components/Permissions';
import { AppPermission } from 'common/auth/permissions';
import { EditorType, WorkflowEditMode } from 'common/types/bundle';
import { usePartialCallback } from 'common/ui/hooks/usePartialCallback';
import { PermissionsFn } from 'common/ui/PermissionsProvider';

/**
 * Is it RO in the Builder?
 * @param editMode
 * @param source
 */
export default function isWorkflowReadonly(
  currentUserHasPermission: PermissionsFn<AppPermission>,
  editMode: WorkflowEditMode,
  source: EditorType,
) {
  const canUpdateOthersProtocol = currentUserHasPermission('update:othersProtocol');
  if (
    source === EditorType.PROTOCOL &&
    editMode === WorkflowEditMode.DISABLED_LATEST_OWNED_BY_SOMEONE_ELSE &&
    canUpdateOthersProtocol
  ) {
    return false;
  }

  return (
    !(
      editMode === WorkflowEditMode.ENABLED_LATEST_OWNED_BY_ME ||
      editMode === WorkflowEditMode.ENABLED_LATEST_EXAMPLE_GALLERY
    ) || EditorType.FORM_EDITOR === source
  );
}

export type IsWorkflowReadonly = (
  editMode: WorkflowEditMode,
  source: EditorType,
) => boolean;

export function useIsWorkflowReadonly(): IsWorkflowReadonly {
  const { currentUserHasPermission } = usePermissions();
  return usePartialCallback(currentUserHasPermission, isWorkflowReadonly);
}
