import React, { useCallback, useMemo } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {
  useCreateProtocolVersionAndNavigate,
  useQueryProtocolVersions,
} from 'client/app/apps/protocols/api/ProtocolsAPI';
import { protocolsRoutes } from 'client/app/lib/nav/actions';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import IconButton from 'common/ui/components/IconButton';
import LinearProgress from 'common/ui/components/LinearProgress';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import { DialogProps } from 'common/ui/hooks/useDialog';

type Props = DialogProps<void> & {
  id: ProtocolId;
  version: ProtocolVersion;
};

function useExistingDraftVersions(id: ProtocolId) {
  const { loading, data } = useQueryProtocolVersions(id);

  const draftVersions = useMemo((): ProtocolVersion[] => {
    if (loading) {
      return [];
    }
    const drafts = data?.protocols.items.filter(p => !p.isPublished && !p.isDeleted);
    return drafts?.map(p => p.version) ?? [];
  }, [data?.protocols.items, loading]);

  return { loading, draftVersions };
}

/**
 * A dialog which creates a new draft of a previously published protocol, or helps
 * the user navigate to an existing draft if there is one
 */
export default function UpdateProtocolDialog({ id, version, isOpen, onClose }: Props) {
  const handleClose = () => {
    onClose();
  };

  const { loading, draftVersions } = useExistingDraftVersions(id);
  const latestDraftVersion = Math.max(...draftVersions) as ProtocolVersion;
  const { navigate } = useNavigation();
  const navigateToLatest = useCallback(() => {
    navigate(protocolsRoutes.editProtocol, {
      id: id,
      version: latestDraftVersion,
    });
  }, [id, latestDraftVersion, navigate]);

  const { handleCreateProtocolVersionAndNavigate, loading: working } =
    useCreateProtocolVersionAndNavigate();
  const onNewDraft = useCallback(async () => {
    // delete any exising drafts, we will have already confirmed this with the user
    await handleCreateProtocolVersionAndNavigate(id, version, {
      deleteExistingEditableVersions: true,
    });
  }, [handleCreateProtocolVersionAndNavigate, id, version]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      onWheel={e => {
        e.stopPropagation();
      }}
    >
      <DialogTitle>
        <Typography variant="h2" component="span">
          Update Protocol
        </Typography>
        {!working && (
          <IconButton
            icon={<CloseIcon />}
            onClick={handleClose}
            color="inherit"
            size="small"
          />
        )}
      </DialogTitle>
      <Divider />

      {working ? (
        <WorkingComponent />
      ) : loading ? (
        <LoadingComponent onCancel={onClose} />
      ) : (
        <Contents
          onClose={handleClose}
          onNewDraft={onNewDraft}
          hasLaterVersion={latestDraftVersion > version}
          onNavigateToLatestDraft={navigateToLatest}
        />
      )}
    </Dialog>
  );
}

function LoadingComponent({ onCancel }: { onCancel: () => void }) {
  return (
    <>
      <DialogContent>
        <ContentSkeleton />
      </DialogContent>

      <DialogActions data-test="confirmationDialogActions">
        <Button variant="tertiary" color="inherit" onClick={onCancel}>
          Cancel
        </Button>
        <ButtonSkeleton />
      </DialogActions>
    </>
  );
}

function WorkingComponent() {
  return (
    <>
      <LinearProgress />
      <DialogContent>
        <Stack minHeight={155} justifyContent="center" alignItems="center">
          Loading new draft...
        </Stack>
      </DialogContent>
    </>
  );
}

function ContentSkeleton() {
  return <Skeleton variant="rounded" width="70%" />;
}

function ButtonSkeleton() {
  return <Skeleton variant="rounded" width={128} height={33} />;
}

type ContentsProps = {
  hasLaterVersion: boolean;
  onClose: () => void;
  onNewDraft: () => void;
  onNavigateToLatestDraft?: () => void;
};

function Contents({
  hasLaterVersion,
  onClose,
  onNewDraft,
  onNavigateToLatestDraft,
}: ContentsProps) {
  const firstParagraph = hasLaterVersion
    ? `An updated draft has already been created from this protocol. You can either
      continue to edit that draft, or delete it and start a new one.`
    : `To edit this published protocol, first create a new draft and make your desired
      changes to it.`;
  const actions = hasLaterVersion ? (
    <>
      <Button variant="tertiary" color="warning" onClick={onNewDraft}>
        delete and create new draft
      </Button>
      <Button variant="tertiary" color="primary" onClick={onNavigateToLatestDraft}>
        continue from draft
      </Button>
    </>
  ) : (
    <Button variant="tertiary" color="primary" onClick={onNewDraft}>
      New Draft
    </Button>
  );
  return (
    <>
      <DialogContent>
        <p>{firstParagraph}</p>
        <p>
          When you publish the draft, it will become the latest version of this protocol
          and will be used by default in the future. You will still be able to view and
          use previous versions of the protocol by selecting them from the dropdown.
        </p>
      </DialogContent>

      <DialogActions data-test="confirmationDialogActions">
        <Button variant="tertiary" color="inherit" onClick={onClose}>
          Cancel
        </Button>
        {actions}
      </DialogActions>
    </>
  );
}

const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  backgroundColor: Colors.GREY_5,
  padding: theme.spacing(0, 6),
  height: '56px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));
