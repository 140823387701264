import { useCallback } from 'react';

import { useMutation } from '@apollo/client';

import { CREATE_PROTOCOL_INSTANCE_EXECUTION } from 'client/app/api/gql/mutations';
import { executionRoutes } from 'client/app/lib/nav/actions';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';

export default function useExecuteProtocol(protocolInstanceId: ProtocolInstanceId) {
  const syntheticDataEnabled = useFeatureToggle('SYNTHETIC_DATA');
  const { showError } = useSnackbarManager();
  const [createExecution, { loading }] = useMutation(CREATE_PROTOCOL_INSTANCE_EXECUTION);
  const { navigate } = useNavigation();

  const createExecutionCallback = useCallback(async () => {
    await createExecution({
      variables: {
        input: {
          id: protocolInstanceId,
          createWithSyntheticData: syntheticDataEnabled,
        },
      },
      onCompleted({ createProtocolInstanceExecution: { instance } }) {
        if (instance.execution?.id) {
          navigate(executionRoutes.openInExecutionDetails, {
            executionId: instance.execution?.id,
          });
        } else {
          showError('Failed to create protocol run execution');
        }
      },
      onError(error) {
        showError(error.message);
      },
    });
  }, [createExecution, protocolInstanceId, syntheticDataEnabled, navigate, showError]);

  return [createExecutionCallback, { loading }] as const;
}
