import React from 'react';

import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import { StepParamConfigById } from 'client/app/apps/protocols/context/StepsProvider/stepsConfig';
import { CreateElementInputStepState } from 'client/app/apps/protocols/context/StepsProvider/stepState';
import { ParameterEntrySelector } from 'client/app/apps/protocols/EditProtocol/DefineProtocol/InstancePanel/EntrySelector';
import { PanelItem } from 'client/app/apps/protocols/EditProtocol/DefineProtocol/InstancePanel/PanelItem';
import ProtocolInstancePanel from 'client/app/apps/protocols/EditProtocol/DefineProtocol/InstancePanel/ProtocolInstancePanel';
import { useElementInputs } from 'client/app/apps/protocols/EditProtocol/DefineProtocol/InstancePanel/useElementInputs';
import { ElementParameterInfo } from 'client/app/apps/protocols/lib/types';
import { useElementNamePopover } from 'client/app/apps/workflow-builder/panels/element-instance-panel/hooks';
import { useConfiguredDevicesContext } from 'client/app/state/ConfiguredDevicesProvider/ConfiguredDevicesProvider';
import { Parameter } from 'common/types/bundle';
import { ParameterEditorConfigurationSpec } from 'common/types/commonConfiguration';

type Props = {
  stepsConfig: StepParamConfigById;
  activeStepId: string;
  elementInstanceId: string;
  elementInstanceName: string;
  inputs: Omit<ElementParameterInfo, 'elementInstanceId'>[];
  onChange: (input: CreateElementInputStepState, checked: boolean) => void;
  onClose: () => void;
};

export default function ProtocolParameterInstancePanel({
  stepsConfig,
  activeStepId,
  elementInstanceId,
  elementInstanceName,
  inputs: parametersWithValues,
  onChange,
  onClose,
}: Props) {
  const { setActiveConfiguredDeviceIds } = useConfiguredDevicesContext();
  const { elementNamePopover, ...elementNamePopoverEvents } =
    useElementNamePopover(elementInstanceName);

  const { inputParameters, handleChangeInput } = useElementInputs(
    elementInstanceId,
    elementInstanceName,
    parametersWithValues,
    stepsConfig,
    activeStepId,
    onChange,
  );

  const handleInputToggle =
    (input: { parameter: Parameter; value: any; enabledKeys: string[] }) =>
    (checked: boolean) => {
      const editor = input.parameter.configuration!.editor;
      handleChangeInput(input.parameter, checked, editor, input.value);
      if (checked === false) {
        // disabling the entire parameter through this toggle should also
        // disable any keys set. note: since we are removing inputs, it's ok
        // that the editor is not the value editor. It's a reasonable amount of
        // (unnecessary) work to get the correct value editor
        input.enabledKeys.map(key =>
          handleChangeInput(input.parameter, false, editor, null, key),
        );
      }
    };

  const handleInputEntryToggle =
    (inputParameter: Parameter) =>
    (
      keyChecked: boolean,
      valueEditor: ParameterEditorConfigurationSpec,
      valueForKey: any,
      key: string | number,
    ) => {
      handleChangeInput(inputParameter, keyChecked, valueEditor, valueForKey, key);
      if (keyChecked) {
        // disable editing the entire parameter
        const editor = inputParameter.configuration!.editor;
        handleChangeInput(inputParameter, false, editor, null);
      }
    };

  return (
    <ProtocolInstancePanel
      title={elementInstanceName}
      onClose={onClose}
      {...elementNamePopoverEvents}
    >
      <StyledStack>
        {inputParameters.map(input => {
          const hasValue = input.value !== undefined && input.value !== null;
          const isDisabled = input.isEnabled && input.otherStepMembership !== undefined;
          const configuration = input.parameter.configuration!; // by now we must have configuration
          return (
            <PanelItem
              key={`${elementInstanceId}-${input.parameter.name}`}
              displayName={configuration.displayName}
              checked={input.isEnabled}
              onCheck={handleInputToggle(input)}
              onClick={() =>
                setActiveConfiguredDeviceIds(input.relatedConfiguredDeviceIds)
              }
              isDisabled={isDisabled}
              otherStepMembership={input.otherStepMembership}
              contents={
                hasValue ? (
                  <ParameterEntrySelector
                    elementInstanceId={elementInstanceId}
                    editor={configuration.editor}
                    value={input.value}
                    enabledKeys={input.enabledKeys}
                    onToggle={handleInputEntryToggle(input.parameter)}
                    isDisabled={isDisabled}
                  />
                ) : undefined
              }
            />
          );
        })}
      </StyledStack>
      {elementNamePopover}
    </ProtocolInstancePanel>
  );
}

const StyledStack = styled(Stack)(({ theme: { spacing } }) => ({
  gap: spacing(2),
  padding: spacing(0, 2),
}));
