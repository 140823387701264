import { useCallback } from 'react';

import { StepParamConfigById } from 'client/app/apps/protocols/context/StepsProvider/stepsConfig';
import { CreateStageInputStepState } from 'client/app/apps/protocols/context/StepsProvider/stepState';
import { isDefined } from 'common/lib/data';
import { ConfiguredDevice, Parameter } from 'common/types/bundle';
import { ParameterEditorConfigurationSpec } from 'common/types/commonConfiguration';
import { ConfiguredDeviceTail } from 'common/types/schema';

export function useStageInputs(
  parametersWithInfo: {
    stage: { name: string; id: string };
    parameter: Parameter;
    value: ConfiguredDevice[];
  }[],
  stepsConfig: StepParamConfigById,
  activeStepId: string,
  onChange: (value: CreateStageInputStepState, checked: boolean) => void,
) {
  const inputParameters = parametersWithInfo.map(({ stage, ...rest }) => ({
    stage,
    ...rest,
    ...addStepConfiguredStageContext(
      stage.id,
      stepsConfig,
      stepsConfig[activeStepId]?.name ?? '',
    ),
  }));

  const handleChangeInput = useCallback(
    (
      stage: { name: string; id: string },
      parameter: Parameter,
      checked: boolean,
      editor: ParameterEditorConfigurationSpec,
      value: any,
      tail?: ConfiguredDeviceTail,
    ) => {
      const entry: CreateStageInputStepState = {
        stage,
        parameter: {
          name: parameter.name,
          editor,
          displayName: parameter.configuration!.displayName,
          tail,
        },
        value,
      };
      onChange(entry, checked);
    },
    [onChange],
  );

  return {
    inputParameters,
    handleChangeInput,
  };
}

function addStepConfiguredStageContext(
  stageId: string,
  stepsConfig: StepParamConfigById,
  activeStepName: string,
) {
  const relatedConfigs = Object.values(stepsConfig).filter(v => stageId in v.devices);
  const isEnabled = relatedConfigs.length > 0;
  const enabledTails: ConfiguredDeviceTail[] = relatedConfigs.flatMap(config => {
    const stageDeviceConfig = config.devices[stageId]?.keys();
    const tails = [...stageDeviceConfig].filter(isDefined);
    return tails;
  });

  const stepMemberships = relatedConfigs.map(v => v.name);
  const otherStepMembership =
    stepMemberships.filter(v => v !== activeStepName).join(', ') || undefined;

  return {
    isEnabled,
    enabledTails,
    otherStepMembership,
  };
}
