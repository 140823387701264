import React from 'react';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

import Colors from 'common/ui/Colors';

type Props = { className: string };

export function InlineEditorHelp({ className }: Props) {
  return (
    <HelpTooltip
      arrow
      placement="top"
      disableInteractive
      className={className}
      title={<TooltipContent />}
    >
      <HelpOutlineIcon color="info" />
    </HelpTooltip>
  );
}

function TooltipContent() {
  return (
    <pre>
      Bold: Cmd/Ctrl + B<br />
      Italics: Cmd/Ctrl + I<br />
      Underline: Cmd/Ctrl + U<br />
      Lists: - then space
      <br />
      Lists ordered: 1. then space
      <br />
      List indents: use tab
    </pre>
  );
}

const HelpTooltip = styled(Tooltip)(({ theme }) => ({
  padding: theme.spacing(1),
  background: Colors.WHITE,
  position: 'absolute',
  top: 0,
  right: 0,
  cursor: 'pointer',
  visibility: 'hidden',
  zIndex: 2,
}));
