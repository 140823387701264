import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';

import { TipTypeDropdownEditor } from 'client/app/components/Parameters/DeviceConfigurator/TipTypeDropdownEditor';
import { useConfiguredDevicesContext } from 'client/app/state/ConfiguredDevicesProvider/ConfiguredDevicesProvider';
import { EditorType } from 'common/elementConfiguration/EditorType';
import { pluralize } from 'common/lib/format';
import Button from 'common/ui/components/Button';
import ArrayEditor from 'common/ui/components/ParameterEditors/ArrayEditor';

type Props = {
  value?: string[] | null;
  onChange: (tipTypeValues?: string[]) => void;
  isDisabled?: boolean;
};

export function DeviceTipTypeSelector({
  value,
  onChange: handleTipTypesChange,
  isDisabled = false,
}: Props) {
  const tipTypes = value ?? [];

  const { loadingConfigOptions: loading, activeMainConfiguredDeviceConfig } =
    useConfiguredDevicesContext();

  const handleTipTypeDelete = (index: number) =>
    handleTipTypesChange(tipTypes.toSpliced(index, 1));

  const buttonCopy = loading
    ? 'Loading options...'
    : pluralize(tipTypes.length, 'tip type preference');

  // we need the activeMainConfiguredDeviceConfig to be able to show
  // TipTypeDropdownEditor options rendered properly
  return activeMainConfiguredDeviceConfig ? (
    <ArrayEditor
      anthaType=""
      component={TipTypeDropdownEditor}
      value={tipTypes}
      onChange={handleTipTypesChange}
      onItemDelete={handleTipTypeDelete}
      overrideAddNewItemCopy="Add tip type preference"
      itemEditorProps={{
        placeholder: '',
        type: EditorType.TIP_TYPE,
      }}
      isDisabled={isDisabled}
    />
  ) : (
    <Button
      variant="tertiary"
      color="primary"
      startIcon={loading && <CircularProgress size={18} />}
    >
      {buttonCopy}
    </Button>
  );
}
