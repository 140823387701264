import { getRuleMeta, RuleMetaKey } from 'client/app/apps/policy-library/RulesMeta';
import { capitalize } from 'common/lib/format';
import { CellValue } from 'common/types/spreadsheetEditor';

export enum ConsequenceParameter {
  CAN_MULTI = 'can_multi',
  PROXY = 'proxy',
  TIP_REUSE_LIMIT = 'tip_reuse_limit',
  ASPIRATE_ENTRY_SPEED = 'aspirate_entry_speed',
  ASPIRATE_FLOW_RATE = 'aspirate_flow_rate',
  ASPIRATE_LLD = 'aspirate_lld',
  ASPIRATE_LLF = 'aspirate_llf',
  ASPIRATE_MOVEMENT_SPEED_Z = 'aspirate_movement_speed_z',
  ASPIRATE_OFFSET = 'aspirate_offset',
  ASPIRATE_REFERENCE = 'aspirate_reference',
  ASPIRATE_WAIT_TIME = 'aspirate_wait_time',
  DISPENSE_ENTRY_SPEED = 'dispense_entry_speed',
  DISPENSE_FLOW_RATE = 'dispense_flow_rate',
  DISPENSE_LLD = 'dispense_lld',
  DISPENSE_LLF = 'dispense_llf',
  DISPENSE_MOVEMENT_SPEED_Z = 'dispense_movement_speed_z',
  DISPENSE_OFFSET = 'dispense_offset',
  DISPENSE_REFERENCE = 'dispense_reference',
  DISPENSE_WAIT_TIME = 'dispense_wait_time',
  BLOWOUT_OFFSET = 'blowoutoffset',
  BLOWOUT_REFERENCE = 'blowoutreference',
  BLOWOUT_VOLUME = 'blowoutvolume',
  PRE_MIX_NUM_CYCLES = 'pre_mix_num_cycles',
  PRE_MIX_OFFSET = 'pre_mix_offset',
  PRE_MIX_PROXY = 'pre_mix_proxy',
  PRE_MIX_REFERENCE = 'pre_mix_reference',
  PRE_MIX_VOLUME = 'pre_mix_volume',
  PRE_MIX_FLOW_RATE = 'pre_mix_flow_rate',
  POST_MIX_FLOW_RATE = 'post_mix_flow_rate',
  POST_MIX_NUM_CYCLES = 'post_mix_num_cycles',
  POST_MIX_OFFSET = 'post_mix_offset',
  POST_MIX_PROXY = 'post_mix_proxy',
  POST_MIX_REFERENCE = 'post_mix_reference',
  POST_MIX_VOLUME = 'post_mix_volume',
  ALLOWED_TIP_TYPES = 'allowed_tip_types',
  DEFAULT_FLOW_RATE = 'default_flow_rate',
  HEADER_VOLUME = 'header_volume',
  LIQUID_CLASS_OVERRIDE = 'liquid_class_override',
  MOVEMENT_SPEED_Z = 'movement_speed_z',
  MULTI_DISPENSE_LIMIT = 'multi_dispense_limit',
  OVERRIDE_FLOW_RATES = 'override_flow_rates',
  OVERRIDE_VOLUMES = 'override_volumes',
  RESET_OVERRIDE = 'reset_override',
  TOUCH_OFF_ENABLED = 'touch_off_enabled',
  TOUCH_OFF_HEIGHT = 'touch_off_height',
  PREWET_VOLUME = 'prewet_volume',
}

export class ConsequenceParser {
  private parameter: ConsequenceParameter;

  constructor(parameter: ConsequenceParameter) {
    this.parameter = parameter;
  }

  parse(value: any) {
    switch (this.parameter) {
      case ConsequenceParameter.CAN_MULTI:
      case ConsequenceParameter.TOUCH_OFF_ENABLED:
      case ConsequenceParameter.RESET_OVERRIDE:
      case ConsequenceParameter.OVERRIDE_FLOW_RATES:
      case ConsequenceParameter.OVERRIDE_VOLUMES: {
        return this.parseBool(value);
      }
      case ConsequenceParameter.ASPIRATE_REFERENCE:
      case ConsequenceParameter.ASPIRATE_LLD:
      case ConsequenceParameter.ASPIRATE_LLF:
      case ConsequenceParameter.DISPENSE_REFERENCE:
      case ConsequenceParameter.DISPENSE_LLD:
      case ConsequenceParameter.DISPENSE_LLF:
      case ConsequenceParameter.PRE_MIX_REFERENCE:
      case ConsequenceParameter.PRE_MIX_PROXY:
      case ConsequenceParameter.POST_MIX_REFERENCE:
      case ConsequenceParameter.POST_MIX_PROXY:
      case ConsequenceParameter.BLOWOUT_REFERENCE:
      case ConsequenceParameter.PROXY:
      case ConsequenceParameter.LIQUID_CLASS_OVERRIDE: {
        return this.parseString(value);
      }
      case ConsequenceParameter.ALLOWED_TIP_TYPES: {
        return this.parseStringArray(value);
      }
      case ConsequenceParameter.ASPIRATE_OFFSET:
      case ConsequenceParameter.ASPIRATE_WAIT_TIME:
      case ConsequenceParameter.BLOWOUT_OFFSET:
      case ConsequenceParameter.BLOWOUT_VOLUME:
      case ConsequenceParameter.DISPENSE_OFFSET:
      case ConsequenceParameter.DISPENSE_WAIT_TIME:
      case ConsequenceParameter.HEADER_VOLUME:
      case ConsequenceParameter.POST_MIX_OFFSET:
      case ConsequenceParameter.POST_MIX_VOLUME:
      case ConsequenceParameter.PRE_MIX_OFFSET:
      case ConsequenceParameter.PRE_MIX_VOLUME:
      case ConsequenceParameter.PREWET_VOLUME:
      case ConsequenceParameter.TOUCH_OFF_HEIGHT: {
        return this.parseMeasurement(value);
      }
      case ConsequenceParameter.ASPIRATE_ENTRY_SPEED:
      case ConsequenceParameter.ASPIRATE_FLOW_RATE:
      case ConsequenceParameter.ASPIRATE_MOVEMENT_SPEED_Z:
      case ConsequenceParameter.DEFAULT_FLOW_RATE:
      case ConsequenceParameter.DISPENSE_ENTRY_SPEED:
      case ConsequenceParameter.DISPENSE_FLOW_RATE:
      case ConsequenceParameter.DISPENSE_MOVEMENT_SPEED_Z:
      case ConsequenceParameter.MOVEMENT_SPEED_Z:
      case ConsequenceParameter.POST_MIX_FLOW_RATE:
      case ConsequenceParameter.PRE_MIX_FLOW_RATE: {
        return this.parsePositiveMeasurement(value);
      }
      case ConsequenceParameter.PRE_MIX_NUM_CYCLES:
      case ConsequenceParameter.POST_MIX_NUM_CYCLES:
      case ConsequenceParameter.TIP_REUSE_LIMIT:
      case ConsequenceParameter.MULTI_DISPENSE_LIMIT: {
        return this.parseNonNegativeNumber(value);
      }
      default: {
        throw new Error(`Unsupported parameter: ${this.parameter}`);
      }
    }
  }

  private parseBool(value: CellValue) {
    if (typeof value === 'boolean') {
      return value;
    } else {
      throw new MeasurementConsequenceError(this.parameter);
    }
  }
  private parseString(value: CellValue) {
    if (typeof value === 'string') {
      return value;
    } else {
      throw new MeasurementConsequenceError(this.parameter);
    }
  }

  private parseStringArray(value: CellValue) {
    if (typeof value === 'string') {
      return value.split(';');
    } else {
      throw new MeasurementConsequenceError(this.parameter);
    }
  }

  private parseMeasurement(value: CellValue) {
    const match = this.matchMeasurement(value);
    if (!match) {
      throw new MeasurementConsequenceError(
        this.parameter,
        'The value must be non negative.',
      );
    } else {
      return { value: Number(match[1]), unit: match[3] };
    }
  }

  private parsePositiveMeasurement(value: CellValue) {
    const match = this.matchMeasurement(value);
    if (!match)
      throw new MeasurementConsequenceError(
        this.parameter,
        'The value must be positive.',
      );
    const isPositive = Number(match[1]) > 0;
    if (!isPositive)
      throw new MeasurementConsequenceError(
        this.parameter,
        'The value must be positive.',
      );

    return { value: Number(match[1]), unit: match[3] };
  }

  private matchMeasurement(value: CellValue) {
    if (typeof value === 'string') {
      const positiveFloatPattern = '\\d+(\\.\\d+)?';
      const unitsPattern = '[a-zA-Z]+(?:\\/?[a-zA-Z]+)?';
      const regexPattern = `^(${positiveFloatPattern})\\s*(${unitsPattern})$`;
      return value.trim().match(regexPattern);
    } else {
      throw new MeasurementConsequenceError(this.parameter);
    }
  }

  private parseNonNegativeNumber(value: CellValue) {
    if (typeof value === 'number' && value >= 0) {
      return value;
    } else {
      throw new MeasurementConsequenceError(
        this.parameter,
        'The value must be non negative.',
      );
    }
  }
}

export class MeasurementConsequenceError extends Error {
  constructor(conditionVariable: string, valueMessage?: string) {
    super();
    this.message = this.buildMessage(conditionVariable, valueMessage);
  }

  private buildMessage(conditionVariable: string, valueMessage?: string) {
    const ruleMeta = getRuleMeta('consequence', conditionVariable as RuleMetaKey);
    const conditionLabel = capitalize(ruleMeta.label);
    const invalidFormatMessage = `Invalid format of consequence "${conditionLabel}".`;
    const checkHeadeMessage =
      'Please make sure to check the help information available in the column header.';

    return `${invalidFormatMessage} ${checkHeadeMessage} ${valueMessage || ''}`;
  }
}
