import { useCallback } from 'react';

import { StepParamConfigById } from 'client/app/apps/protocols/context/StepsProvider/stepsConfig';
import { CreateOutputStepState } from 'client/app/apps/protocols/context/StepsProvider/stepState';
import { hasOutputVisualisation } from 'client/app/components/ElementPlumber/ElementOutputs/helpers';
import { isDefined } from 'common/lib/data';
import { Parameter } from 'common/types/bundle';

export function useElementOutputs(
  elementInstanceId: string,
  elementInstanceName: string,
  parameters: readonly Parameter[],
  stepsConfig: StepParamConfigById,
  activeStepId: string,
  onChange: (value: CreateOutputStepState, checked: boolean) => void,
) {
  const hasOutputInActiveStepInAnyElement =
    Object.keys(stepsConfig[activeStepId]?.outputs || {}).length > 0;

  const configuredElementOutputs = getConfiguredElementOutputsByStepId(
    elementInstanceId,
    stepsConfig,
  );

  const outputParameters = parameters
    .filter(hasOutputVisualisation)
    .map(parameter =>
      addConfigContext(
        parameter,
        configuredElementOutputs,
        configuredElementOutputs[activeStepId]?.stepName ?? '',
      ),
    );

  const handleChange = useCallback(
    (parameter: Parameter, checked: boolean) => {
      const entry: CreateOutputStepState = {
        element: {
          instanceName: elementInstanceName,
          id: elementInstanceId,
        },
        parameter: {
          name: parameter.name,
          typeName: parameter.type,
          displayName: parameter.configuration?.displayName ?? parameter.name,
        },
      };
      onChange(entry, checked);
    },
    [elementInstanceId, elementInstanceName, onChange],
  );

  return {
    outputParameters,
    hasOutputInActiveStepInAnyElement,
    handleChange,
  };
}

function addConfigContext(
  parameter: Parameter,
  configuredOutputs: ConfiguredOutputsByStepId,
  activeStepName: string,
) {
  const stepMemberships = Object.entries(configuredOutputs)
    .map(([_, config]) => {
      const isParamEnabled = config.outputs[parameter.name] !== undefined;
      return isParamEnabled ? config.stepName : undefined;
    })
    .filter(isDefined);

  const isInActiveStep = stepMemberships.includes(activeStepName);

  return {
    parameter: parameter,
    isEnabled: isInActiveStep,
    otherStepMembership:
      stepMemberships.filter(v => v !== activeStepName).join(', ') || undefined,
  };
}

type ConfiguredOutputsByStepId<DisplayName = string> = {
  [stepId: string]: {
    stepName: DisplayName;
    outputs: { [paramName: string]: unknown };
  };
};

function getConfiguredElementOutputsByStepId(
  elementInstanceId: string,
  stepsConfig: StepParamConfigById,
): ConfiguredOutputsByStepId {
  const entries = Object.entries(stepsConfig)
    .map(([stepId, stepConfig]) => {
      if (!(elementInstanceId in stepConfig.outputs)) {
        return undefined;
      }
      return [
        stepId,
        {
          stepName: stepConfig.name,
          outputs: stepConfig.outputs[elementInstanceId],
        },
      ];
    })
    .filter(isDefined);

  return Object.fromEntries(entries);
}
