import React, { useCallback } from 'react';

import ParameterEditorWithHelperText from 'client/app/components/Parameters/ParameterEditor';
import { ParameterValue } from 'common/types/bundle';
import { ParameterEditorConfigurationSpec } from 'common/types/commonConfiguration';

type Props = {
  editor: ParameterEditorConfigurationSpec;
  value: any;
  onClick?: () => void;
  onChange: (value: ParameterValue) => void;
  /**
   * used by parameters in elements that fetch context about other parameters
   * or the output from the element
   */
  elementInstanceId?: string;
  isDisabled?: boolean;
};

export function ProtocolParameter({
  editor,
  value,
  onClick,
  onChange,
  elementInstanceId,
  isDisabled,
}: Props) {
  const handleChange = useCallback(
    (value: ParameterValue, _?: string) => onChange(value),
    [onChange],
  );

  return (
    <div onClick={onClick}>
      <ParameterEditorWithHelperText
        // no anthaType since we have set the correct overrides in additionalProps
        // for autosuggestions
        anthaType=""
        onChange={handleChange}
        elementInstanceId={elementInstanceId}
        editorType={editor.type}
        editorProps={editor.additionalProps || undefined}
        placeholder={editor.placeholder}
        value={value}
        isDisabled={isDisabled}
      />
    </div>
  );
}
