import React from 'react';

import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import { StepParamConfigById } from 'client/app/apps/protocols/context/StepsProvider/stepsConfig';
import { CreateStageInputStepState } from 'client/app/apps/protocols/context/StepsProvider/stepState';
import { DevicesEntrySelector } from 'client/app/apps/protocols/EditProtocol/DefineProtocol/InstancePanel/EntrySelector';
import { PanelItem } from 'client/app/apps/protocols/EditProtocol/DefineProtocol/InstancePanel/PanelItem';
import ProtocolInstancePanel from 'client/app/apps/protocols/EditProtocol/DefineProtocol/InstancePanel/ProtocolInstancePanel';
import { useStageInputs } from 'client/app/apps/protocols/EditProtocol/DefineProtocol/InstancePanel/useStageInputs';
import { useConfiguredDevicesContext } from 'client/app/state/ConfiguredDevicesProvider/ConfiguredDevicesProvider';
import { ConfiguredDevice, Parameter } from 'common/types/bundle';
import { ParameterEditorConfigurationSpec } from 'common/types/commonConfiguration';
import { ConfiguredDeviceTail } from 'common/types/schema';

type Props = {
  activeStepId: string;
  stepsConfig: StepParamConfigById;
  inputs: {
    stage: { name: string; id: string };
    parameter: Parameter;
    value: ConfiguredDevice[];
  }[];
  onChange: (value: CreateStageInputStepState, checked: boolean) => void;
  onClose: () => void;
};

export default function ProtocolStageSelectionPanel({
  activeStepId,
  stepsConfig,
  inputs,
  onChange,
  onClose,
}: Props) {
  const { setActiveConfiguredDeviceIds } = useConfiguredDevicesContext();
  const { inputParameters, handleChangeInput } = useStageInputs(
    inputs,
    stepsConfig,
    activeStepId,
    onChange,
  );

  const handleInputToggle =
    (input: {
      stage: { name: string; id: string };
      parameter: Parameter;
      value: any;
      enabledTails: ConfiguredDeviceTail[];
    }) =>
    (checked: boolean) => {
      const { stage, parameter, value, enabledTails } = input;
      const editor = parameter.configuration!.editor;
      handleChangeInput(stage, parameter, checked, editor, value);
      if (checked === false) {
        // disabling the entire parameter through this toggle should also
        // disable any keys set. note: since we are removing inputs, it's ok
        // that the editor is not the value editor. It's a reasonable amount of
        // (unnecessary) work to get the correct value editor
        enabledTails.map(tail =>
          handleChangeInput(stage, parameter, false, editor, null, tail),
        );
      }
    };

  const handleInputEntryToggle =
    (stage: { name: string; id: string }, parameter: Parameter) =>
    (
      tailChecked: boolean,
      valueEditor: ParameterEditorConfigurationSpec,
      valueForKey: any,
      tail: ConfiguredDeviceTail,
    ) => {
      handleChangeInput(stage, parameter, tailChecked, valueEditor, valueForKey, tail);
      if (tailChecked) {
        // disable editing the entire parameter
        handleChangeInput(stage, parameter, false, parameter.configuration!.editor, null);
      }
    };

  return (
    <ProtocolInstancePanel title="Stage devices" onClose={onClose}>
      <StyledStack>
        {inputParameters.map(input => {
          const isDisabled = input.isEnabled && input.otherStepMembership !== undefined;
          const configuration = input.parameter.configuration!; // by now we must have configuration
          const configuredDeviceIds = input.value.map(v => v.id);
          return (
            <PanelItem
              key={input.stage.id}
              displayName={configuration.displayName}
              checked={input.isEnabled}
              onCheck={handleInputToggle(input)}
              onClick={() => setActiveConfiguredDeviceIds(configuredDeviceIds)}
              isDisabled={isDisabled}
              otherStepMembership={input.otherStepMembership}
              contents={
                <DevicesEntrySelector
                  value={input.value}
                  isDisabled={isDisabled}
                  enabledTails={input.enabledTails}
                  onToggle={handleInputEntryToggle(input.stage, input.parameter)}
                />
              }
            />
          );
        })}
      </StyledStack>
    </ProtocolInstancePanel>
  );
}

const StyledStack = styled(Stack)(({ theme: { spacing } }) => ({
  gap: spacing(2),
  padding: spacing(0, 2),
}));
