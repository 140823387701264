import React, { createElement } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {
  ProtocolEditMode,
  useProtocolContext,
} from 'client/app/apps/protocols/EditProtocol/context/ProtocolProvider';
import {
  EditProtocolFC,
  EditProtocolProps,
} from 'client/app/apps/protocols/EditProtocol/useProtocolNavigation';
import { HasPermission } from 'client/app/components/Permissions';
import { TopBar } from 'client/app/components/TopBar';
import { protocolsRoutes } from 'client/app/lib/nav/actions';
import RouteButton from 'common/ui/components/navigation/RouteButton';

type Props = {
  protocolId: ProtocolId;
  protocolVersion: ProtocolVersion;
  editMode: ProtocolEditMode;
  author?: string;
};

const ProtocolReadOnlyBar = ({
  editMode,
  protocolId,
  protocolVersion,
  author,
}: Props) => {
  if (editMode === ProtocolEditMode.EDITABLE) {
    return null;
  }
  return (
    <HasPermission
      permission="update:othersProtocol"
      renderItem={hasPermission => {
        // with the special permission, you can update a protocol so long as it isn't deleted
        const canOverride = hasPermission && editMode === ProtocolEditMode.NOT_OWNER;

        if (canOverride) {
          return (
            <TopBar warning>
              <InfoOutlined color="warning" />
              <Typography variant="h6" color="textPrimary">
                You are viewing a protocol {author ? `by ${author}` : 'by someone else'}{' '}
                which would normally be <b>read-only</b>
                <br />
                However, you have elevated privileges and can edit the protocol even
                though {getReason(editMode)}
              </Typography>
            </TopBar>
          );
        }
        return (
          <TopBar>
            <InfoOutlined color="info" />
            <Typography variant="h6" color="textPrimary">
              This protocol {author ? `by ${author} ` : ''}is in <b>read-only</b> mode
              because {getReason(editMode)}
            </Typography>
            <RouteButton
              label="Duplicate"
              route={protocolsRoutes.copyProtocol}
              routeParam={{ id: protocolId, version: protocolVersion }}
              variant="contained"
              color="primary"
              startIcon={<ContentCopyIcon />}
            />
          </TopBar>
        );
      }}
    />
  );
};

const getReason = (editMode: ProtocolEditMode): string => {
  switch (editMode) {
    case ProtocolEditMode.EDITABLE:
      return '';
    case ProtocolEditMode.DELETED:
      return 'the protocol has been deleted';
    case ProtocolEditMode.NOT_OWNER:
      return 'you are not the owner';
    case ProtocolEditMode.DELETED_NOT_OWNER:
      return 'the protocol has been deleted and you are not the owner';
  }
  // we know this is unreachable, or TS would complain about the 'string' return type
};

const withProtocolReadOnlyBar = (fc: EditProtocolFC) => {
  return (props: EditProtocolProps) => {
    const { protocolId, protocolVersion, editMode, author } = useProtocolContext();

    return (
      <Wrapper>
        <ProtocolReadOnlyBar
          protocolId={protocolId}
          protocolVersion={protocolVersion}
          editMode={editMode}
          author={author}
        />
        {createElement(fc, props)}
      </Wrapper>
    );
  };
};
export default withProtocolReadOnlyBar;

const Wrapper = styled(Stack)({
  position: 'relative',
  height: '100%',
  overflow: 'hidden',
});
