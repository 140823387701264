import React from 'react';

import { useQuery } from '@apollo/client';

import { QUERY_UNIQUE_TAGS } from 'client/app/api/gql/queries';
import {
  CategoriesList,
  EmptyCategoriesList,
  LoadingCategoriesList,
} from 'client/app/components/CategoriesList/CategoriesList';
import { UniqueTagsQuery } from 'client/app/gql';
import { RenderQuery } from 'common/ui/components/RenderQuery/RenderQuery';

type SelectedTag = {
  selectedTag: string | undefined;
  onSelectTag: (tag: string) => void;
};

type Props = {
  entityClassName: string;
} & SelectedTag;

export default function CategoriesListFetcher(props: Props) {
  const { selectedTag, onSelectTag, entityClassName } = props;
  const exampleWorkflowsTagsQuery = useQuery(QUERY_UNIQUE_TAGS, {
    variables: {
      entityClassName,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  return (
    <RenderQuery
      query={exampleWorkflowsTagsQuery}
      renderData={(props: { data: UniqueTagsQuery } & SelectedTag) => {
        const { data, ...remainingProps } = props;
        return <CategoriesList tags={data.uniqueTags} {...remainingProps} />;
      }}
      additionalDataProps={{ selectedTag, onSelectTag }}
      renderNoData={EmptyCategoriesList}
      loadingComponent={LoadingCategoriesList}
    />
  );
}
