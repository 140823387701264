import React from 'react';

import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';

type Props = {
  onCancel: () => void;
  onDelete: () => void;
};

const ConfirmDeletion = ({ onCancel, onDelete }: Props) => {
  return (
    <Stack direction="row" justifyContent="right" alignItems="baseline" gap={5}>
      <Typography>Are you sure you want to delete the protocol run?</Typography>
      <Button variant="tertiary" color="inherit" onClick={onCancel}>
        Cancel
      </Button>
      <DeleteButton variant="tertiary" onClick={onDelete} color="primary">
        Delete
      </DeleteButton>
    </Stack>
  );
};

const DeleteButton = styled(Button)({
  '&.MuiButton-root': {
    color: Colors.RED,
  },
});

export default ConfirmDeletion;
