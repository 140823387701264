import { useContext, useMemo } from 'react';

import { useWorkflowContext } from 'client/app/apps/protocols/context/WorkflowProvider';
import { ElementParameterInfo } from 'client/app/apps/protocols/lib/types';
import { ParameterStateContext } from 'client/app/lib/rules/elementConfiguration/ParameterStateContext';
import { ArrayAdditionalProps } from 'common/elementConfiguration/AdditionalEditorProps';
import { applyAnthaTypeOverrides } from 'common/elementConfiguration/applyAnthaTypeOverrides';
import { EditorType } from 'common/elementConfiguration/EditorType';
import { getAdditionalEditorPropsForEditorType } from 'common/elementConfiguration/getEditorTypeInfo';
import { ElementInstance, Parameter } from 'common/types/bundle';
import { ParameterEditorConfigurationSpec } from 'common/types/commonConfiguration';

/**
 * @returns - workflow element parameters that are visible based on element
 * configuration rules along with extra context required to create protocol
 * inputs such as the value in the workflow
 */
export const useConfiguredElementParameterInfo = (
  instance?: ElementInstance,
): ElementParameterInfo[] | undefined => {
  const { getStateForParameter } = useContext(ParameterStateContext);
  const { getElementParameterValue, getElementRelatedConfigDeviceIds } =
    useWorkflowContext();
  return useMemo(() => {
    return instance?.element.inputs
      .filter(({ name, configuration }) => {
        // parameter visibility can be dependent on element configuration rules
        const {
          // assume parameter is enabled if state not found
          isVisible: isVisibleByConfigRules = true,
          isEnabled = true,
        } = getStateForParameter(instance.name, name) || {};

        // while other options are simply a yes / no setting
        const {
          isBlockedFromSchema = false,
          isInternalOnly = false,
          isVisible = true, // assume isVisible if configuration is undefined
        } = configuration || {};
        return (
          isEnabled &&
          isVisible &&
          isVisibleByConfigRules &&
          !isBlockedFromSchema &&
          !isInternalOnly &&
          // this is so fundamental for protocols. If it's not present, one
          // should be set for the element through element configuration
          configuration &&
          configuration.editor
        );
      })
      .map(parameter => {
        // ! editor due to the filter step just above
        const editor = parameter.configuration!.editor;
        const editorWithProps = ensureEditorWithProps(editor, parameter.type);
        return {
          ...getElementRelatedConfigDeviceIds(instance.Id),
          ...getElementParameterValue(instance, parameter),
          elementInstanceId: instance.Id,
          parameter: {
            ...parameter,
            configuration: {
              ...parameter.configuration,
              editor: editorWithProps,
            },
          } as Parameter,
        };
      });
  }, [
    getElementParameterValue,
    getElementRelatedConfigDeviceIds,
    getStateForParameter,
    instance,
  ]);
};

/**
 * ensures editor additional props are set for protocols and/or interpreted from
 * an antha core type string such that anthaType is no longer required
 *
 * This is useful to limit the amount of extra context that must be provided to
 * `ParameterEditor` for its rendering and to have the correct context when we
 * save the editor to a protocol input step
 */
function ensureEditorWithProps(
  editor: ParameterEditorConfigurationSpec,
  anthaType: string,
): ParameterEditorConfigurationSpec {
  let props = editor.additionalProps;
  if (props && editor.type === EditorType.ARRAY) {
    // PLATE_LAYOUT_LAYERS requires a lot of user input. We don't want to
    // accidentally delete it since we don't support undo actions in protocols
    const arrayProps = editor.additionalProps as ArrayAdditionalProps;
    props = {
      ...arrayProps,
      confirmDeletion: arrayProps.itemEditor?.type === EditorType.PLATE_LAYOUT_LAYERS,
    };
  }
  if (!props) {
    props = getAdditionalEditorPropsForEditorType(editor.type, anthaType);
  }

  // now we apply autocomplete context. If anthaTypeOverride is not set, then
  // the `ParameterEditor` will try and infer if from the anthaType, but we want
  // to encapsulate all logic into editor and not rely on anthaType, so we set
  // the overrides here
  return applyAnthaTypeOverrides({ ...editor, additionalProps: props }, anthaType);
}
