import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';

import { SIMULATE_PROTOCOL_INSTANCE } from 'client/app/api/gql/mutations';
import { QUERY_SIMULATION } from 'client/app/api/gql/queries';
import { useProtocolInstanceContext } from 'client/app/apps/protocols/context/ProtocolInstanceProvider/ProtocolInstanceProvider';
import { useWorkflowContext } from 'client/app/apps/protocols/context/WorkflowProvider';
import useSimulationButton from 'client/app/apps/workflow-builder/useSimulationButton';
import { SimulationQuery } from 'client/app/gql';
import { simulationRoutes } from 'client/app/lib/nav/actions';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';

type SimulationContextType = {
  simulation: SimulationQuery['simulation'] | undefined;
  isSimulating: boolean;
  onSimulate: () => Promise<void>;
  clearSimulation: () => void;
  simulateButtonTooltipTitle: string;
  shouldDisableSimulateButton: boolean | undefined;
};

export const SimulationContext = createContext<SimulationContextType | undefined>(
  undefined,
);

export const useSimulationContext = () => {
  const context = useContext(SimulationContext);

  if (context === undefined) {
    throw new Error('useSimulationContext must be used within a SimulationProvider');
  }

  return context;
};
const POLLING_INTERVAL = 2000;

export const SimulationProvider: FC<PropsWithChildren> = ({ children }) => {
  const navigation = useNavigation();
  const [simulation, setSimulation] = useState<SimulationQuery['simulation']>();
  const [simulationId, setSimulationId] = useState<SimulationId | null>(null);
  const [isPolling, setIsPolling] = useState(false);

  const [getSimulationStatus, { data: simulationData, stopPolling }] = useLazyQuery(
    QUERY_SIMULATION,
    { pollInterval: POLLING_INTERVAL },
  );

  const [simulateProtocolInstance, { loading: isSimulating }] = useMutation(
    SIMULATE_PROTOCOL_INSTANCE,
  );
  const { workflowConfig } = useWorkflowContext();
  const { protocolInstance } = useProtocolInstanceContext();

  const onSimulate = useCallback(async () => {
    const response = await simulateProtocolInstance({
      variables: {
        input: {
          id: protocolInstance.id,
          editVersion: protocolInstance.editVersion,
        },
      },
    });
    const simulationId =
      response.data?.simulateProtocolInstance.protocolInstance.simulation?.id;

    if (simulationId) {
      setSimulationId(simulationId);
      setIsPolling(true);
      await getSimulationStatus({ variables: { id: simulationId } });
    }
  }, [
    getSimulationStatus,
    protocolInstance.editVersion,
    protocolInstance.id,
    simulateProtocolInstance,
  ]);

  useEffect(() => {
    const simulationStatus = simulationData?.simulation.status;
    if (simulationId && simulationStatus && simulationStatus !== 'RUNNING') {
      stopPolling();
      setIsPolling(false);
      setSimulation(simulationData?.simulation);
      if (simulationStatus === 'COMPLETED') {
        navigation.navigate(simulationRoutes.openInSimulationDetails, {
          simulationId,
        });
      }
    }
  }, [
    simulationId,
    simulationData?.simulation.status,
    stopPolling,
    navigation,
    simulationData?.simulation,
  ]);

  const { simulateButtonTooltipTitle, shouldDisableSimulateButton } = useSimulationButton(
    { workflowConfig },
  );

  const clearSimulation = useCallback(() => setSimulation(undefined), []);

  const state = {
    simulation,
    isSimulating: isSimulating || isPolling,
    onSimulate,
    clearSimulation,
    simulateButtonTooltipTitle,
    shouldDisableSimulateButton,
  };

  return (
    <SimulationContext.Provider value={state}>{children}</SimulationContext.Provider>
  );
};
