import React, { forwardRef, useEffect, useMemo, useState } from 'react';

import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { useLoadAll } from 'client/app/api/PlateTypesApi';
import InstructionsStep from 'client/app/apps/simulation-details/instructions/InstructionsStep';
import { GroupsByStep } from 'client/app/apps/simulation-details/instructions/types';
import { useDisambiguate } from 'client/app/apps/simulation-details/instructions/useDisambiguate';
import { Simulation } from 'client/app/gql';
import { formatDateTime } from 'common/lib/format';
import { PlateType } from 'common/types/plateType';
import { Step } from 'common/types/steps';
import { PRINT_HEIGHT_A4 } from 'common/ui/components/Print';

type Props = {
  steps: Step[];
  groups: GroupsByStep;
};

export default function Instructions({ steps, groups, id }: Props & { id: string }) {
  const platesMap = usePlatesMap();
  const stepNumbers = useStepNumbers(steps);
  const disambiguate = useDisambiguate(steps);

  return platesMap ? (
    <Wrapper id={id}>
      {steps.map((step, i) => (
        <InstructionsStep
          step={step}
          plates={platesMap}
          key={i}
          numbers={stepNumbers}
          groups={groups}
          showConcentration={disambiguate}
        />
      ))}
    </Wrapper>
  ) : null;
}

type PropsForPrint = Props & { simulation: Simulation };

export const PrintableInstructions = forwardRef<HTMLDivElement, PropsForPrint>(
  function PrintableInstructions(
    { simulation, steps, groups }: PropsForPrint,
    ref: React.Ref<HTMLDivElement>,
  ) {
    const platesMap = usePlatesMap();
    const stepNumbers = useStepNumbers(steps);
    const disambiguate = useDisambiguate(steps);

    return platesMap ? (
      <Wrapper ref={ref} style={{ visibility: 'hidden' }}>
        <FirstPrintPage>
          <Typography variant="h1" fontWeight={700}>
            {simulation.name}
          </Typography>
          <Typography variant="h3" fontWeight={400}>
            simulated on {formatDateTime(new Date(simulation.startedAt))}
          </Typography>
        </FirstPrintPage>
        {steps.map((step, i) => (
          <InstructionsStep
            step={step}
            plates={platesMap}
            key={i}
            numbers={stepNumbers}
            showConcentration={disambiguate}
            groups={groups}
            isPrint
          />
        ))}
      </Wrapper>
    ) : null;
  },
);

function usePlatesMap() {
  const loadPlates = useLoadAll();
  const [platesMap, setPlatesMap] = useState<Record<string, PlateType>>();

  useEffect(() => {
    void loadPlates().then(plates => {
      setPlatesMap(Object.fromEntries(plates.map(plate => [plate.id, plate])));
    });
  }, [loadPlates]);

  return platesMap;
}

function useStepNumbers(steps: Step[]) {
  return useMemo(() => {
    return steps.reduce((tally, step, i) => {
      tally[step.id] = i + 1;
      return tally;
    }, {} as Record<string, number>);
  }, [steps]);
}

const Wrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(6, 6, 6, 4),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(8),
  overflow: 'auto',

  '@media print': {
    '@page': {
      size: 'A4 landscape',
      margin: '1cm',
    },
  },
}));

const FirstPrintPage = styled(Stack)({
  justifyContent: 'center',
  alignItems: 'center',

  height: PRINT_HEIGHT_A4,

  '@media print': {
    pageBreakAfter: 'always',
  },
});
