import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import {
  FetchResult,
  gql,
  MutationFunctionOptions,
  useApolloClient,
} from '@apollo/client';
import CallMadeIcon from '@mui/icons-material/CallMade';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import Fab from '@mui/material/Fab';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import { QUERY_WORKFLOW_BY_ID } from 'client/app/api/gql/queries';
import {
  convertElementInstancesForLayout,
  ensureMetaSetInWorkflow,
  snapElementInstancesToOrigin,
} from 'client/app/apps/template-workflows/editor/helpers';
import FormParameterEditor from 'client/app/apps/template-workflows/runner/FormParameterEditor';
import { WorkflowLayoutWithMouseModeContext as WorkflowLayout } from 'client/app/components/ElementPlumber/WorkflowLayout';
import DeviceSelector from 'client/app/components/Parameters/DeviceSelector';
import { ConfigParameterList } from 'client/app/components/WorkflowSettings/ConfigParameterList';
import {
  MigrateWorkflowMutation,
  UpdateWorkflowMutation,
  UpdateWorkflowMutationVariables,
  WorkflowByIdQuery,
  WorkflowEditModeEnum,
} from 'client/app/gql';
import { getLayoutDimensions } from 'client/app/lib/layout/LayoutHelper';
import { workflowRoutes } from 'client/app/lib/nav/actions';
import ParameterStateContextProvider, {
  ParameterStateContext,
} from 'client/app/lib/rules/elementConfiguration/ParameterStateContext';
import cloneWithUUID from 'client/app/lib/workflow/cloneWithUUID';
import { ALL_CONFIG_PROPERTIES } from 'client/app/lib/workflow/workflowConfigProperties';
import AutocompleteParameterValuesContextProvider from 'client/app/state/AutocompleteParameterValuesContext';
import { useConfiguredDevicesContext } from 'client/app/state/ConfiguredDevicesProvider/ConfiguredDevicesProvider';
import { groupBy, indexBy } from 'common/lib/data';
import {
  BundleParameters,
  ConfiguredDevice,
  Connection,
  Element,
  ElementInstance,
  ParameterValue,
  ServerSideElementInstance,
  TemplateWorkflowInput,
  WorkflowConfig,
} from 'common/types/bundle';
import { updateConfigAfterSet } from 'common/types/bundleConfigUtils';
import Colors from 'common/ui/Colors';
import { buildGridBackground } from 'common/ui/components/Workspace/layoutUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDebounce from 'common/ui/hooks/useDebounce';
import { useAdditionalPanelContext } from 'common/ui/providers/AdditionalPanelProvider';

const DEBOUNCE_SAVE_DELAY_MS = 1000;

// When WorkflowLayout rerenders there are glitches with the ports (they appear open and then closed).
// Luckily the layout won't change in a Form, so we can just memoize and hopefull avoid all-rerender.
const MemoWorkflowLayout = React.memo(WorkflowLayout, () => true);

type ElementInstanceWithParameters = ElementInstance & {
  Parameters: BundleParameters;
};
type Workflow = MigrateWorkflowMutation['migrateWorkflow']['workflow'];

type Props = {
  workflow: Workflow;
  elements: readonly Element[];
  onStartSimulation: () => void;
  saveWorkflow: (
    options?: MutationFunctionOptions<
      UpdateWorkflowMutation,
      UpdateWorkflowMutationVariables
    >,
  ) => Promise<FetchResult<UpdateWorkflowMutation>>;
};

export default function TemplateWorkflowForm({
  workflow: initialWorkflow,
  elements,
  saveWorkflow,
  onStartSimulation,
}: Props) {
  const classes = useStyles();
  const { additionalPanelContents } = useAdditionalPanelContext();
  const { setActiveConfiguredDeviceIds } = useConfiguredDevicesContext();
  const workflow = ensureMetaSetInWorkflow(initialWorkflow);

  const bundle = { ...workflow.workflow };
  const { Config: config } = bundle;
  const { id } = workflow;
  const isReadonly =
    workflow.editMode !== WorkflowEditModeEnum.ENABLED_LATEST_OWNED_BY_ME;

  const elementMap = useMemo(
    () => (elements ? indexBy(elements, 'name') : null),
    [elements],
  );

  const updateWorkflow = useUpdateWorkflow(id, saveWorkflow);

  // editing config parameters
  const onConfigParameterChange = useCallback(
    (parameterName: string, value: any) => {
      void updateWorkflow(
        getWorkflowConfigParameterUpdater(config, parameterName, value),
      );
    },
    [config, updateWorkflow],
  );

  // editing devices
  const configuredDevices = useMemo(
    () => config.configuredDevices ?? [],
    [config.configuredDevices],
  );

  const onSelectedDevicesChange = useCallback(
    (newConfiguredDevices: ConfiguredDevice[]) => {
      void updateWorkflow(getWorkflowDevicesUpdater(newConfiguredDevices));
    },
    [updateWorkflow],
  );

  const onClickParameters = useCallback(
    () => setActiveConfiguredDeviceIds(configuredDevices.map(v => v.id)),
    [configuredDevices, setActiveConfiguredDeviceIds],
  );

  const onInputValueChange = useCallback(
    (input: TemplateWorkflowInput, value: any) => {
      void updateWorkflow(getWorkflowInputsUpdater(input, value));
    },
    [updateWorkflow],
  );

  const adjustedElementInstances = useMemo(
    () =>
      elementMap
        ? snapElementInstancesToOrigin(
            convertElementInstancesForLayout(bundle.Elements.Instances, elementMap),
          )
        : [],
    [elementMap, bundle.Elements.Instances],
  );

  const connections = Object.values(bundle.Elements.InstancesConnections).map(
    cloneWithUUID,
  );

  const workflowBuilderPath = workflowRoutes.openInWorkflowBuilder.getPath({
    workflowId: workflow.id,
  });

  const groupedInputs = useMemo(
    () => groupBy(workflow.workflow.Template?.Inputs ?? [], 'ElementInstanceId'),
    [workflow.workflow.Template?.Inputs],
  );

  const configParameters = useMemo(() => {
    const parametersByName = indexBy(ALL_CONFIG_PROPERTIES, 'name');
    // return parameters in the same order as they are stored in the TW, user can change
    // the order
    return (workflow.workflow.Template?.Config ?? []).map(configParameter => {
      const parameter = parametersByName[configParameter.PropertyName];
      return {
        ...parameter,
        displayName: configParameter.DisplayName,
      };
    });
  }, [workflow.workflow.Template?.Config]);

  const parameters: BundleParameters = useMemo(() => {
    return Object.entries(workflow.workflow.Elements.Instances).reduce(
      (acc: BundleParameters, [name, info]: [string, ServerSideElementInstance]) => {
        return {
          ...acc,
          [name]: info.Parameters,
        };
      },
      {},
    );
  }, [workflow.workflow.Elements.Instances]);

  const elementInstances: ElementInstanceWithParameters[] = useMemo(() => {
    if (!elementMap) {
      return [];
    }
    return Object.entries(workflow.workflow.Elements.Instances).map(
      ([name, instance]) => ({
        ...instance,
        name,
        element: elementMap[instance.TypeName],
      }),
    );
  }, [elementMap, workflow.workflow.Elements.Instances]);
  const indexedElementInstances = useMemo(
    () => indexBy(elementInstances, 'Id'),
    [elementInstances],
  );

  const previewWidth = useWorkflowPreviewWidth(
    elementInstances,
    workflow.workflow.Elements.InstancesConnections,
  );

  return (
    <ParameterStateContextProvider
      parameters={parameters}
      elementInstances={elementInstances}
      connections={bundle.Elements.InstancesConnections}
    >
      <AutocompleteParameterValuesContextProvider
        instances={elementInstances}
        parameters={parameters}
      >
        {additionalPanelContents && (
          <Dialog fullWidth maxWidth="lg" open>
            {additionalPanelContents}
          </Dialog>
        )}
        <Card
          classes={{
            root: classes.cardContainer,
          }}
        >
          <CardContent className={classes.divider}>
            <Typography variant="h1">{workflow.name}</Typography>
            <Typography variant="body2">
              {workflow.workflow.Template?.Description}
            </Typography>
          </CardContent>
          <div className={cx(classes.workflow, classes.divider)}>
            <div className={classes.workflowViewer}>
              <MemoWorkflowLayout
                elementInstances={adjustedElementInstances}
                connections={connections}
                editMode="preview"
                previewWidth={previewWidth}
              />
            </div>
            <Link to={workflowBuilderPath}>
              <Fab
                color="primary"
                aria-label="Open in workflow builder"
                className={classes.workflowFab}
              >
                <CallMadeIcon />
              </Fab>
            </Link>
          </div>

          <div onClick={onClickParameters}>
            <CardContent className={classes.divider}>
              <>
                <Typography variant="h3">Workflow inputs</Typography>
                <List>
                  {Object.entries(groupedInputs).map(([instanceId, inputs]) => (
                    <div key={instanceId} className={classes.inputGroup}>
                      <Typography variant="h4" gutterBottom>
                        {indexedElementInstances[instanceId].name}
                      </Typography>
                      {inputs.map(input => (
                        <InputEditor
                          key={input.InputName}
                          indexedElementInstances={indexedElementInstances}
                          input={input}
                          isDisabled={isReadonly}
                          onValueChange={onInputValueChange}
                        />
                      ))}
                    </div>
                  ))}
                </List>
              </>
            </CardContent>
            <CardContent className={classes.divider}>
              <Typography variant="h3">Execution Mode</Typography>
              <DeviceSelector
                configuredDevices={configuredDevices}
                onSelectedDevicesChange={onSelectedDevicesChange}
                isDisabled={isReadonly}
              />
            </CardContent>

            <CardContent className={classes.divider}>
              <Typography variant="h3">Config</Typography>
              <ConfigParameterList
                parameters={configParameters}
                parameterValueDict={config.global}
                onChange={onConfigParameterChange}
                isDisabled={isReadonly}
              />
            </CardContent>
          </div>

          <CardActions className={classes.actions}>
            <Button color="primary" disabled={isReadonly} onClick={onStartSimulation}>
              Simulate
            </Button>
          </CardActions>
        </Card>
      </AutocompleteParameterValuesContextProvider>
    </ParameterStateContextProvider>
  );
}

function InputEditor({
  indexedElementInstances,
  input,
  isDisabled,
  onValueChange,
}: {
  indexedElementInstances: Partial<{
    [id: string]: ElementInstanceWithParameters;
  }>;
  input: TemplateWorkflowInput;
  isDisabled?: boolean;
  onValueChange: (input: TemplateWorkflowInput, value: any) => void;
}) {
  const classes = useStyles();
  const { getStateForParameter } = useContext(ParameterStateContext);
  const onChange = useCallback(
    (value: ParameterValue) => onValueChange(input, value),
    [onValueChange, input],
  );

  const elementInstance = indexedElementInstances[input.ElementInstanceId];
  useEffect(() => {
    if (!elementInstance) {
      console.error(`Element ${input.ElementInstanceId} doesn't exist on workflow.`);
    }
  });
  if (!elementInstance) {
    // This should never happen.
    return null;
  }
  const targetInput = elementInstance.Parameters[input.InputName];
  const anthaType =
    elementInstance.element.inputs.find(i => i.name === input.InputName)?.type || '';

  const parameterConfig =
    elementInstance.element.configuration?.parameters?.[input.InputName];
  const elementId = elementInstance.element.id;
  const paramState = getStateForParameter(elementInstance.name, input.InputName);
  return (
    <ListItem classes={{ root: classes.editor }}>
      <FormParameterEditor
        name={input.InputName}
        displayName={input.DisplayName}
        collapsed={input.Collapsed}
        elementId={elementId}
        value={targetInput}
        anthaType={anthaType}
        editorType={parameterConfig?.editor?.type ?? undefined}
        editorProps={parameterConfig?.editor?.additionalProps ?? undefined}
        onChange={onChange}
        isDisabled={isDisabled}
        paramState={paramState}
      />
    </ListItem>
  );
}

const useStyles = makeStylesHook({
  cardContainer: {
    maxWidth: '100%',
    marginTop: '2em',
  },
  workflow: {
    overflow: 'hidden',
    position: 'relative',

    backgroundColor: Colors.GREY_10,
  },
  workflowViewer: {
    display: 'block',
    overflow: 'hidden',
    padding: 50,

    background: buildGridBackground({ x: 0, y: 0 }, 1, 'dots'),
  },
  workflowFab: {
    position: 'absolute',
    right: 16,
    bottom: 16,
    backgroundColor: Colors.GREEN,
  },
  divider: {
    borderBottom: '1px #c4c8ca solid',
  },
  actions: {
    justifyContent: 'flex-end',
  },
  editor: {
    marginBottom: '1rem',
    backgroundColor: Colors.GREY_10,
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  inputGroup: {
    marginBottom: '2rem',
  },
});

function getWorkflowConfigParameterUpdater(
  config: WorkflowConfig,
  parameterName: string,
  value: any,
) {
  return (workflow: WorkflowByIdQuery['workflow']) => {
    const newConfig = updateConfigAfterSet({
      ...config,
      global: {
        ...config.global,
        [parameterName]: value,
      },
    });
    const newWorkflow = {
      ...workflow.workflow,
      Config: newConfig,
    };
    return newWorkflow;
  };
}

function getWorkflowDevicesUpdater(configuredDevices: ConfiguredDevice[]) {
  return (workflow: WorkflowByIdQuery['workflow']) => {
    // Templates don't support stages, there's no way to configure different devices for
    // each stage, so we just configure every stage to include all devices
    const deviceIds = configuredDevices.map(d => d.id);
    const stages = workflow.workflow.Stages?.map(stage => {
      return {
        ...stage,
        configuredDevices: deviceIds,
      };
    });
    const newWorkflow = {
      ...workflow.workflow,
      Config: updateConfigAfterSet({
        ...workflow.workflow.Config,
        configuredDevices: configuredDevices,
      }),
      Stages: stages,
    };

    return newWorkflow;
  };
}

function getWorkflowInputsUpdater(input: TemplateWorkflowInput, value: any) {
  return (workflow: WorkflowByIdQuery['workflow']) => {
    const elementName = Object.entries(workflow.workflow.Elements.Instances).find(
      ([, el]) => el.Id === input.ElementInstanceId,
    )?.[0];
    if (!elementName) {
      return workflow.workflow;
    }
    const newWorkflow = {
      ...workflow.workflow,
      Elements: {
        ...workflow.workflow.Elements,
        Instances: {
          ...workflow.workflow.Elements.Instances,
          [elementName]: {
            ...workflow.workflow.Elements.Instances[elementName],
            Parameters: {
              ...workflow.workflow.Elements.Instances[elementName].Parameters,
              [input.InputName]: value,
            },
          },
        },
      },
    };
    return newWorkflow;
  };
}

/**
 * This returns a function that will:
 * - update the workflow locally using the provided updater function.
 * - save it with the provided graphql mutation
 *
 * We debounce the save and make sure that there aren't multiple ongoing save.
 *
 * @param id Workflow's id
 * @param saveWorkflowMutation graphql mutation that saves the workflow
 */
function useUpdateWorkflow(
  id: WorkflowId,
  saveWorkflowMutation: (
    options?: MutationFunctionOptions<
      UpdateWorkflowMutation,
      UpdateWorkflowMutationVariables
    >,
  ) => Promise<FetchResult<UpdateWorkflowMutation>>,
) {
  const apollo = useApolloClient();

  // We do not want multiple save mutation happening at the same time.
  // Every time we do save, we will update this lock to be a Promise that will be resolved once the save mutation is done.
  const savingLock = useRef(Promise.resolve());

  const saveCurrent = useCallback(async () => {
    // We cannot save if we are already saving.
    await savingLock.current;
    savingLock.current = new Promise(resolve => {
      const data = apollo.readQuery({
        query: QUERY_WORKFLOW_BY_ID,
        variables: { id },
      });
      const workflow = data?.workflow;
      if (!workflow) {
        return;
      }
      return saveWorkflowMutation({
        variables: {
          id: workflow.id,
          version: workflow.version,
          name: workflow.name,
          workflow: workflow.workflow,
        },
      }).then(() => {
        return resolve();
      });
    });
  }, [apollo, id, saveWorkflowMutation]);

  const saveCurrentRef = useRef(saveCurrent);
  useEffect(() => {
    saveCurrentRef.current = saveCurrent;
  }, [saveCurrent]);
  const debouncedSaveCurrent = useDebounce(
    useCallback(() => saveCurrentRef.current?.(), []),
    DEBOUNCE_SAVE_DELAY_MS,
  );

  /**  This is the function returned by the hook.
   *   Give an updater function, it will update the apollo cache and schedule a save mutation.
   *   Due to the asynchronous dance we do with saving, it is a lot safer to read the cache directly
   *   instead of using probably outdated workflow.
   */
  const updateWorkflow = useCallback(
    (
      workflowUpdater: (
        workflow: WorkflowByIdQuery['workflow'],
      ) => WorkflowByIdQuery['workflow']['workflow'],
    ) => {
      const data = apollo.readQuery({
        query: QUERY_WORKFLOW_BY_ID,
        variables: { id },
      });
      const workflow = data?.workflow;
      if (!workflow) {
        return;
      }
      const newWorkflow = workflowUpdater(workflow);

      apollo.writeFragment({
        id: `Workflow:${workflow.id}`,
        fragment: gql`
          fragment LocalWorkflow on Workflow {
            name
            workflow
          }
        `,
        data: {
          name: newWorkflow.Meta.Name,
          workflow: newWorkflow,
        },
      });
      // Only try to save once there is no saving ongoing
      return savingLock.current.then(debouncedSaveCurrent);
    },
    [apollo, debouncedSaveCurrent, id],
  );
  return updateWorkflow;
}

const MAX_WORKFLOW_PREVIEW_WIDTH = 400;

/**
 * Recalculate optimal previewWidth depending on workflow elements
 */
const useWorkflowPreviewWidth = (
  elementInstances: ElementInstance[],
  connections: Connection[],
) =>
  useMemo(() => {
    const { width } = getLayoutDimensions(elementInstances, connections);
    /**
     * If there is not enough free space => take MAX_WORKFLOW_PREVIEW_WIDTH and zoom out (scale)
     * If there is more free space than needed => use width from dimensions (scale 1:1)
     * so that workflow of 1 or 2 elements is not too zoomed in
     */
    return Math.min(width, MAX_WORKFLOW_PREVIEW_WIDTH);
  }, [connections, elementInstances]);
