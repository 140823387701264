import { useCallback } from 'react';

import '@remirror/styles/all.css';

import { useDocChanged, useHelpers } from '@remirror/react';

export function OnChangeMarkdown({ onChange }: { onChange: (s: string) => void }) {
  const helpers = useHelpers(true);

  useDocChanged(
    useCallback(() => {
      onChange(helpers.getMarkdown());
    }, [helpers, onChange]),
  );

  return null;
}
