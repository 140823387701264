import React from 'react';

import Stack from '@mui/material/Stack';

import ExecuteProtocolInstanceButton from 'client/app/apps/simulation-details/ExecuteButton/ExecuteProtocolInstanceButton';
import {
  FirstDivider,
  SecondDivider,
} from 'client/app/apps/simulation-details/header/Dividers';
import ProtocolMenuContent from 'client/app/apps/simulation-details/header/ProtocolMenuContent';
import SimulationHeaderDropdown from 'client/app/apps/simulation-details/header/SimulationHeaderDropdown';
import { SimulationQuery } from 'client/app/gql';
import { protocolInstanceRoutes } from 'client/app/lib/nav/actions';
import RouteButton from 'common/ui/components/navigation/RouteButton';

type Props = {
  simulation: SimulationQuery['simulation'];
  protocolInstanceId: ProtocolInstanceId;
};

const ProtocolAdornment = ({ simulation, protocolInstanceId }: Props) => {
  const hasTasksToBeExecuted = simulation.tasks ? simulation.tasks.length > 0 : false;

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-end">
      <RouteButton
        data-heap-tracking="return-to-workflow-button"
        label="Return to Protocol"
        route={protocolInstanceRoutes.editProtocolInstance}
        routeParam={{
          id: protocolInstanceId,
        }}
      />
      <FirstDivider orientation="vertical" flexItem />
      <SimulationHeaderDropdown contents={ProtocolMenuContent} props={{ simulation }} />
      <SecondDivider orientation="vertical" flexItem />
      {hasTasksToBeExecuted && (
        <ExecuteProtocolInstanceButton
          protocolInstanceId={protocolInstanceId}
          hasStartedExecution={simulation.execution !== null}
        />
      )}
    </Stack>
  );
};

export default ProtocolAdornment;
