import React from 'react';

import { styled } from '@mui/material/styles';

import { experimentsStyles } from 'client/app/apps/experiments/commonExperimentsStyles';
import { useDeleteProtocolInstance } from 'client/app/apps/protocols/api/ProtocolsAPI';
import ProtocolInstancesListItem from 'client/app/apps/protocols/RecentProtocolInstanceList/ProtocolInstancesDialog/ProtocolInstancesListItem';
import { ProtocolInstancesQuery } from 'client/app/gql';

type Props = {
  data: ProtocolInstancesQuery;
  handleOnProtocolInstanceClick: (id: string) => void;
};

const ProtocolInstancesList = ({ data, handleOnProtocolInstanceClick }: Props) => {
  const protocolInstances = data.protocolInstances.items || [];

  const latestVersion = Math.max(
    ...protocolInstances.map(i => i.protocol.version),
  ) as ProtocolVersion;
  const showVersion = protocolInstances.some(i => i.protocol.version !== latestVersion);

  const { handleDeleteProtocolInstance } = useDeleteProtocolInstance();

  return (
    <List>
      {protocolInstances.map(protocolInstance => (
        <ProtocolInstancesListItem
          key={protocolInstance.id}
          instance={protocolInstance}
          showVersion={showVersion}
          onClick={() => handleOnProtocolInstanceClick(protocolInstance.id)}
          onDelete={() => handleDeleteProtocolInstance(protocolInstance.id)}
        />
      ))}
    </List>
  );
};

const List = styled('div')(({ theme }) => ({
  ...experimentsStyles(theme).list,
}));

export default ProtocolInstancesList;
