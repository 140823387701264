import React from 'react';

import { styled } from '@mui/material/styles';

import { ArrayElement, ExampleWorkflowsQuery } from 'client/app/gql';
import { EntityCard } from 'common/ui/components/EntityCard';
import { BookAndBulbIcon } from 'common/ui/icons';

export type ExampleWorkflow = ArrayElement<
  ExampleWorkflowsQuery['exampleWorkflows']['items']
>;

type Props = {
  exampleWorkflow: ExampleWorkflow;
  onDetailsClick: () => void;
};

const ExampleWorkflowCard = ({ exampleWorkflow, onDetailsClick }: Props) => {
  const { name, summary } = exampleWorkflow;

  const nameColumn = { label: name, value: summary };

  const interaction = {
    onClick: onDetailsClick,
  };

  return (
    <ExampleWorkflowEntityCard
      icon={<BookAndBulbIcon />}
      nameColumn={nameColumn}
      interaction={interaction}
    />
  );
};

const ExampleWorkflowEntityCard = styled(EntityCard)(({ theme }) => ({
  '.EntityCardContent-nameColumn-label': {
    ...theme.typography.subtitle2,
  },
  '.EntityCardContent-nameColumn-value': {
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
  },
}));

export default ExampleWorkflowCard;
