import React from 'react';

import Skeleton from '@mui/material/Skeleton';

import { Cell } from 'client/app/apps/protocols/RecentProtocolInstanceList/ProtocolInstanceListItem';
import { Grid } from 'client/app/apps/protocols/RecentProtocolInstanceList/ProtocolInstancesList';
import { RecentProtocolInstancesListHeader } from 'client/app/apps/protocols/RecentProtocolInstanceList/RecentProtocolInstancesListHeader';

const LoadingSkeleton = () => {
  return (
    <Grid>
      <RecentProtocolInstancesListHeader />
      <Cell gridRow={2} gridColumn="name">
        <TextSkeleton />
      </Cell>
      <Cell gridRow={2} gridColumn="author">
        <TextSkeleton />
      </Cell>
      <Cell gridRow={2} gridColumn="date">
        <TextSkeleton />
      </Cell>
    </Grid>
  );
};

const TextSkeleton = () => {
  return <Skeleton variant="text" width="75%" height={25} />;
};

export { LoadingSkeleton };
