import React, { useCallback, useState } from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';

import ParameterHeader from 'client/app/components/Parameters/ElementParameterHeader';
import ParameterEditor from 'client/app/components/Parameters/ParameterEditor';
import { ParameterState } from 'client/app/lib/rules/elementConfiguration/evaluateParameterState';
import { AdditionalEditorProps } from 'common/elementConfiguration/AdditionalEditorProps';
import { EditorType } from 'common/elementConfiguration/EditorType';
import IconButton from 'common/ui/components/IconButton';
import UIBox from 'common/ui/components/UIBox';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

// TODO: Refactor props to be based off of those of ParameterEditor
type Props = {
  /**
   * Parameter display name, shown in the header.
   */
  displayName: string;

  /**
   * Parameter name (original, not changed by configuration). Required for ElementParameterHelpIcon to be shown.
   */
  name: string;

  /**
   * Required for ElementParameterHelpIcon to be shown.
   */
  elementId: string;

  /**
   * If set to true, then the ParameterEditor will be collapsed and the user must click
   * to expand.
   */
  collapsed?: boolean;

  anthaType: string;
  value: any;
  onChange: (value: any) => void;

  /**
   * Disabled the Parameter Editor. Mostly for read-only form.
   */
  isDisabled?: boolean;

  /** Optionally provide an editor type from a parameter configuration */
  editorType?: EditorType;
  editorProps?: AdditionalEditorProps;

  paramState?: ParameterState;
};

export default function FormParameterEditor(props: Props) {
  const classes = useStyles();
  const [collapsed, setCollapsed] = useState(props.collapsed ?? false);
  const handleSetCollapse = useCallback(() => setCollapsed(prev => !prev), []);
  return (
    <>
      <div className={classes.paramHeader}>
        <IconButton
          onClick={handleSetCollapse}
          icon={collapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          size="small"
        />
        <ParameterHeader
          elementId={props.elementId}
          name={props.name}
          displayName={props.displayName}
          isRequired={props.paramState?.isRequired ?? false}
        />
      </div>

      <Collapse in={!collapsed} timeout="auto">
        <UIBox padding="vm">
          <ParameterEditor
            value={props.value}
            anthaType={props.anthaType}
            onChange={props.onChange}
            editorType={props.editorType}
            editorProps={props.editorProps}
            isDisabled={props.isDisabled}
          />
        </UIBox>
      </Collapse>
    </>
  );
}

const useStyles = makeStylesHook({
  paramHeader: {
    display: 'grid',
    gridTemplateColumns: 'max-content auto',
  },
});
