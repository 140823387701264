import { useMemo } from 'react';

import { Measurement } from 'common/types/mix';
import { Step, WellState } from 'common/types/steps';

export function useDisambiguate(steps: Step[]) {
  return useMemo(() => {
    const result = new Map<WellState, boolean>();
    const names = new Map<string, Set<Measurement>>();

    function foreachWellState(f: (well: WellState) => void) {
      for (const step of steps) {
        for (const layer of step.layers) {
          for (const layerPlates in layer.plates) {
            const layerPlate = layer.plates[layerPlates];
            for (const wellKey in layerPlate.wells) {
              const well = layerPlate.wells[wellKey];
              if (well.initial) {
                f(well.initial);
              }
              if (well.added) {
                f(well.added);
              }
              f(well.final);
            }
          }
        }
      }
    }
    function addNameConc(well: WellState) {
      const name = names.get(well.name);
      if (name) {
        if (well.name in well.contents) {
          name.add(well.contents[well.name]);
        }
      } else {
        if (well.name in well.contents) {
          names.set(well.name, new Set<Measurement>([well.contents[well.name]]));
        } else {
          names.set(well.name, new Set<Measurement>());
        }
      }
    }
    function addToResult(well: WellState) {
      const name = well.name;
      const concs = names.get(name)!;
      if (concs.size > 1) {
        // this name is ambiguous on concentration
        result.set(well, true);
      } else {
        result.set(well, false);
      }
    }

    // first gather all the names
    foreachWellState(addNameConc);
    // now build a map of well states to unambiguous names
    foreachWellState(addToResult);

    return (well: WellState) => {
      const shouldShowConc = result.get(well);
      if (shouldShowConc === undefined) {
        return false;
      }
      return shouldShowConc;
    };
  }, [steps]);
}
