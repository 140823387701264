import { graphql } from 'client/app/gql';

export const QUERY_PROTOCOL_TAGS = graphql(/* GraphQL */ `
  query ProtocolTags(
    $isLatest: Boolean
    $isDeleted: Boolean
    $isPublic: Boolean
    $isPublished: Boolean
    $userId: String
  ) {
    protocolTags(
      isLatest: $isLatest
      isDeleted: $isDeleted
      isPublic: $isPublic
      isPublished: $isPublished
      userId: $userId
    )
  }
`);
