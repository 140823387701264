import React, { useState } from 'react';

import { useQuery } from '@apollo/client';

import {
  ProtocolDetails,
  ProtocolDetailsSkeleton,
} from 'client/app/apps/protocols/dialogs/ProtocolDetailsDialog/ProtocolDetails';
import { QUERY_PROTOCOL_DETAILS } from 'client/app/apps/protocols/dialogs/ProtocolDetailsDialog/queryProtocolDetails';
import { RenderQuery } from 'common/ui/components/RenderQuery/RenderQuery';

type Props = {
  id: ProtocolId;
  version: ProtocolVersion;
  onClose: () => void;
};

const ProtocolLoader = ({ id, version, onClose }: Props) => {
  const [selectedVersion, setSelectedVersion] = useState(version);
  const query = useQuery(QUERY_PROTOCOL_DETAILS, {
    variables: { id, version: selectedVersion },
  });

  return (
    <RenderQuery
      query={query}
      renderData={ProtocolDetails}
      additionalDataProps={{ onClose, setSelectedVersion }}
      loadingComponent={ProtocolDetailsSkeleton}
      additionalLoadingProps={{ onClose }}
      renderNoData={() => null}
    />
  );
};

export { ProtocolLoader };
