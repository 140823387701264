import { useCallback, useEffect, useRef, useState } from 'react';

export const useScrollGuard = () => {
  const ref = useRef<HTMLDivElement>(null);

  const [isActive, setIsActive] = useState(false);

  const handleOnClick = useCallback(() => setIsActive(true), []);
  const handleOnMouseLeave = useCallback(() => setIsActive(false), []);

  useEffect(() => {
    const el = ref.current;
    if (!el) return;

    const listener = (e: WheelEvent) => {
      if (isActive) {
        e.preventDefault();
      }
    };

    el.addEventListener('wheel', listener, { passive: false });
    return () => el.removeEventListener('wheel', listener);
  }, [isActive]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsActive(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return { ref, isActive, handleOnClick, handleOnMouseLeave };
};
