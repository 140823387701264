import React, { FC, useRef } from 'react';

import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

import AddTagButton from 'common/ui/components/TagInput/AddTagButton';
import Options from 'common/ui/components/TagInput/Options';
import Tags from 'common/ui/components/TagInput/Tags';
import useTagInput from 'common/ui/components/TagInput/useTagInput';

export type Props = {
  tags: string[];
  options: string[];
  placeholder: string;
  allowFreeText?: boolean;
  onTagsChange: (tags: string[]) => void;
};

const TagInput: FC<Props> = ({
  tags,
  options,
  placeholder,
  allowFreeText = true,
  onTagsChange,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { value, handleInputChange, handleAddTag, handleDeleteTag, handleKeyDown } =
    useTagInput({ tags, allowFreeText, onTagsChange });

  const filteredOptions = value
    ? options.filter(
        option =>
          option.toLowerCase().includes(value.trim().toLowerCase()) &&
          !tags.includes(option),
      )
    : [];

  const hasTags = tags.length > 0;
  const showOptions = !!value && filteredOptions.length > 0;
  const optionsWidth = containerRef.current
    ? containerRef.current.clientWidth
    : undefined;

  return (
    <Wrapper>
      <Container ref={containerRef}>
        <StyledTextField
          variant="outlined"
          value={value}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          autoComplete="off"
          size="small"
          InputProps={{
            disableUnderline: true,
          }}
        />
        {allowFreeText && <AddTagButton onClick={() => handleAddTag(value)} />}
      </Container>
      <Popper
        anchorEl={containerRef.current}
        open={showOptions}
        placement="bottom-start"
        sx={{ zIndex: theme => theme.zIndex.modal }}
      >
        <Options width={optionsWidth} options={filteredOptions} onChange={handleAddTag} />
      </Popper>
      {hasTags && <Tags tags={tags} onDelete={handleDeleteTag} />}
    </Wrapper>
  );
};

const Wrapper = styled('div')({
  position: 'relative',
  width: '100%',
});

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  transition: 'border-color 0.2s',
  '&:hover': {
    borderColor: theme.palette.primary.main,
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  flex: 1,
  '& .MuiOutlinedInput-root': {
    padding: theme.spacing(1),
    '& fieldset': {
      border: 'none',
    },
    '&:hover fieldset': {
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      border: 'none',
    },
  },
}));

export default TagInput;
