import React, { useState } from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import DeleteButton from 'client/app/apps/protocols/EditProtocol/DefineProtocol/StepCard/DeleteButton';

type Props<TItem> = {
  items: TItem[];
  getItemKey: (item: TItem, index: number) => string;
  renderItemContents: (item: TItem, index: number) => React.ReactNode;
  emptyMessage: string;
  onDelete?: (index: number) => void;
};

export function StepContents<TItem>({
  items,
  getItemKey,
  renderItemContents,
  emptyMessage,
  onDelete,
}: Props<TItem>) {
  const [activeItemIndex, setActiveItemIndex] = useState<number | undefined>();

  if (items.length === 0) {
    return (
      <Typography variant="body1" component="i">
        {emptyMessage}
      </Typography>
    );
  }

  return (
    <StyledContentsWrapper variant="outlined">
      <List>
        {items.map((item, index) => {
          return (
            <ListItem
              key={`step-contents-${getItemKey(item, index)}`}
              onMouseEnter={() => setActiveItemIndex(index)}
              onMouseLeave={() => setActiveItemIndex(undefined)}
              secondaryAction={
                activeItemIndex === index &&
                onDelete && <DeleteButton onClick={() => onDelete(index)} />
              }
            >
              {renderItemContents(item, index)}
            </ListItem>
          );
        })}
      </List>
    </StyledContentsWrapper>
  );
}

const StyledContentsWrapper = styled(Paper)({
  borderRadius: '4px',
  width: '100%',
});
