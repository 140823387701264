import React, { FC, PropsWithChildren } from 'react';

import { styled } from '@mui/material/styles';

import { useScrollGuard } from 'common/ui/components/ScrollGuard/useScrollGuard';

type Props = PropsWithChildren<{
  deactivateOnMouseLeave?: boolean;
}>;

const ScrollGuard: FC<Props> = ({ children, deactivateOnMouseLeave }) => {
  const { ref, isActive, handleOnClick, handleOnMouseLeave } = useScrollGuard();

  return (
    <OuterContainer
      ref={ref}
      isActive={isActive}
      onClick={handleOnClick}
      onMouseLeave={() => deactivateOnMouseLeave && handleOnMouseLeave()}
    >
      <InnerContainer isActive={isActive}>{children}</InnerContainer>
    </OuterContainer>
  );
};

type ContainerProps = {
  isActive: boolean;
};

const OuterContainer = styled('div')<ContainerProps>(({ theme, isActive }) => ({
  border: isActive ? `1px solid ${theme.palette.primary.main}` : '1px solid transparent',
  borderRadius: theme.shape.borderRadius,
  transition: 'border 0.2s ease-in-out',
}));

const InnerContainer = styled('div')<ContainerProps>(({ isActive }) => ({
  pointerEvents: isActive ? 'auto' : 'none',
}));

export default ScrollGuard;
