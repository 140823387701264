import React, { useEffect, useState } from 'react';

import { useCopyProtocolInstanceAndNavigate } from 'client/app/apps/protocols/api/ProtocolsAPI';
import UIErrorBox from 'client/app/components/UIErrorBox';
import LinearProgress from 'common/ui/components/LinearProgress';

type Props = {
  id: ProtocolInstanceId;
};

export default function CopyProtocolInstance({ id }: Props) {
  const [error, setError] = useState<Error | null>(null);
  const { handleCopyProtocolInstanceAndNavigate, loading } =
    useCopyProtocolInstanceAndNavigate();

  useEffect(() => {
    const executeAsync = async () => {
      try {
        await handleCopyProtocolInstanceAndNavigate(id);
      } catch (e) {
        setError(e);
      }
    };
    void executeAsync();
  }, [handleCopyProtocolInstanceAndNavigate, id]);

  return (
    <>
      {loading && <LinearProgress />}
      {error && (
        <UIErrorBox>Could not create a copy of protocol run: {error.message}</UIErrorBox>
      )}
    </>
  );
}
