import { ChangeEvent, KeyboardEvent, useCallback, useState } from 'react';

type Props = {
  tags: string[];
  allowFreeText: boolean;
  onTagsChange: (tags: string[]) => void;
};

const useTagInput = ({ tags, allowFreeText, onTagsChange }: Props) => {
  const [value, setValue] = useState('');

  const handleInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  }, []);

  const handleAddTag = useCallback(
    (tag: string) => {
      const trimmedValue = tag.trim();
      if (!trimmedValue) return;
      setValue('');
      if (tags.includes(trimmedValue)) return;
      onTagsChange([...tags, trimmedValue]);
    },
    [onTagsChange, tags],
  );

  const handleDeleteTag = useCallback(
    (tagToDelete: string) => {
      onTagsChange(tags.filter(tag => tag !== tagToDelete));
    },
    [onTagsChange, tags],
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter' && allowFreeText) {
        handleAddTag(value);
      }
    },
    [allowFreeText, handleAddTag, value],
  );

  return {
    value,
    handleInputChange,
    handleAddTag,
    handleDeleteTag,
    handleKeyDown,
  };
};

export default useTagInput;
