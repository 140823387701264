import React from 'react';

import { useQuery } from '@apollo/client';

import { QUERY_PROTOCOL_TAGS } from 'client/app/apps/protocols/queryProtocolTags';
import {
  CategoriesList,
  EmptyCategoriesList,
  LoadingCategoriesList,
} from 'client/app/components/CategoriesList/CategoriesList';
import { ProtocolTagsQuery } from 'client/app/gql';
import { RenderQuery } from 'common/ui/components/RenderQuery/RenderQuery';

export type QueryVariables = {
  isLatest?: boolean;
  isDeleted?: boolean;
  isPublic?: boolean;
  isPublished?: boolean;
  userId?: string;
};

type SelectedTag = {
  selectedTag: string | undefined;
  onSelectTag: (tag: string) => void;
};

type Props = {
  variables: QueryVariables;
} & SelectedTag;

export default function ProtocolsCategoriesFetcher(props: Props) {
  const { variables, selectedTag, onSelectTag } = props;
  const tagsQuery = useQuery(QUERY_PROTOCOL_TAGS, {
    variables,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  return (
    <RenderQuery
      query={tagsQuery}
      renderData={(props: { data: ProtocolTagsQuery } & SelectedTag) => {
        const { data, ...remainingProps } = props;
        return <CategoriesList tags={data.protocolTags} {...remainingProps} />;
      }}
      additionalDataProps={{ selectedTag, onSelectTag }}
      renderNoData={EmptyCategoriesList}
      loadingComponent={LoadingCategoriesList}
    />
  );
}
