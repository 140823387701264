import React from 'react';

import { InlineMarkdownEditor } from 'client/app/components/Markdown/InlineMarkdownEditor';
import useDebounce from 'common/ui/hooks/useDebounce';

type Props = {
  description: string;
  onSave: (description: string) => void;
};

/**
 * Shows the description of something that on click enables markdown editing
 */
export default function EditableDisplayDescription({ description, onSave }: Props) {
  const debounceSave = useDebounce(onSave, 200);

  return (
    <InlineMarkdownEditor
      initialContent={description}
      onChange={debounceSave}
      placeholder="+ Add description"
      highlightOnHover
    />
  );
}
