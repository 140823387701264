import React, { useState } from 'react';

import { styled } from '@mui/material/styles';

import { hasExecution } from 'client/app/apps/simulation-details/dataUtils';
import DefaultAdornment from 'client/app/apps/simulation-details/header/DefaultAdornment';
import ProtocolAdornment from 'client/app/apps/simulation-details/header/ProtocolAdornment';
import useSimulationProtocolInstance from 'client/app/apps/simulation-details/useSimulationProtocolInstance';
import FavoriteStar from 'client/app/components/FavoriteStar';
import { SimulationOrExecutionStatusesEnum, SimulationQuery } from 'client/app/gql';
import { formatShortTransitiveSimulationStatus } from 'client/app/lib/formatTransitiveSimulationStatus';
import LinearProgress from 'common/ui/components/LinearProgress';
import CommonHeader, {
  CommonHeaderInfo,
} from 'common/ui/components/simulation-details/CommonHeader';

type Props = {
  simulation: SimulationQuery['simulation'];
};

const SimulationDetailsHeader = React.memo(({ simulation }: Props) => {
  const { protocolInstanceId, loading: loadingProtocol } = useSimulationProtocolInstance(
    simulation.id,
  );

  // Convert to a format the CommonHeader understands.
  // The CommonHeader is used in AnthaHub, too.
  const simulationHeaderInfo: CommonHeaderInfo = {
    name: 'Simulation details',
    submitterName: simulation.user.displayName,
    dateCreated: new Date(simulation.startedAt),
    dateFinished: simulation.finishedAt ? new Date(simulation.finishedAt) : undefined,
  };

  // Useful when the UI controls inside the header kick off requests
  const [headerLoading, setHeaderLoading] = useState<boolean>(false);

  const favoriteStarIcon = (
    <FavoriteStar
      isFavoritedByCurrentUser={simulation.isFavoritedByCurrentUser ?? false}
      favoritedBy={simulation.favoritedBy ?? []}
      simulationId={simulation.id}
      size="small"
    />
  );

  const simulationWithExecution = hasExecution(simulation);

  const simulationStatus = formatShortTransitiveSimulationStatus(
    simulationWithExecution
      ? SimulationOrExecutionStatusesEnum.SIMULATION_SUCCESS
      : simulation.transitiveStatus,
  );

  return (
    <>
      {headerLoading && <StyledLinearProgress />}
      <CommonHeader
        headerInfo={simulationHeaderInfo}
        status={simulationStatus}
        simulationSeriesPart={simulation.simulationSeriesPart}
        estimatedTimeSeconds={simulation.timeEstimateSeconds || null}
        createdAtAction="Created"
        startAdornment={favoriteStarIcon}
        endAdornment={
          loadingProtocol ? null : protocolInstanceId === undefined ? (
            <DefaultAdornment
              simulation={simulation}
              setHeaderLoading={setHeaderLoading}
            />
          ) : (
            <ProtocolAdornment
              simulation={simulation}
              protocolInstanceId={protocolInstanceId}
            />
          )
        }
      />
    </>
  );
});

const StyledLinearProgress = styled(LinearProgress)({
  // Display the progress bar at the top of the header,
  // don't push content down.
  position: 'absolute',
  width: '100%',
});

export default SimulationDetailsHeader;
