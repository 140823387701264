import React from 'react';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { DeviceConfiguratorHeader } from 'client/app/components/Parameters/DeviceConfigurator/DeviceConfigurator';
import doNothing from 'common/lib/doNothing';
import IconButtonWithPopper from 'common/ui/components/IconButtonWithPopper';

type Props = {
  header: DeviceConfiguratorHeader;
};

export function ExecutionModeHeader({ header }: Props) {
  const helpLink = getHelpLink(header);
  return (
    <Stack alignItems="center" direction="row" justifyContent="space-between">
      <Typography variant="subtitle2">{header}</Typography>
      <IconButtonWithPopper
        content={
          <Typography variant="caption">
            {getHelpContent(header)}
            {helpLink && (
              <>
                <br />
                <br />
                To learn more, click{' '}
                <Link
                  href={helpLink}
                  target="_blank"
                  rel="noopenner noreferrer"
                  variant="inherit"
                  underline="hover"
                >
                  here
                </Link>
                .
              </>
            )}
          </Typography>
        }
        iconButtonProps={{ size: 'xsmall', icon: <HelpOutlineIcon /> }}
        onClick={doNothing}
      />
    </Stack>
  );
}

function getHelpContent(header: DeviceConfiguratorHeader) {
  switch (header) {
    case 'Mode':
      return 'Set the workflow to be run manually, via a device, or as a data workflow. This will determine the instructions type and other device settings.';
    case 'Deck Layout':
      return 'Select a deck layout, then tell Synthace where you want it to allocate your labware.';
    case 'Tip Types':
      return 'Synthace will calculate the best tip types for your workflow, but if you would prefer to select them yourself, you can.';
    case 'Input Plate Types':
      return 'If you did not prepare your input plates in advance, select the plate types that you want to use for your input plates.';
    default:
      return undefined;
  }
}

function getHelpLink(header: DeviceConfiguratorHeader) {
  switch (header) {
    case 'Deck Layout':
      return 'https://intercom.help/antha/en/articles/5588117-deck-options';
    case 'Tip Types':
      return 'https://intercom.help/antha/en/articles/5550553-select-the-tip-types-that-you-want-to-use';
    case 'Input Plate Types':
      return 'https://intercom.help/antha/en/articles/5409800-select-the-plate-types-that-you-want-to-use-for-your-input-plates';
    case 'Mode':
    default:
      return undefined;
  }
}
