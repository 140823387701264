import React, { PropsWithChildren, useState } from 'react';

import Box from '@mui/material/Box';
import Paper, { PaperProps } from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import { CardExpander } from 'client/app/apps/protocols/EntityCardExpander/CardExpander';
import Colors from 'common/ui/Colors';

const EXPAND_BUTTON_WIDTH = '34px';
const ENTITY_CARD_EXPANDABLE_GRID_TEMPLATE_COLUMNS = `[expand] ${EXPAND_BUTTON_WIDTH} [card] 1fr`;

type Props = {
  /**
   * If set, the component is controlled. An uncontrolled component will show a button to expand
   * and close the card while a controlled one will not
   */
  expanded?: boolean;
  expandedBorderColor?: string;
  whenExpanded: React.ReactNode;
};

const EntityCardExpander = ({
  children,
  whenExpanded,
  expandedBorderColor,
  expanded: expandedProp,
}: PropsWithChildren<Props>) => {
  const [expandedState, setExpanded] = useState(false);
  const controlled = expandedProp !== undefined;
  const expanded = controlled ? expandedProp : expandedState;

  return (
    <Card
      variant="outlined"
      expanded={expanded}
      controlled={controlled}
      expandedBorderColor={expandedBorderColor}
    >
      {!controlled && (
        <CardExpander
          expanded={expanded}
          onClick={() => setExpanded(expanded => !expanded)}
        />
      )}
      <Box sx={{ gridColumn: 'card' }}>{children}</Box>
      {expanded && (
        <Box sx={{ gridColumn: '1 / -1', overflow: 'hidden' }}>{whenExpanded}</Box>
      )}
    </Card>
  );
};
type CardProps = {
  expanded?: boolean;
  controlled?: boolean;
  expandedBorderColor?: string;
} & PaperProps;

const Card = styled(Paper, {
  shouldForwardProp: prop => prop !== 'expanded',
})<CardProps>(({ expanded, controlled, expandedBorderColor }) => ({
  display: 'grid',
  gridTemplateColumns: controlled
    ? '[card] 1fr'
    : ENTITY_CARD_EXPANDABLE_GRID_TEMPLATE_COLUMNS,
  gridTemplateRows: expanded ? 'auto 1fr' : 'auto',
  alignItems: 'stretch',
  borderRadius: '8px',
  overflow: 'hidden',
  ...(expanded && {
    borderColor: expandedBorderColor ?? Colors.INDIGO_90,
  }),
}));

export { EntityCardExpander };
