import React from 'react';

import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography, { TypographyProps } from '@mui/material/Typography';

import useOverflow from 'common/ui/hooks/useOverflow';

type Props = Pick<TypographyProps, 'sx'> & {
  name: string;
  tooltip?: string;
};

export default function DisplayName({ name, tooltip = '', sx }: Props) {
  const { isOverflowing, handleOverflow, ref } = useOverflow();
  const title = isOverflowing ? `Name: ${name}\n${tooltip}` : tooltip;
  const wrappedTitle = title ? <MultiLine>{title}</MultiLine> : null;

  return (
    <Tooltip arrow title={wrappedTitle} disableInteractive>
      <Typography
        noWrap
        ref={ref}
        onMouseOver={handleOverflow}
        sx={{ ...{ fontWeight: 700 }, ...sx }}
      >
        {name}
      </Typography>
    </Tooltip>
  );
}

const MultiLine = styled('div')(({ theme }) => ({
  whiteSpace: 'pre-wrap',
  lineHeight: 1.4,
  padding: theme.spacing(1),
}));
