import { graphql } from 'client/app/gql';

export const QUERY_PROTOCOL_INSTANCES = graphql(/* GraphQL */ `
  query ProtocolInstances($protocolId: String, $search: String, $isDeleted: Boolean) {
    protocolInstances(protocolId: $protocolId, search: $search, isDeleted: $isDeleted) {
      items {
        id
        name
        isOwnedByUser
        createdBy {
          id
          displayName
        }
        createdAt
        protocol {
          id
          version
        }
        executionId
        status {
          ...BreadcrumbsStep
        }
      }
    }
  }
`);
