import React, { useEffect, useRef, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import ExperimentsDialog from 'client/app/apps/experiments/ExperimentsDialog';
import { useCurrentEntity } from 'client/app/components/nav/breadcrumbs/BreadcrumbsEntityContext';
import BreadcrumbsMenu from 'client/app/components/nav/breadcrumbs/components/BreadcrumbsMenu';
import { NodeItems } from 'client/app/components/nav/breadcrumbs/components/ItemsDropdown';
import { ExperimentAdditive } from 'client/app/components/nav/breadcrumbs/useAddEntityToExperiment';
import { BreadcrumbsEntityDropdownProps } from 'client/app/components/nav/breadcrumbs/useBreadcrumbsEntity';
import CreateNewExperimentButton from 'client/app/components/nav/CreateNewExperimentButton';
import { useExperimentPopoverContext } from 'client/app/components/nav/ExperimentPopoverContext';
import CANCEL_CHOICE from 'client/app/components/Parameters/cancel';
import stopPropagation from 'common/lib/stopPropagation';
import Colors from 'common/ui/Colors';
import useDialog from 'common/ui/hooks/useDialog';

/**
 * Allows a user to add the entity to an experiment or view experiments that contain the entity.
 * The functionality differs depending on how many experiments the entity is in.
 *
 * 0 experiments  - An 'add' button is rendered allowing user to add the entity to an experiment.
 * 1 experiment   - No UI is rendered, as the user will click on the parent node itself to go
 *                  directly to taht experiment
 * >1 experiments - A 'plus' button is rendered which opens up a popover containing the list of
 *                  experiments, which a user can click to go to that experiment.
 */
export default function ExperimentsDropdown(props: BreadcrumbsEntityDropdownProps) {
  const experimentCount = props.node.items.length;
  const hasOnlyOneExperiment = experimentCount === 1;
  const hasMoreThanOneExperiment = experimentCount > 1;

  const { popover, showPopover, hidePopover } = useExperimentPopoverContext();
  const openButtonRef = useRef<HTMLButtonElement>(null);

  // We store the 'popover' locally to manage state here. This is because 'popover'
  // from context can be null (when MUI-Popover is closed), but the null value
  // means we don't render anything inside the MUI-Popover while it closes, and this
  // breaks the close animation for the MUI-Popover. Storing this state allows us to
  // to keep a non-null value, and then update as required in the useEffect if 'popover'
  // from context changes.
  const [popoverToRender, setPopoverToRender] = useState(popover);
  useEffect(() => {
    if (popover) {
      setPopoverToRender(popover);
    }
  }, [popover]);

  let popoverCopy = '';
  if (popoverToRender === 'structured-data') {
    popoverCopy =
      'In order to structure data, the execution must be added to an experiment.';
  }
  if (popoverToRender === 'DOE') {
    popoverCopy =
      'In order to create a DOE design, the workflow must be added to an experiment.';
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    stopPropagation(event);
    showPopover('standard');
  };
  const handleAnyMenuClick = (event: React.MouseEvent) => {
    stopPropagation(event);
    if (hasMoreThanOneExperiment) {
      hidePopover();
    }
  };

  const currentEntity = useCurrentEntity<ExperimentAdditive>();
  const [experimentsDialog, openExperimentsDialog] = useDialog(ExperimentsDialog);

  const openExperimentSelectionDialog = async () => {
    if (currentEntity.addToExistingExperiment) {
      const experimentId = await openExperimentsDialog({});
      if (experimentId !== CANCEL_CHOICE) {
        hidePopover();
        await currentEntity.addToExistingExperiment(experimentId);
      }
    }
  };
  const handleCreateExperiment = async (experimentName: string) => {
    if (currentEntity.addToNewExperiment) {
      hidePopover();
      await currentEntity.addToNewExperiment(experimentName);
    }
  };

  if (!currentEntity.addToExistingExperiment || !currentEntity.addToNewExperiment) {
    return null;
  }

  if (hasOnlyOneExperiment) {
    return null;
  }

  return (
    <>
      <OpenButton
        data-heap-tracking="breadcrumbs-experiments-dropdown-button"
        ref={openButtonRef}
        onClick={handleClick}
      >
        {hasMoreThanOneExperiment ? (
          <Typography variant="subtitle2">{experimentCount}</Typography>
        ) : (
          <AddIcon fontSize="small" />
        )}
      </OpenButton>
      <BreadcrumbsMenu
        data-heap-tracking="breadcrumbs-experiments-dropdown-menu"
        anchorEl={openButtonRef.current}
        open={!!popover}
        onClose={hidePopover}
        onClick={handleAnyMenuClick}
        onDoubleClick={stopPropagation}
      >
        {hasMoreThanOneExperiment ? (
          <NodeItems {...props} />
        ) : (
          <ExperimentButtons onKeyDown={stopPropagation}>
            {popoverCopy && <PopoverCopy variant="body1">{popoverCopy}</PopoverCopy>}
            <AddToExisting
              data-heap-tracking="breadcrumbs-experiments-dropdown-add-to-existing-button"
              variant="outlined"
              size="small"
              color="inherit"
              onClick={openExperimentSelectionDialog}
            >
              <Typography variant="button">Add to an existing experiment</Typography>
            </AddToExisting>
            <AddToNew
              data-heap-tracking="breadcrumbs-experiments-dropdown-create-new-experiment-button"
              onCreateNewExperiment={handleCreateExperiment}
              variant="secondary"
              color="primary"
            />
            {experimentsDialog}
          </ExperimentButtons>
        )}
      </BreadcrumbsMenu>
    </>
  );
}

// #region Styles

const OpenButton = styled(IconButton)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  backgroundColor: theme.palette.primary.main,
  color: Colors.GREY_0,

  padding: 0,
  marginLeft: theme.spacing(2),
  height: 20,
  width: 20,

  '&.MuiIconButton-root:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const AddToExisting = styled(Button)(({ theme }) => ({
  display: 'block',
  width: 250,
  height: 24,
  borderColor: theme.palette.divider,
  padding: 0,
}));

const AddToNew = styled(CreateNewExperimentButton)(() => ({
  display: 'block',
  width: 250,
  height: 24,
  padding: 0,
}));

const ExperimentButtons = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3, 4, 4),
  gap: theme.spacing(3),
}));

const PopoverCopy = styled(Typography)({
  width: 250,
});

// #endregion
