import React from 'react';

import { useQuery } from '@apollo/client';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';

import { QUERY_PROTOCOL_VERSIONS } from 'client/app/api/gql/queries';
import { ProtocolVersionsQuery } from 'client/app/gql';
import { RenderQuery } from 'common/ui/components/RenderQuery/RenderQuery';
import Tooltip from 'common/ui/components/Tooltip';

type Selected = {
  selected: ProtocolVersion;
  setSelected: (version: ProtocolVersion) => void;
};

type Props = Selected & {
  id: ProtocolId;
};

const VersionSelector = ({ id, selected, setSelected }: Props) => {
  const query = useQuery(QUERY_PROTOCOL_VERSIONS, { variables: { id } });

  return (
    <RenderQuery
      query={query}
      loadingComponent={SkeletonComponent}
      renderData={DataComponent}
      additionalDataProps={{ selected, setSelected }}
      renderNoData={() => null}
    />
  );
};

const SkeletonComponent = () => {
  return <Skeleton width={110} height={33} />;
};

type DataComponentProps = Selected & {
  data: ProtocolVersionsQuery;
};

const DataComponent = ({ data, selected, setSelected }: DataComponentProps) => {
  const versions = [...data.protocols.items].sort((a, b) => b.version - a.version);
  const otherVersionsAvailable = versions.length > 1;

  const handleChange = (evt: SelectChangeEvent<ProtocolVersion>) => {
    if (typeof evt.target.value === 'string') {
      setSelected(parseInt(evt.target.value) as ProtocolVersion);
    } else {
      setSelected(evt.target.value);
    }
  };

  const tooltipTitle = otherVersionsAvailable
    ? ''
    : 'This is the only version of this protocol';
  return (
    <Tooltip title={tooltipTitle}>
      <Select<ProtocolVersion>
        value={selected}
        onChange={handleChange}
        disabled={!otherVersionsAvailable}
        size="small"
        MenuProps={{
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          transformOrigin: { vertical: 'top', horizontal: 'center' },
        }}
      >
        {versions.map(protocol => (
          <MenuItem key={`${protocol.version}`} value={protocol.version}>
            Version {protocol.version}
          </MenuItem>
        ))}
      </Select>
    </Tooltip>
  );
};

export { VersionSelector };
