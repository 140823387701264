import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';

export const FirstDivider = styled(Divider)(({ theme: { spacing } }) => ({
  // Center vertically
  alignSelf: 'center',
  height: '24px',
  marginLeft: spacing(4),
  marginRight: spacing(5),
}));

export const SecondDivider = styled(Divider)(({ theme: { spacing } }) => ({
  // Center vertically
  alignSelf: 'center',
  height: '24px',
  marginLeft: spacing(5),
  marginRight: spacing(5),
}));
