import React, { useCallback, useContext, useMemo } from 'react';

import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import ElementParameterGroupHeader from 'client/app/components/Parameters/ElementParameterGroupHeader';
import ParameterHeader from 'client/app/components/Parameters/ElementParameterHeader';
import ParameterEditor, {
  ParameterEditorHelperText,
} from 'client/app/components/Parameters/ParameterEditor';
import { PLATE_BASED_MIXING_PARAMETER } from 'client/app/components/Parameters/PlateLayout/plateLayoutUtils';
import {
  ParameterState,
  ParameterStateRuleResult,
} from 'client/app/lib/rules/elementConfiguration/evaluateParameterState';
import { ParameterStateContext } from 'client/app/lib/rules/elementConfiguration/ParameterStateContext';
import { getParameterDisplayName } from 'client/app/lib/workflow/elementConfigUtils';
import { ConnectionMaskDict } from 'client/app/lib/workflow/types';
import { sanitiseParameterValue } from 'common/elementConfiguration/parameterUtils';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { groupBy } from 'common/lib/data';
import { Parameter, ParameterValue, ParameterValueDict } from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import ConnectionOnlyEditor from 'common/ui/components/ParameterEditors/ConnectionOnlyEditor';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type ParamChangeCallback = (
  paramName: string,
  value: ParameterValueDict,
  elementInstanceId?: string,
) => void;

/**
 * Props needed at the InstanceParameter level that need to be passed in at the
 * ElementParameterGroupList level. Used by every component in this file.
 */
type GeneralProps = {
  onChange: ParamChangeCallback;
  onPendingChange?: ParamChangeCallback;
  elementId: string;
  /** Required by the PlateReaderProtocol editors. */
  workflowId?: string;
  /** Required by Table UI */
  workflowName?: string;
  instanceName: string;
  instanceId: string;
  /** Used to determine which parameters have values provided through connections */
  connectionMaskDict?: ConnectionMaskDict;
  isDisabled?: boolean;
  defaultParameters: ParameterValueDict;
};

type Props = GeneralProps & {
  /** A list of parameters (information about the parameters, not values) */
  parameters: readonly Parameter[];
  /** A map of parameter names to to their values */
  parameterValueDict: ParameterValueDict;
  /** Evaluation results from the Element Configuration rule engine */
  ParameterStateRuleResult?: ParameterStateRuleResult;
  showValidation?: boolean;
};

function isParameterSet(parameterValue: ParameterValue) {
  return parameterValue !== null && parameterValue !== undefined;
}

export default function ElementParameterGroupList(props: Props) {
  const { areAllGroupParametersHidden } = useContext(ParameterStateContext);

  const groupedParams = useMemo(
    () =>
      Object.entries(
        groupBy(
          props.parameters
            // Remove any deprecated parameters that don't have values set.
            // We don't want them to factor into the logic behind whether groups
            // are hidden or not.
            .filter(
              parameter =>
                !(parameter.configuration?.isVisible === false) ||
                isParameterSet(props.parameterValueDict[parameter.name]),
            )
            .map(parameter => ({
              ...parameter,
              groupName: parameter.groupName ?? '',
            })),
          'groupName',
        ),
      ),
    [props.parameterValueDict, props.parameters],
  );

  const areParametersUngrouped = groupedParams.length === 1 && groupedParams[0][0] === '';

  return (
    <>
      {groupedParams
        .filter(
          ([groupName, _]) =>
            areParametersUngrouped ||
            !areAllGroupParametersHidden(props.instanceName, groupName),
        )
        .map(([groupName, parametersInGroup]) => {
          const onlyGroup = groupedParams.length === 1;
          const groupProps = {
            ...props,
            groupName,
            parameters: parametersInGroup,
            onlyGroup,
          };
          return (
            <ElementParameterGroup
              workflowId={props.workflowId}
              workflowName={props.workflowName}
              key={`param-group-${groupName}`}
              {...groupProps}
              isDisabled={props.isDisabled}
            />
          );
        })}
    </>
  );
}

type ElementParameterGroupProps = Props & {
  groupName: string;
  onlyGroup: boolean;
};

const ElementParameterGroup = React.memo(function ElementParameterGroup(
  props: ElementParameterGroupProps,
) {
  const classes = useStyles();
  const description = props.parameters[0]?.groupDescription;
  return (
    <>
      <ElementParameterGroupHeader name={props.groupName} onlyGroup={props.onlyGroup} />
      <div className={cx({ [classes.paramGroupInputs]: !!props.groupName })}>
        {description && (
          <div className={classes.parameterGroupDescription}>{description}</div>
        )}
        <ElementParameterList {...props} parameters={props.parameters} />
      </div>
    </>
  );
});

type ElementParameterListProps = Props;

export const ElementParameterList = React.memo(function ElementParameterList(
  props: ElementParameterListProps,
) {
  const { parameters, parameterValueDict, ...instanceParameterProps } = props;
  const { getStateForParameter } = useContext(ParameterStateContext);

  return (
    <>
      {parameters.map(parameter => (
        <HideableInstanceParameter
          key={parameter.name}
          parameter={parameter}
          // This is important: never pass valueDict that contains values for all parameters to
          // the component for a single parameter. That will force all parameter editors to rerender
          // when only single parameter value changes
          paramValue={parameterValueDict[parameter.name]}
          paramState={getStateForParameter(props.instanceName, parameter.name)}
          parameterValueDict={parameterValueDict}
          {...instanceParameterProps}
        />
      ))}
    </>
  );
});

type InstanceParameterProps = GeneralProps &
  Pick<Props, 'parameterValueDict' | 'showValidation'> & {
    parameter: Parameter;
    paramValue: ParameterValue;
    /** Properties of the parameter calculated by the rules engine. */
    paramState: ParameterState | undefined;
  };

const HideableInstanceParameter = React.memo(function HideableInstanceParameter(
  props: InstanceParameterProps,
) {
  const classes = useStyles();
  const { paramState, parameter } = props;

  const isPlateBasedMixing = useFeatureToggle('PLATE_BASED_MIXING');
  let isVisible = paramState?.isVisible ?? true;
  if (parameter.name === PLATE_BASED_MIXING_PARAMETER) {
    isVisible = isVisible && isPlateBasedMixing;
  }

  return (
    <Collapse in={isVisible}>
      <div className={classes.parameterContainer} key={`param-${parameter.name}`}>
        <InstanceParameter {...props} />
      </div>
    </Collapse>
  );
});

export function InstanceParameter({
  elementId,
  paramState,
  onChange,
  instanceName,
  instanceId,
  onPendingChange,
  parameter,
  paramValue,
  showValidation,
  ...props
}: InstanceParameterProps) {
  const classes = useStyles();
  const isElementConfigDebugModeEnabled = useFeatureToggle(
    'ELEMENT_CONFIGURATION_DEBUG_MODE',
  );
  const isEnabledElementParameterValidation = useFeatureToggle('PARAMETER_VALIDATION');

  const onChangeWithSanitise = useCallback(
    (newParamValue: any) => {
      const sanitisedNewValue = sanitiseParameterValue(newParamValue);
      onChange(parameter.name, sanitisedNewValue, instanceName);
    },
    [instanceName, onChange, parameter.name],
  );

  const onPendingChangeWithSanitise = useCallback(
    (newParamValue: any) => {
      const sanitisedNewValue = sanitiseParameterValue(newParamValue);
      onPendingChange?.(parameter.name, sanitisedNewValue, instanceName);
    },
    [instanceName, onPendingChange, parameter.name],
  );

  const anthaType = parameter.type;
  const displayName = getParameterDisplayName(parameter, isElementConfigDebugModeEnabled);
  const editorType = parameter.configuration?.editor.type;
  const editorProps = parameter.configuration?.editor.additionalProps ?? undefined;
  const placeholder = parameter.configuration?.editor.placeholder;

  // If an output from another element instance provides this parameter with a value
  // already, this will be a string label showing which output port that is.
  const outputProvidingValue = props.connectionMaskDict?.[parameter.name];

  const editorDisabled = paramState?.isEnabled === false || props.isDisabled;

  let helperText: ParameterEditorHelperText | undefined = undefined;
  if (paramState?.errorMessages?.[0]) {
    helperText = { type: 'error', message: paramState.errorMessages[0] };
  } else if (paramState?.warningMessages?.[0]) {
    helperText = { type: 'warning', message: paramState.warningMessages[0] };
  }

  return (
    <>
      <div>
        <ParameterHeader
          elementId={elementId}
          name={parameter.name}
          displayName={displayName}
          isRequired={paramState?.isRequired}
          isValid={
            isEnabledElementParameterValidation && showValidation
              ? paramState?.isValid
              : true
          }
        />
        {parameter.configuration?.shortDescription && (
          <Typography variant="caption" className={classes.description}>
            {parameter.configuration?.shortDescription}
          </Typography>
        )}
      </div>
      {!outputProvidingValue ? (
        <ParameterEditor
          isDisabled={editorDisabled}
          anthaType={anthaType}
          value={paramValue}
          workflowId={props.workflowId}
          workflowName={props.workflowName}
          onChange={onChangeWithSanitise}
          onPendingChange={onPendingChangeWithSanitise}
          elementInstanceId={instanceId}
          editorType={editorType}
          editorProps={editorProps}
          placeholder={placeholder}
          helperText={helperText}
        />
      ) : (
        <ConnectionOnlyEditor isDisabled value={outputProvidingValue} />
      )}
    </>
  );
}

const useStyles = makeStylesHook(({ palette, spacing }) => ({
  parameterContainer: {
    marginTop: '12px',
  },
  paramGroupInputs: {
    borderLeft: `2px solid ${palette.info.dark}`,
    paddingLeft: '8px',
  },
  description: {
    display: 'block',
    paddingBottom: spacing(2),
    color: Colors.TEXT_SECONDARY,
  },
  parameterGroupDescription: {
    backgroundColor: Colors.BLUE_5,
    color: palette.text.primary,

    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 400,

    borderRadius: '4px',
    padding: spacing(2, 3),
    marginTop: spacing(2),
  },
}));
