import React from 'react';

import Stack from '@mui/material/Stack';

import { EntrySelector } from 'client/app/apps/protocols/EditProtocol/DefineProtocol/InstancePanel/EntrySelector/EntrySelector';
import {
  DECKSELECTION,
  DEVICESELECTIONS,
  INPUTPLATES,
  TIPTYPES,
} from 'client/app/lib/workflow/workflowConfigProperties';
import { ConfiguredDevice } from 'common/types/bundle';
import {
  isDeviceThatCanPerformLiquidHandling,
  isLiquidHandlerDevice,
} from 'common/types/bundleConfigUtils';
import { ParameterEditorConfigurationSpec } from 'common/types/commonConfiguration';
import { ConfiguredDeviceTail } from 'common/types/schema';

type Props = {
  value: ConfiguredDevice[];
  enabledTails?: ConfiguredDeviceTail[];
  onToggle: (
    checked: boolean,
    editor: ParameterEditorConfigurationSpec,
    value: any,
    tail: ConfiguredDeviceTail,
  ) => void;
  isDisabled: boolean;
};

export default function DevicesEntrySelector({
  value: devices,
  enabledTails = [],
  onToggle,
  isDisabled,
}: Props) {
  const mainDevice = devices.find(isDeviceThatCanPerformLiquidHandling);
  const isComplexLiquidHandler = mainDevice && isLiquidHandlerDevice(mainDevice);
  const enabledDisplayKeys = mapEnabledTailsToDisplayKeys(enabledTails);
  return (
    <Stack spacing={4}>
      {/*
       * we display the DEVICESELECTIONS editor so the user can see the actively selected
       * device. However, we do not allow this option to be selected, since we don't
       * want to allow users to be able to select devices but never configure them e.g.
       * with deck selection
       */}
      <EntrySelector
        entries={{ [DEVICESELECTIONS.configuration!.displayName]: devices }}
        valueEditor={DEVICESELECTIONS.configuration!.editor}
        isDisabled
        showKeys={false}
      />
      {isComplexLiquidHandler && (
        <>
          <EntrySelector
            enabledKeys={enabledDisplayKeys}
            entries={{ [DECKSELECTION.configuration!.displayName]: mainDevice }}
            valueEditor={DECKSELECTION.configuration!.editor}
            onToggle={(checked, editor, value) =>
              onToggle(checked, editor, value, [mainDevice.id])
            }
            showKeys
            isDisabled={isDisabled}
          />
          <EntrySelector
            enabledKeys={enabledDisplayKeys}
            entries={{
              [TIPTYPES.configuration!.displayName]: mainDevice.tipTypes,
            }}
            valueEditor={TIPTYPES.configuration!.editor}
            onToggle={(checked, editor, value) =>
              onToggle(checked, editor, value, [mainDevice.id, TIPTYPES.name])
            }
            showKeys
            isDisabled={isDisabled}
          />
        </>
      )}
      {mainDevice && (
        <EntrySelector
          enabledKeys={enabledDisplayKeys}
          entries={{
            [INPUTPLATES.configuration!.displayName]: mainDevice.inputPlateTypes,
          }}
          valueEditor={INPUTPLATES.configuration!.editor}
          onToggle={(checked, editor, value) =>
            onToggle(checked, editor, value, [mainDevice.id, INPUTPLATES.name])
          }
          showKeys
          isDisabled={isDisabled}
        />
      )}
    </Stack>
  );
}

function mapEnabledTailsToDisplayKeys(enabledTails: ConfiguredDeviceTail[]) {
  return enabledTails.map(tail => {
    if (tail.includes(INPUTPLATES.name)) {
      return INPUTPLATES.configuration!.displayName;
    }
    if (tail.includes(TIPTYPES.name)) {
      return TIPTYPES.configuration!.displayName;
    }
    if (tail.length) {
      return DECKSELECTION.configuration!.displayName;
    }
    return '';
  });
}
