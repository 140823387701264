import {
  InputStepState,
  StepState,
} from 'client/app/apps/protocols/context/StepsProvider/stepState';
import {
  ConfiguredDeviceTail,
  getElementExtraKeys,
  getElementId,
  getElementParameterName,
  getStageConfiguredDeviceTail,
  getStageId,
} from 'common/types/schema';

/**
 * StepParamConfig describes all protocol inputs, outputs and stage devices that
 * have been selected and their renaming of parameters
 */
export type StepParamConfigById = {
  [stepId: string]: StepParamConfig;
};

type StepParamConfig<DisplayName = string> = {
  name: DisplayName;
  inputs: StepParamConfigByElement;
  outputs: StepParamConfigByElement;
  devices: StepParamConfigByStage;
};

export type StepParamConfigByElement<DisplayName = string> = {
  [elementId: string]: {
    [paramName: string]: {
      noKeyDisplayName: DisplayName;
      [keyName: string]: DisplayName;
    };
  };
};

type StepParamConfigByStage<DisplayName = string> = {
  [stageId: string]: Map<ConfiguredDeviceTail | undefined, DisplayName>;
};

export function newStepParamConfig(states: StepState[]) {
  const stepEntries = states.map(state => {
    const entry: StepParamConfig = {
      name: state.displayName,
      inputs: newStepParamConfigByElement(state.inputs),
      outputs: newStepParamConfigByElement(state.outputs),
      devices: newStepParamConfigByStage(state.inputs),
    };
    return [state.id, entry];
  });
  return Object.fromEntries(stepEntries);
}

function newStepParamConfigByElement(
  entries: Pick<InputStepState, 'path' | 'displayName' | 'linked'>[],
) {
  const result: StepParamConfigByElement = {};
  for (const { path: mainPath, displayName, linked = [] } of entries) {
    const allPaths = [mainPath, ...linked.map(v => v.path)];
    for (const path of allPaths) {
      const elementId = getElementId(path);
      const paramName = getElementParameterName(path);
      if (!elementId || !paramName) {
        continue; // this isn't a element path
      }
      const extraKeys = getElementExtraKeys(path) || [];
      if (extraKeys.length > 1) {
        throw new Error(
          'unsupported: cannot index more than one level deep into an element parameter',
        );
      }

      const prevEntries = result[elementId]?.[paramName];
      const key = extraKeys[0] ?? 'noKeyDisplayName';

      result[elementId] = {
        ...result[elementId],
        [paramName]: {
          ...prevEntries,
          [key]: displayName,
        },
      };
    }
  }
  return result;
}

function newStepParamConfigByStage(
  entries: Pick<InputStepState, 'path' | 'displayName' | 'linked'>[],
) {
  const result: StepParamConfigByStage = {};
  for (const { path: mainPath, displayName, linked = [] } of entries) {
    const allPaths = [mainPath, ...linked.map(v => v.path)];
    for (const path of allPaths) {
      const stageId = getStageId(path);
      if (!stageId) {
        continue; // this isn't a stage device path
      }
      const extraKeys = getStageConfiguredDeviceTail(path);
      const entries = result?.[stageId] ?? new Map();
      entries.set(extraKeys, displayName);

      result[stageId] = entries;
    }
  }
  return result;
}
