import React from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { ElementError } from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import { MessagePreview, sxSmallerHeadings } from 'common/ui/components/MessagePreview';

type Severity = 'error' | 'warning';

type Props = {
  errors: ElementError[];
  severity: Severity;
  infoChildren?: React.ReactNode;
};

export function OutputStepErrors({ infoChildren, errors, severity }: Props) {
  if (errors.length === 0) {
    return null;
  }
  return (
    <ErrorListContainer>
      {infoChildren ? (
        <ErrorListItem key={-1} severity={severity}>
          <ErrorListHeader severity={severity}>{infoChildren}</ErrorListHeader>
        </ErrorListItem>
      ) : null}
      {errors.map((error, index) => (
        <ErrorListItem key={index} severity={severity}>
          <ErrorListHeader severity={severity}>
            Protocol {severity === 'error' ? 'Error' : 'Warning'}
          </ErrorListHeader>
          <Stack gap={1}>
            <Typography variant="subtitle2">Summary</Typography>
            <MessagePreview
              messageType={error.messageType}
              message={error.message}
              sx={sxSmallerHeadings}
            />
          </Stack>
          {error.details && (
            <Stack gap={1} paddingTop={3}>
              <Typography variant="subtitle2">Details</Typography>
              <MessagePreview
                messageType={error.messageType}
                message={error.details}
                sx={sxSmallerHeadings}
              />
            </Stack>
          )}
        </ErrorListItem>
      ))}
    </ErrorListContainer>
  );
}

function ErrorListHeader(props: { severity: Severity; children: React.ReactNode }) {
  const { severity, children } = props;
  return (
    <Stack direction="row" alignItems="center" gap={3}>
      <StyledInfoOutlinedIcon severity={severity} />
      <ErrorHeader variant="subtitle2" severity={severity}>
        {children}
      </ErrorHeader>
    </Stack>
  );
}

const ErrorListItem = styled(ListItem, {
  shouldForwardProp: prop => prop !== 'severity',
})<{ severity: Severity }>(({ severity, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(3),
  padding: theme.spacing(4, 5),
  border: `1px ${severity === 'error' ? Colors.ERROR_MAIN : Colors.WARNING_MAIN} solid`,
  borderRadius: '4px',
  overflow: 'scroll',
}));

const ErrorListContainer = styled(List)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon, {
  shouldForwardProp: prop => prop !== 'severity',
})<{ severity: Severity }>(({ severity, theme }) => ({
  color: severity === 'error' ? theme.palette.error.main : theme.palette.warning.dark,
}));

const ErrorHeader = styled(Typography, {
  shouldForwardProp: prop => prop !== 'severity',
})<{ severity: Severity }>(({ severity, theme }) => ({
  color: severity === 'error' ? theme.palette.error.main : theme.palette.warning.dark,
}));
