import { useEffect, useMemo, useRef, useState } from 'react';

import useEntityConflictErrorDialog from 'client/app/hooks/useEntityConflictErrorDialog';
import { ErrorCodes } from 'common/types/errorCodes';
import { ProtocolStep } from 'common/types/Protocol';
import { useStateWithURLParams } from 'common/ui/hooks/useStateWithURLParams';

const PROTOCOL_SELECTED_STEP_ID_PARAM = 'selected_step';
const PROTOCOL_EXPANDED_LIST_PARAM = 'expand_input_list';

/**
 * Handles storing state in URL for parameters relating to the Protocols UI.
 *
 * @returns State setters for each URL param
 */
export function useProtocolsParamState(steps: ProtocolStep[]): {
  selectedStep?: ProtocolStep;
  handleSelectStep: (stepId: string) => void;
  expandInputList?: boolean;
  handleSetExpandInputList: () => void;
} {
  const [selectedStepId, setSelectedStepId] = useStateWithURLParams({
    paramName: PROTOCOL_SELECTED_STEP_ID_PARAM,
    paramType: 'string',
  });

  const [expandInputList, setExpandInputList] = useStateWithURLParams({
    paramName: PROTOCOL_EXPANDED_LIST_PARAM,
    paramType: 'boolean',
  });

  const handleSelectStep = (stepId: string) => {
    setSelectedStepId(stepId);
  };

  const handleSetExpandInputList = () => {
    setExpandInputList(!expandInputList);
  };

  useEffect(() => {
    if (steps.length > 1) {
      setExpandInputList(true);
    }
  }, [setExpandInputList, steps.length]);

  const prevIndex = useRef(0);
  const selectedIndex = useMemo(() => {
    const index = steps.findIndex(step => step.id === selectedStepId);
    if (index >= 0) {
      return index;
    }
    // the selected step was deleted, fallback to the one before if it exists
    const fallbackIndex = Math.max(prevIndex.current - 1, 0);
    // there are no steps for a newly created protocol
    setSelectedStepId(steps.length ? steps[fallbackIndex].id : '');
    return fallbackIndex;
  }, [selectedStepId, setSelectedStepId, steps]);
  prevIndex.current = selectedIndex;

  return {
    selectedStep: steps[selectedIndex],
    handleSelectStep,
    expandInputList,
    handleSetExpandInputList,
  };
}

/**
 * Manages asynchronous entity updates such that:
 *  1. multiple `handleUpdate` calls are not issued at once
 *  2. `handleUpdate` is retriggered if `setUpdateRequired(true)` or
 *     `editVersion` / `handleUpdate` are updated
 *
 * This hook is useful to minimise editVersion conflicts on updates
 */
export function useUpdateEntity(args: {
  entityType: string;
  editVersion: number;
  conflictCode: ErrorCodes;
  handleUpdate: (editVersion: number) => Promise<void>;
}) {
  const { entityType, editVersion, conflictCode, handleUpdate } = args;

  // must use isUpdating rather than e.g. the apollo loading state of an entity
  // update. The latter will be true if useEffect is triggered twice at once
  // because `handleUpdate` is called async
  const isUpdating = useRef(false);
  const [updateRequired, setUpdateRequired] = useState(false);
  const { handleCheckConflictError, conflictDialog } = useEntityConflictErrorDialog();

  useEffect(() => {
    if (isUpdating.current || !updateRequired || conflictDialog) return;
    isUpdating.current = true;
    // while updating, the caller could set isUpdateRequired true, which will
    // cause the effect to be triggered _only_ once the current update is
    // complete _and_ if the current handleUpdate call increases the editVersion
    setUpdateRequired(false);

    (async () => {
      try {
        await handleUpdate(editVersion);
      } catch (error) {
        await handleCheckConflictError(error, entityType, conflictCode);
      } finally {
        isUpdating.current = false;
      }
    })();
  }, [
    conflictDialog,
    entityType,
    conflictCode,
    handleCheckConflictError,
    handleUpdate,
    editVersion,
    updateRequired,
  ]);

  return {
    setUpdateRequired,
    conflictDialog,
  };
}
