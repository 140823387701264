import React, { createElement, FunctionComponent } from 'react';

import KebabMenuIcon from '@mui/icons-material/MoreVert';
import Fade from '@mui/material/Fade';
import Menu, { MenuProps } from '@mui/material/Menu';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import { IntercomTourIDs } from 'common/lib/intercom';
import Colors from 'common/ui/Colors';
import IconButton from 'common/ui/components/IconButton';

type CloseMenu = { closeMenu: () => void };

type Props<TProps extends CloseMenu> = {
  contents: FunctionComponent<TProps>;
  props: Omit<TProps, 'closeMenu'>;
};

export default function SimulationHeaderDropdown<TProps extends CloseMenu>({
  contents,
  props,
}: Props<TProps>) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Stack direction="row" alignItems="center">
      <MenuIconButton
        size="small"
        onClick={handleOpenMenu}
        icon={<KebabMenuIcon />}
        data-intercom-target={`${IntercomTourIDs.SIMULATION_OPTIONS}-simulation-menu`}
      />
      <DropdownMenu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={closeMenu}>
        {createElement(contents, { closeMenu, ...props } as TProps)}
      </DropdownMenu>
    </Stack>
  );
}

function DropdownMenu(props: MenuProps) {
  return (
    <Menu
      TransitionComponent={Fade}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  );
}

const MenuIconButton = styled(IconButton)({
  color: Colors.GREY_80,
});
