import { ProtocolInstancesQuery } from 'client/app/gql';

export function useCanDeleteProtocolInstance(
  instance: ProtocolInstancesQuery['protocolInstances']['items'][number],
) {
  const hasExecution = !!instance.executionId;
  const canDelete = instance.isOwnedByUser && !hasExecution;
  const message = canDelete
    ? 'Delete Run'
    : !instance.isOwnedByUser
    ? 'Only the creator of the run can delete it'
    : 'This run has been executed and cannot be deleted';

  return [canDelete, message];
}
