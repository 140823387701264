import React, { useCallback, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import DOMPurify from 'dompurify';

import Panel from 'client/app/apps/workflow-builder/panels/Panel';
import DeviceAdvancedOptionsList from 'client/app/apps/workflow-builder/panels/workflow-settings/DeviceAdvancedOptions';
import { ExecutionModeHeader } from 'client/app/apps/workflow-builder/panels/workflow-settings/ExecutionModeHeader';
import LHPolicyUploadEditor from 'client/app/apps/workflow-builder/panels/workflow-settings/LHPolicyUploadEditor';
import SettingsPanelContainer from 'client/app/apps/workflow-builder/panels/workflow-settings/SettingsPanelContainer';
import { useWorkflowSettingsState } from 'client/app/apps/workflow-builder/panels/workflow-settings/workflowSettingsState';
import DeviceConfigurator from 'client/app/components/Parameters/DeviceConfigurator/DeviceConfigurator';
import { ScreenRegistry } from 'client/app/registry';
import { useWorkflowBuilderDispatch } from 'client/app/state/WorkflowBuilderStateContext';
import {
  ConfiguredDevice,
  ParameterValue,
  ParameterValueDict,
} from 'common/types/bundle';
import { DirectUploadSingleValueLegacy } from 'common/types/fileParameter';
import TextField from 'common/ui/filaments/TextField';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

type Props = {
  className: string;
  onClose: () => void;
};

const SettingsPanel = React.memo(function SettingsPanel({ className, onClose }: Props) {
  const dispatch = useWorkflowBuilderDispatch();
  const {
    stages,
    selectedStage,
    selectedConfiguredDevices,
    isReadonly,
    showCustomLHPolicies,
    globalWorkflowConfig,
  } = useWorkflowSettingsState();

  const [stageNameValidationState, setStageNameValidationState] = useState(
    selectedStage?.name ?? '',
  );
  useEffect(() => {
    setStageNameValidationState(selectedStage?.name ?? '');
  }, [selectedStage?.name]);

  const isStageNameValid = (name: string) => {
    // Disallows entering empty strings
    return name.trim() !== '';
  };

  const onStageNameChange = useTextFieldChange((newName: string) => {
    const sanitized = DOMPurify.sanitize(newName.trimStart());
    setStageNameValidationState(sanitized);
    if (selectedStage && isStageNameValid(sanitized)) {
      dispatch({
        type: 'updateStageName',
        payload: { stageId: selectedStage.id, newName: sanitized },
      });
    }
  });

  const handleSelectedDevicesChange = useCallback(
    (newConfiguredDevices: ConfiguredDevice[]) => {
      dispatch({
        type: 'setSelectedStageDevices',
        payload: newConfiguredDevices,
      });
    },
    [dispatch],
  );

  const onConfigParamChange = useCallback(
    (paramChanged: string, newValues: ParameterValue) => {
      logEvent('edit-config-parameter', ScreenRegistry.WORKFLOW, paramChanged);
      const updated = { ...globalWorkflowConfig, [paramChanged]: newValues };
      dispatch({ type: 'setGlobalConfig', payload: updated });
    },
    [dispatch, globalWorkflowConfig],
  );

  const onUploadedPolicyFileChange = useCallback(
    (policyFile: DirectUploadSingleValueLegacy | undefined) => {
      if (!policyFile) {
        // File "changed" but no file.
        // This happens when the user removes the policy file
        // using the little 'x' icon.
        // We take existing config without liguidHandlingPolicyXlsx{File, FileName}
        const {
          liquidHandlingPolicyXlsxJmpFile: _toRemove,
          liquidHandlingPolicyXlsxJmpFileName: _toRemoveToo,
          ...globalMixerMinusLiquidPolicyFile
        } = globalWorkflowConfig;
        dispatch({
          type: 'setGlobalConfig',
          payload: globalMixerMinusLiquidPolicyFile,
        });
        return;
      }

      const pf = policyFile;
      if (!pf.bytes || !pf.name) {
        throw new Error('Unexpected value for policy upload');
      }
      const newGlobalMixerConfig = {
        ...globalWorkflowConfig,
        liquidHandlingPolicyXlsxJmpFile: pf.bytes.bytes,
        liquidHandlingPolicyXlsxJmpFileName: pf.name,
      };
      dispatch({
        type: 'setGlobalConfig',
        payload: newGlobalMixerConfig,
      });
    },
    [dispatch, globalWorkflowConfig],
  );

  return (
    <Panel
      className={className}
      title="Workflow Settings"
      onClose={onClose}
      panelContent="WorkflowSettings"
    >
      <Box p={3}>
        {/** We need to support legacy workflows with 0 stages for readonly display, and in this case, we won't show stage name */}
        {stages.length > 0 && (
          <StageNameContainer>
            <TextField
              variant="standard"
              label="Stage name"
              disabled={isReadonly}
              value={stageNameValidationState}
              fullWidth
              onChange={onStageNameChange}
              error={!isStageNameValid(stageNameValidationState)}
              helperText={
                isStageNameValid(stageNameValidationState)
                  ? ' '
                  : 'Stage name must be defined'
              }
            />
          </StageNameContainer>
        )}
        <SettingsPanelContainer title="Execution">
          <DeviceConfigurator
            value={selectedConfiguredDevices}
            onChange={handleSelectedDevicesChange}
            numStages={stages.length}
            isDisabled={isReadonly}
            renderHeader={header => <ExecutionModeHeader header={header} />}
          />
        </SettingsPanelContainer>
        {showCustomLHPolicies && (
          <SettingsPanelContainer
            title="Custom Liquid Policies"
            helpContent="If the liquid policies in Synthace do not transfer a liquid in your workflow the way that you want, create a custom liquid policy instead."
            additionalHelpLinkUrl="https://intercom.help/antha/en/articles/5451980-custom-liquid-policies"
          >
            <LHPolicyUploadEditor
              isDisabled={isReadonly}
              fileName={globalWorkflowConfig?.liquidHandlingPolicyXlsxJmpFileName}
              file={globalWorkflowConfig?.liquidHandlingPolicyXlsxJmpFile}
              onChange={onUploadedPolicyFileChange}
            />
          </SettingsPanelContainer>
        )}
        <DeviceAdvancedOptionsList
          parameterValueDict={globalWorkflowConfig as ParameterValueDict}
          onChange={onConfigParamChange}
          isDisabled={isReadonly}
        />
      </Box>
    </Panel>
  );
});

export default SettingsPanel;

const StageNameContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
}));
