import React from 'react';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { ProtocolInstancesListItem } from 'client/app/apps/protocols/RecentProtocolInstanceList/ProtocolInstanceListItem';
import { ProtocolInstancesDialog } from 'client/app/apps/protocols/RecentProtocolInstanceList/ProtocolInstancesDialog';
import { RecentProtocolInstancesListFooter } from 'client/app/apps/protocols/RecentProtocolInstanceList/RecentProtocolInstancesListFooter';
import { RecentProtocolInstancesListHeader } from 'client/app/apps/protocols/RecentProtocolInstanceList/RecentProtocolInstancesListHeader';
import { useDeleteProtocolInstanceWithConfirmation } from 'client/app/apps/protocols/RecentProtocolInstanceList/useDeleteInstance';
import { ProtocolInstancesQuery } from 'client/app/gql';
import { protocolInstanceRoutes } from 'client/app/lib/nav/actions';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import useDialog from 'common/ui/hooks/useDialog';

type Props = {
  data: ProtocolInstancesQuery;
  protocolId: ProtocolId;
};

const ProtocolInstancesList = ({ data, protocolId }: Props) => {
  const [protocolInstancesDialog, openProtocolInstancesDialog] = useDialog(
    ProtocolInstancesDialog,
  );

  const instances = data.protocolInstances.items || [];
  const latestVersion = Math.max(
    ...instances.map(i => i.protocol.version),
  ) as ProtocolVersion;

  const instancesToShow = instances.slice(0, 5);
  const showVersion = instancesToShow.some(i => i.protocol.version !== latestVersion);

  const { navigate } = useNavigation();
  const handleOnClick = (id: string) => {
    navigate(protocolInstanceRoutes.editProtocolInstance, {
      id,
    });
  };

  const handleOpenProtocolInstancesDialog = async (protocolId: ProtocolId) => {
    await openProtocolInstancesDialog({ protocolId });
  };

  const { confirmationDialog, handleDeleteProtocolInstanceWithConfirmation } =
    useDeleteProtocolInstanceWithConfirmation();

  return (
    <Grid showVersion={showVersion}>
      <RecentProtocolInstancesListHeader showVersion={showVersion} />
      {instancesToShow.map((instance, index) => (
        <ProtocolInstancesListItem
          key={instance.id}
          instance={instance}
          showVersion={showVersion}
          onClick={() => handleOnClick(instance.id)}
          onDelete={() => handleDeleteProtocolInstanceWithConfirmation(instance.id)}
          gridRow={index + 2}
        />
      ))}
      {instances.length > instancesToShow.length && (
        <RecentProtocolInstancesListFooter
          onClick={() => handleOpenProtocolInstancesDialog(protocolId)}
        />
      )}
      {protocolInstancesDialog}
      {confirmationDialog}
    </Grid>
  );
};

const ENTITY_CARD_COLUMN_TEMPLATE_NO_VERSION =
  '[trailer] 22px [name] minmax(30%, 1fr) [author] auto [date] auto [status] auto [action] 64px [end]';
const ENTITY_CARD_COLUMN_TEMPLATE_WITH_VERSION =
  '[trailer] 22px [name] minmax(30%, 1fr) [version] 46px [author] auto [date] auto [status] auto [action] 64px [end]';

export const Grid = styled(Box, { shouldForwardProp: p => p !== 'showVersion' })<{
  showVersion?: boolean;
}>(({ showVersion, theme }) => ({
  display: 'grid',
  // if we're not showing version, we have to remove it from the template or we'll get extra gaps between columns
  gridTemplateColumns: showVersion
    ? ENTITY_CARD_COLUMN_TEMPLATE_WITH_VERSION
    : ENTITY_CARD_COLUMN_TEMPLATE_NO_VERSION,
  alignItems: 'center',
  columnGap: theme.spacing(6),
}));

export { ProtocolInstancesList };
