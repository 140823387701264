import React from 'react';

import Stack from '@mui/material/Stack';

import ExecuteSimulationButton from 'client/app/apps/simulation-details/ExecuteButton/ExecuteSimulationButton';
import DefaultMenuContent from 'client/app/apps/simulation-details/header/DefaultMenuContent';
import {
  FirstDivider,
  SecondDivider,
} from 'client/app/apps/simulation-details/header/Dividers';
import SimulationHeaderDropdown from 'client/app/apps/simulation-details/header/SimulationHeaderDropdown';
import { SimulationQuery, WorkflowEditModeEnum } from 'client/app/gql';
import useIsDOEAllowedForSimulation from 'client/app/hooks/useIsDOEAllowedForSimulation';
import getWorkflowPropsBySource from 'client/app/lib/workflow/getWorkflowPropsBySource';
import { IntercomTourIDs } from 'common/lib/intercom';
import RouteButton from 'common/ui/components/navigation/RouteButton';

type Props = {
  simulation: SimulationQuery['simulation'];
  setHeaderLoading: (v: boolean) => void;
};

// Adornment for a normal simulation coming from a workflow or template
const DefaultAdornment = ({ simulation, setHeaderLoading }: Props) => {
  const { route: workflowRoute } = getWorkflowPropsBySource(simulation.workflow.source);
  const isEditableWorkflow =
    simulation.editableWorkflow?.version === simulation.workflow.version &&
    simulation.editableWorkflow?.editMode ===
      WorkflowEditModeEnum.ENABLED_LATEST_OWNED_BY_ME;

  const doeSimulationType = useIsDOEAllowedForSimulation(
    simulation.workflow.workflow,
    simulation.source,
  );
  const hasTasksToBeExecuted = simulation.tasks ? simulation.tasks.length > 0 : false;

  const showCreateDOEButton =
    simulation.status === 'COMPLETED' && doeSimulationType === 'legacy';

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-end">
      <div data-intercom-target={`${IntercomTourIDs.SIMULATION_OPTIONS}-view-snapshot`}>
        <RouteButton
          data-heap-tracking="return-to-workflow-button"
          label={isEditableWorkflow ? 'Continue editing' : 'View snapshot'}
          route={workflowRoute}
          routeParam={{
            workflowId: isEditableWorkflow
              ? simulation.editableWorkflow!.id
              : simulation.workflow.id,
          }}
        />
      </div>
      <FirstDivider orientation="vertical" flexItem />
      <SimulationHeaderDropdown
        contents={DefaultMenuContent}
        props={{ simulation, onLoadingChange: setHeaderLoading, showCreateDOEButton }}
      />
      <SecondDivider orientation="vertical" flexItem />

      {hasTasksToBeExecuted && (
        <ExecuteSimulationButton
          simulationId={simulation.id}
          hasStartedExecution={simulation.execution !== null}
        />
      )}
    </Stack>
  );
};

export default DefaultAdornment;
