import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useFetchGraphQLElementSet } from 'client/app/api/ElementsApi';
import { GraphQLWorkflow } from 'client/app/api/gql/utils';
import { useUpdateWorkflow } from 'client/app/apps/protocols/api/ProtocolsAPI';
import { ElementParameterInfo } from 'client/app/apps/protocols/lib/types';
import { useUpdateEntity } from 'client/app/apps/protocols/lib/utils';
import { useDeserialiseWorkflowResponse } from 'client/app/apps/workflow-builder/lib/workflowUtils';
import { getOutputVisualisationTypeFromParameterType } from 'client/app/components/ElementPlumber/ElementOutputs/helpers';
import { ElementSetQuery } from 'client/app/gql';
import ParameterStateContextProvider from 'client/app/lib/rules/elementConfiguration/ParameterStateContext';
import { CONFIGUREDDEVICESELECTION } from 'client/app/lib/workflow/workflowConfigProperties';
import AutocompleteParameterValuesContextProvider from 'client/app/state/AutocompleteParameterValuesContext';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import {
  ConfiguredDevice,
  CoreError,
  ElementContextMap,
  ElementErorrSeverity,
  ElementInstance,
  ElementInstanceStatus,
  Parameter,
  WorkflowConfig,
} from 'common/types/bundle';
import { ErrorCodes } from 'common/types/errorCodes';
import { getElementId, getElementParameterName, Schema } from 'common/types/schema';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';

const emptySchema = () => {
  return { inputs: [], outputs: [] };
};

/** ElementParamConfig describes workflow element set renaming of elements / parameters */
export type ElementParamConfig = {
  element: { typeName: string; displayName: string };
  parameters: { [paramName: string]: string };
};

type WorkflowContextType = {
  id: WorkflowId;
  workflowConfig: WorkflowConfig;
  workflowStageParametersInfo: {
    stage: { name: string; id: string };
    parameter: Parameter;
    value: ConfiguredDevice[];
  }[];
  elementsConfig: { [elementId: string]: ElementParamConfig };
  elementsContext: ElementContextMap;
  elementsRunError?: CoreError;
  loading: boolean;
  conflictDialog: JSX.Element | null;
  /** update workflow state parameter output to display */
  updateOutput: (schemaInputId: string) => void;
  /** triggers updateWorkflow mutation with provided params */
  handleUpdateSchema: (update: Schema) => void;
  getElementInstance: (elementInstanceId: string) => ElementInstance | undefined;
  getElementParameterValue: (
    elementInstance: ElementInstance,
    param: Parameter,
  ) => Pick<ElementParameterInfo, 'value'>;
  getElementRelatedConfigDeviceIds: (
    elementInstanceId: string,
  ) => Pick<ElementParameterInfo, 'relatedConfiguredDeviceIds'>;
  getStageRelatedConfigDeviceIds: (stageId: string) => string[];
};

export const WorkflowContext = createContext<WorkflowContextType | undefined>(undefined);

export const useWorkflowContext = () => {
  const context = useContext(WorkflowContext);
  if (context === undefined) {
    throw new Error('useWorkflowContext must be used within a WorkflowProvider');
  }
  return context;
};

type WorkflowProviderProps = {
  workflow: GraphQLWorkflow;
  elementContext: ElementContext;
} & PropsWithChildren;

type ElementContext = {
  elementContextMap: ElementContextMap | null;
  elementContextError: CoreErrorBlob | null;
};

export const WorkflowProvider: FC<WorkflowProviderProps> = ({
  elementContext,
  workflow,
  children,
}) => {
  const dispatch = useWorkflowBuilderDispatch();
  const snackbar = useSnackbarManager();
  const fetchGraphQLElementSet = useFetchGraphQLElementSet();
  const [elementSet, setElementSet] = useState<ElementSetQuery['elementSet']>();
  const { handleUpdateWorkflow, loading: updateLoading } = useUpdateWorkflow();
  const [schema, setSchema] = useState(workflow.workflow.Schema || emptySchema());
  const loading = elementSet === undefined || updateLoading;

  // elementSet should only be fetched once since since we do not allow
  // uploading workflows or changing branches in protocols. Moreover the query
  // is normally quite expensive
  useEffect(() => {
    (async () => {
      try {
        setElementSet(await fetchGraphQLElementSet(workflow.id));
      } catch (err) {
        snackbar.showError(err);
      }
    })();
  }, [dispatch, fetchGraphQLElementSet, snackbar, workflow.id]);

  // on the other hand, workflow state may change depending on how the provider
  // is used and is cheap to update
  const deserialiseWorkflowResponse = useDeserialiseWorkflowResponse();
  useEffect(() => {
    if (loading) return;
    const { workflowState, errors } = deserialiseWorkflowResponse(workflow, elementSet);
    if (errors.length > 0) {
      snackbar.showError(errors.join(' '));
    }
    dispatch({
      type: 'resetToWorkflow',
      payload: workflowState,
      // these keys in the state are required to show the output preview, if we clear
      // them then the output will not be shown
      preserveKeys: ['additionalPanel', 'outputPreviewProps'],
    });
  }, [deserialiseWorkflowResponse, dispatch, elementSet, loading, snackbar, workflow]);

  useEffect(() => {
    if (elementContext.elementContextMap) {
      dispatch({
        type: 'updateElementsWithContexts',
        payload: elementContext.elementContextMap,
      });
    }
    if (elementContext.elementContextError) {
      dispatch({
        type: 'setElementContextError',
        payload: elementContext.elementContextError,
      });
    }
  }, [dispatch, elementContext]);

  const { conflictDialog, setUpdateRequired } = useUpdateEntity({
    entityType: 'workflow',
    editVersion: workflow.version,
    conflictCode: ErrorCodes.WORKFLOW_EDIT_CONFLICT,
    handleUpdate: useCallback(
      async (editVersion: number) => {
        const params = { ...workflow.workflow, Schema: schema };
        await handleUpdateWorkflow(workflow.id, editVersion, params);
      },
      [handleUpdateWorkflow, schema, workflow.id, workflow.workflow],
    ),
  });

  const {
    stages,
    config: workflowConfig,
    parameters: allElementParametersValues,
    elementInstances,
    outputPreviewProps,
    InstancesConnections: connections,
  } = useWorkflowBuilderSelector(state => state);

  const schemaOutputsById = useMemo(
    () => Object.fromEntries(schema.outputs?.map(output => [output.id, output]) || []),
    [schema.outputs],
  );

  const elementsById = useMemo(
    () => Object.fromEntries(elementInstances.map(instance => [instance.Id, instance])),
    [elementInstances],
  );

  const elementsConfig: { [elementId: string]: ElementParamConfig } = useMemo(() => {
    const elementEntries = elementInstances.map(instance => {
      const { TypeName: typeName, Id: id, element } = instance;
      const { configuration } = element;
      const { elementDisplayName = typeName, parameters = {} } = configuration || {};
      const paramEntries = Object.entries(parameters);
      const paramRenames = Object.fromEntries(
        paramEntries.map(([name, { displayName }]) => [name, displayName]),
      );
      const paramConfig = {
        element: { typeName, displayName: elementDisplayName },
        parameters: paramRenames,
      };
      return [id, paramConfig];
    });
    return Object.fromEntries(elementEntries);
  }, [elementInstances]);

  const workflowStageParametersInfo = useMemo(() => {
    return stages.map((stage, index) => {
      const parameter: Parameter = {
        ...CONFIGUREDDEVICESELECTION,
        configuration: {
          ...CONFIGUREDDEVICESELECTION.configuration!,
          displayName: `Stage ${index + 1}: ${stage.name}`,
        },
      };
      const value = (workflowConfig.configuredDevices ?? []).filter(v =>
        stage.configuredDevices.includes(v.id),
      );
      return { stage, parameter, value };
    });
  }, [stages, workflowConfig.configuredDevices]);

  const getElementParameterValue = useCallback(
    (instance: ElementInstance, parameter: Parameter) => {
      const value = allElementParametersValues[instance.name]?.[parameter.name];
      return { value };
    },
    [allElementParametersValues],
  );

  const getElementRelatedConfigDeviceIds = useCallback(
    (instanceId: string) => {
      const stage = stages.find(stage => stage.elementIds.includes(instanceId));
      const relatedConfiguredDeviceIds = (workflowConfig.configuredDevices ?? [])
        .filter(({ id }) => stage?.configuredDevices.includes(id))
        .map(v => v.id);
      return { relatedConfiguredDeviceIds };
    },
    [stages, workflowConfig.configuredDevices],
  );

  const getStageRelatedConfigDeviceIds = useCallback(
    (stageId: string) => {
      const stage = stages.find(v => v.id === stageId);
      return stage?.configuredDevices ?? [];
    },
    [stages],
  );

  const getElementInstance = useCallback(
    (id: string) => elementsById[id],
    [elementsById],
  );

  const updateOutput = useCallback(
    (schemaOutputId: string) => {
      const schemaOutput = schemaOutputsById[schemaOutputId] || { path: [] };
      const { path, typeName } = schemaOutput;
      const paramName = getElementParameterName(path);
      const elementId = getElementId(path);
      if (elementId && paramName) {
        dispatch({
          type: 'openOutputPreview',
          payload: {
            selectedElementId: elementId,
            selectedOutputParameterName: paramName,
            outputType: getOutputVisualisationTypeFromParameterType(typeName),
            entityView: outputPreviewProps.entityView,
          },
        });
      }
    },
    [dispatch, outputPreviewProps.entityView, schemaOutputsById],
  );

  const handleUpdateSchema = useCallback(
    (update: Schema) => {
      setSchema(update);
      setUpdateRequired(true);
    },
    [setUpdateRequired],
  );

  // to simplify our error handling treat errors from the core service as just
  // another error but associated to no element
  const elementsContext: ElementContextMap = useMemo(() => {
    const serviceError = elementContext.elementContextError;
    if (serviceError === null) {
      return { ...elementContext.elementContextMap };
    }
    const severity: ElementErorrSeverity = 'error';
    const status: ElementInstanceStatus = 'error';
    return {
      ...elementContext.elementContextMap,
      noElementId: { status, errors: [{ ...serviceError, severity }] },
    };
  }, [elementContext]);

  const state: WorkflowContextType = {
    id: workflow.id,
    workflowConfig,
    workflowStageParametersInfo,
    elementsConfig,
    elementsContext,
    loading,
    conflictDialog,
    updateOutput,
    handleUpdateSchema,
    getElementInstance,
    getElementParameterValue,
    getElementRelatedConfigDeviceIds,
    getStageRelatedConfigDeviceIds,
  };

  return (
    <ParameterStateContextProvider
      parameters={allElementParametersValues}
      elementInstances={elementInstances}
      // must set connections as element configuration rules are dependent on
      // them even if the protocols app is not
      connections={connections}
    >
      <AutocompleteParameterValuesContextProvider
        parameters={allElementParametersValues}
        instances={elementInstances}
      >
        <WorkflowContext.Provider value={state}>{children}</WorkflowContext.Provider>
      </AutocompleteParameterValuesContextProvider>
    </ParameterStateContextProvider>
  );
};
