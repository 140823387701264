import React from 'react';

import { SimulationQuery } from 'client/app/gql';
import useRequestHelpForSimulation from 'client/app/hooks/useRequestHelpForSimulation';
import { ScreenRegistry } from 'client/app/registry';
import MenuItemWithIcon from 'common/ui/components/Menu/MenuItemWithIcon';
import { TalkToUsIcon } from 'common/ui/icons/TalkToUs';

type Props = {
  simulation: SimulationQuery['simulation'];
  closeMenu: () => void;
};

export default function ProtocolMenuContent({ simulation, closeMenu }: Props) {
  const { requestHelpDialog, handleRequestHelp } = useRequestHelpForSimulation(
    ScreenRegistry.SIMULATION_DETAILS,
    simulation.id,
    simulation.name,
  );

  const closeMenuAndRequestHelp = () => {
    closeMenu();
    void handleRequestHelp();
  };

  return (
    <>
      <MenuItemWithIcon
        icon={<TalkToUsIcon />}
        text="Request help"
        onClick={closeMenuAndRequestHelp}
      />
      {requestHelpDialog}
    </>
  );
}
