import React from 'react';

import { useQuery } from '@apollo/client';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Typography from '@mui/material/Typography';

import { QUERY_PROTOCOLS_BY_WORKFLOW } from 'client/app/api/gql/queries';
import { HasPermission } from 'client/app/components/Permissions';
import { TopBar } from 'client/app/components/TopBar';
import { protocolsRoutes } from 'client/app/lib/nav/actions';
import { ScreenRegistry } from 'client/app/registry';
import { WorkflowEditMode } from 'common/types/bundle';
import Button from 'common/ui/components/Button';
import RouteButton from 'common/ui/components/navigation/RouteButton';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import { ProtocolIcon } from 'common/ui/icons';

type Props = {
  workflowId: WorkflowId;
  editMode: WorkflowEditMode;
  authorName: string;
};

export function ProtocolInBuilderWorkflowBar({
  workflowId,
  editMode,
  authorName,
}: Props) {
  const snackbarManager = useSnackbarManager();
  const { data, loading } = useQuery(QUERY_PROTOCOLS_BY_WORKFLOW, {
    variables: { workflowId },
    onError: err => snackbarManager.showError(`Error getting protocol: ${err.message}`),
  });

  return (
    <HasPermission
      permission="update:othersProtocol"
      renderItem={hasPermission => {
        const canOverride =
          hasPermission &&
          editMode === WorkflowEditMode.DISABLED_LATEST_OWNED_BY_SOMEONE_ELSE;
        return (
          <TopBar warning={canOverride}>
            <InfoOutlined color={canOverride ? 'warning' : 'info'} />
            <Typography variant="h6" color="textPrimary">
              {canOverride
                ? getOverrideMessage(authorName)
                : getMessage(editMode, authorName)}
            </Typography>

            {loading || (data?.protocols?.items?.length ?? 0) < 1 ? (
              <Button
                data-heap-tracking="readonlybar-copy-workflow-button"
                onClick={() => {}}
                disabled
                startIcon={<ProtocolIcon />}
                variant="primary"
                color="primary"
              >
                Back to Protocol
              </Button>
            ) : (
              <RouteButton
                data-heap-tracking="readonlybar-go-to-protocol-button"
                route={protocolsRoutes.editProtocol}
                routeParam={{
                  id: data!.protocols.items[0].id,
                  version: data!.protocols.items[0].version,
                }}
                label="Back to Protocol"
                startIcon={<ProtocolIcon />}
                logEventCategory={ScreenRegistry.WORKFLOW}
                color={canOverride ? 'warning' : 'primary'}
                variant="contained"
              />
            )}
          </TopBar>
        );
      }}
    />
  );
}

const getOverrideMessage = (authorName: string) => {
  return (
    <>
      This workflow powers a protocol by {authorName} and would normally be read only
      <br />
      However, you have elevated privileges and can edit the workflow even though you are
      not the owner
    </>
  );
};

const getMessage = (editMode: WorkflowEditMode, authorName: string): string => {
  switch (editMode) {
    case WorkflowEditMode.ENABLED_LATEST_OWNED_BY_ME:
      return 'This workflow powers a Protocol. If you delete an element, its inputs and outputs will be removed from the protocol as well';
    case WorkflowEditMode.DISABLED_LATEST_OWNED_BY_SOMEONE_ELSE:
      return `This read-only workflow powers a Protocol by ${authorName} which you are not permitted to edit`;
    case WorkflowEditMode.DISABLED_SNAPSHOT_OWNED_BY_ME:
      return `This read-only snapshot powers a Protocol`;
    case WorkflowEditMode.DISABLED_SNAPSHOT_OWNED_BY_SOMEONE_ELSE:
      return `This read-only snapshot powers a Protocol by ${authorName}`;
    case WorkflowEditMode.ENABLED_LATEST_EXAMPLE_GALLERY:
      throw new Error('Protocol workflow found in Example Gallery');
  }
};
