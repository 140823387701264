import React, { useCallback, useEffect, useState } from 'react';

import Typography from '@mui/material/Typography';

import { usePlatesByType } from 'client/app/api/PlateTypesApi';
import { useElementInstance } from 'client/app/apps/workflow-builder/lib/useElementInstance';
import ElementParameterHelpIcon from 'client/app/components/Parameters/ElementParameterHelpIcon';
import ResinBufferInput from 'client/app/components/Parameters/FiltrationPlateLayout/components/ResinBufferInput';
import { useFilterPlateEditorState } from 'client/app/components/Parameters/FiltrationPlateLayout/lib/editorState';
import {
  useFiltrationPlateLayoutParameter,
  useReadonly,
} from 'client/app/components/Parameters/FiltrationPlateLayout/lib/parameterUtils';
import PlateSelectionEditor from 'client/app/components/Parameters/PlateType/PlateSelectionEditor';
import { PlateParameterValue } from 'client/app/components/Parameters/PlateType/processPlateParameterValue';
import TextField from 'common/ui/filaments/TextField';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

export default function HeadlineParametersRow() {
  const classes = useStyles();
  const isReadonly = useReadonly();
  const elementInstance = useElementInstance();
  const parameter = useFiltrationPlateLayoutParameter();

  const { plateTypeName, handlePlateSelectionChange } = usePlateSelectionHandler();
  const { plateName, handlePlateNameChange, handlePlateNameBlur } = usePlateNameInput();

  if (!elementInstance || !parameter.name) return null;

  return (
    <main className={classes.container}>
      <section className={classes.parameterInput}>
        <div className={classes.inputLabelSet}>
          <Typography className={classes.inputLabel} variant="subtitle2" noWrap>
            Plate Type
          </Typography>
          <ElementParameterHelpIcon
            elementId={elementInstance.element.id}
            name={parameter.name}
          />
        </div>
        <PlateSelectionEditor
          value={plateTypeName}
          onChange={handlePlateSelectionChange}
          isDisabled={isReadonly}
        />
      </section>
      {plateTypeName && (
        <section className={classes.parameterInput}>
          <div className={classes.inputLabelSet}>
            <Typography className={classes.inputLabel} variant="subtitle2" noWrap>
              Plate Name
            </Typography>
            <ElementParameterHelpIcon
              elementId={elementInstance.element.id}
              name={parameter.name}
            />
          </div>
          <TextField
            value={plateName}
            onChange={handlePlateNameChange}
            onBlur={handlePlateNameBlur}
            variant="outlined"
            placeholder="Plate Name"
            error={plateName === ''}
            helperText={
              plateName === '' && (
                <Typography className={classes.plateNameError} variant="caption" noWrap>
                  Plate name cannot be empty
                </Typography>
              )
            }
            disabled={isReadonly}
          />
        </section>
      )}
      {plateTypeName && (
        <ResinBufferInput
          elementId={elementInstance.element.id}
          parameterName={parameter.name}
          isReadonly={isReadonly}
        />
      )}
    </main>
  );
}

function usePlateSelectionHandler() {
  const {
    state: { plateType: plateTypeName, wellCapacity },
    selectPlateType,
  } = useFilterPlateEditorState();
  const [platesByType] = usePlatesByType();

  const handlePlateSelectionChange = useCallback(
    (newPlateTypeName?: PlateParameterValue) => {
      const plateTypeName = newPlateTypeName ? (newPlateTypeName as string) : null;
      const plateType = plateTypeName ? platesByType(plateTypeName) : null;
      selectPlateType(plateType);
    },
    [platesByType, selectPlateType],
  );

  useEffect(() => {
    /**
     * When plateType has been selected before and there is no wellCapacity
     * we initialise wellCapacity by selecting plate object from the library.
     */
    if (plateTypeName && wellCapacity === 0) {
      selectPlateType(platesByType(plateTypeName));
    }
  }, [plateTypeName, platesByType, selectPlateType, wellCapacity]);

  return {
    plateTypeName,
    handlePlateSelectionChange,
  };
}

function usePlateNameInput() {
  const { state, changePlateName } = useFilterPlateEditorState();
  const [plateName, setPlateName] = useState<string | null>(state.plateName);
  const handlePlateNameChange = useTextFieldChange(setPlateName);
  const handlePlateNameBlur = () => {
    const value = plateName === null ? '' : plateName;
    setPlateName(value);
    changePlateName(value);
  };
  return {
    plateName,
    handlePlateNameChange,
    handlePlateNameBlur,
  };
}

const useStyles = makeStylesHook(({ spacing }) => ({
  container: {
    display: 'flex',
    gap: spacing(7),
    width: '100%',
    padding: spacing(4),
  },
  parameterInput: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputLabelSet: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing(2),
  },
  inputLabel: {
    marginRight: spacing(3),
  },
  plateNameError: {
    position: 'absolute',
  },
}));
