import React from 'react';

import LinkOffIcon from '@mui/icons-material/LinkOff';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

import DisplayName from 'client/app/apps/protocols/annotations/DisplayName';
import { isDefined } from 'common/lib/data';
import { describeSourceDescription, ProtocolStepInput } from 'common/types/Protocol';
import Colors from 'common/ui/Colors';

type Props = {
  input: ProtocolStepInput;
  onUnlink: () => void;
};

export function LinkedInputStepItem({ input, onUnlink }: Props) {
  const sources = [
    input.sourceDescription,
    ...(input.linked?.map(l => l.sourceDescription) || []),
  ].filter(isDefined);

  return (
    <Stack overflow="hidden">
      <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={5}>
        <Tooltip title="Unlink parameters">
          <StyledIconButton onClick={onUnlink}>
            <LinkOffIcon />
          </StyledIconButton>
        </Tooltip>
        <DisplayName name={input.displayName} />
      </Stack>
      <VerticalLineStack
        alignItems="flex-start"
        marginLeft={9}
        paddingLeft={4}
        spacing={2}
      >
        {sources.map(source => {
          return (
            <DisplayName
              key={`${input.id}-${source.displayName}`}
              name={source.displayName}
              tooltip={describeSourceDescription(source)}
            />
          );
        })}
      </VerticalLineStack>
    </Stack>
  );
}

const StyledIconButton = styled(IconButton)(() => ({
  padding: 0,
  paddingLeft: 5,
}));

const VerticalLineStack = styled(Stack)(() => ({
  borderLeft: `2px solid ${Colors.GREY_30}`,
}));
