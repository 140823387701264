import React from 'react';

import ListItemButton from '@mui/material/ListItemButton';
import { styled } from '@mui/material/styles';

import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';

type Props = {
  name: string;
  selected?: boolean;
  onClick?: () => void;
};

export const CategoriesListItem = (props: Props) => {
  const { name, onClick, selected } = props;
  return (
    <CategoriesListItemButton disableRipple dense selected={selected} onClick={onClick}>
      <TypographyWithTooltip variant="subtitle2">{name}</TypographyWithTooltip>
    </CategoriesListItemButton>
  );
};

const CategoriesListItemButton = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(2),
  color: theme.palette.text.secondary,
  background: 'none',
  '&:hover': {
    background: 'none',
  },
  '&.Mui-selected': {
    background: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      background: 'none',
    },
  },
}));
