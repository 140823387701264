import React, { useCallback } from 'react';

import { CopyWorkflowBar } from 'client/app/apps/workflow-builder/TopBar/CopyWorkflowBar';
import { FormInBuilderWorkflowBar } from 'client/app/apps/workflow-builder/TopBar/FormInBuilderWorkflowBar';
import { ProtocolInBuilderWorkflowBar } from 'client/app/apps/workflow-builder/TopBar/ProtocolInBuilderWorkflowBar';
import { ScreenRegistry } from 'client/app/registry';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import { EditorType, WorkflowEditMode } from 'common/types/bundle';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';

type Props = {
  workflowId: WorkflowId;
  simulationId?: SimulationId;
  onCopyWorkflow: () => void;
};

export function WorkflowTopBar({ workflowId, simulationId, onCopyWorkflow }: Props) {
  const {
    parentWorkflowID,
    authorName,
    editMode,
    source: source,
    contentSource: contentSource,
  } = useWorkflowBuilderSelector(state => state);

  const handleCopyWorkflow = useCallback(() => {
    logEvent('copy-workflow-readonly', ScreenRegistry.WORKFLOW);
    onCopyWorkflow();
  }, [onCopyWorkflow]);

  if (
    (editMode === WorkflowEditMode.ENABLED_LATEST_OWNED_BY_ME ||
      editMode === WorkflowEditMode.ENABLED_LATEST_EXAMPLE_GALLERY) &&
    source === EditorType.WORKFLOW_EDITOR
  ) {
    // Allow normal editing
    return null;
  }

  if (source === EditorType.PROTOCOL) {
    // ProtocolInBuilderWorkflowBar handles the edit mode itself
    return (
      <ProtocolInBuilderWorkflowBar
        workflowId={workflowId}
        editMode={editMode}
        authorName={authorName}
      />
    );
  }

  if (editMode !== WorkflowEditMode.ENABLED_LATEST_OWNED_BY_ME) {
    return (
      <CopyWorkflowBar
        authorName={
          editMode !== WorkflowEditMode.DISABLED_SNAPSHOT_OWNED_BY_ME ? authorName : ''
        }
        handleCopyWorkflow={handleCopyWorkflow}
        simulationId={simulationId}
        workflowContentSource={contentSource}
        parentWorkflowID={parentWorkflowID}
      />
    );
  }

  if (source === EditorType.FORM_EDITOR) {
    return (
      <FormInBuilderWorkflowBar
        handleCopyWorkflow={handleCopyWorkflow}
        workflowId={workflowId}
      />
    );
  }
  return null;
}
