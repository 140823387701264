import { useCallback, useMemo } from 'react';

import { useApolloClient, useMutation } from '@apollo/client';

import {
  QUERY_BREADCRUMBS,
  QUERY_SIMULATION_FROM_EXECUTION_ID,
} from 'client/app/api/gql/queries';
import {
  MUTATION_CREATE_EXPERIMENT,
  MUTATION_CREATE_EXPERIMENT_BLOCK,
} from 'client/app/apps/experiments/gql/mutations';
import { QUERY_FIND_WORK_TREES } from 'client/app/apps/work-tree/queries';
import { BreadcrumbsEntityEnum, ExperimentBlockType } from 'client/app/gql';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';

export default function useAddEntityToExperiment() {
  const { showError } = useSnackbarManager();

  const [createExperimentMutation] = useMutation(MUTATION_CREATE_EXPERIMENT);
  const [createBlockMutation] = useMutation(MUTATION_CREATE_EXPERIMENT_BLOCK);
  const apolloClient = useApolloClient();

  const createNewExperiment = useCallback(
    async (name: string) => {
      try {
        const { data } = await createExperimentMutation({ variables: { name } });
        return data?.createExperiment?.id;
      } catch {
        showError('Could not create experiment');
        return undefined;
      }
    },
    [createExperimentMutation, showError],
  );
  const addWorkflowToExistingExperiment = useCallback(
    (
        entity:
          | BreadcrumbsEntityEnum.WORKFLOW
          | BreadcrumbsEntityEnum.CHERRY_PICKER
          | BreadcrumbsEntityEnum.FORM,
      ) =>
      async (id: WorkflowId, experimentID: ExperimentId) => {
        try {
          await createBlockMutation({
            variables: {
              experimentID,
              type: ExperimentBlockType.WORKFLOW,
              workflowID: id,
            },
            refetchQueries: [
              {
                query: QUERY_BREADCRUMBS,
                variables: { id, entity },
              },
              {
                query: QUERY_FIND_WORK_TREES,
                variables: { workflowId: id },
              },
            ],
          });
        } catch {
          showError('Could not add workflow to the experiment');
        }
      },
    [createBlockMutation, showError],
  );
  const addSimulationToExistingExperiment = useCallback(
    async (id: SimulationId, experimentID: ExperimentId) => {
      try {
        await createBlockMutation({
          variables: {
            experimentID,
            type: ExperimentBlockType.SIMULATION,
            simulationID: id,
          },
          refetchQueries: [
            {
              query: QUERY_BREADCRUMBS,
              variables: { id, entity: BreadcrumbsEntityEnum.SIMULATION },
            },
            {
              query: QUERY_FIND_WORK_TREES,
              variables: { simulationId: id },
            },
          ],
        });
      } catch {
        showError('Could not add simulation to the experiment');
      }
    },
    [createBlockMutation, showError],
  );
  const addExecutionToExistingExperiment = useCallback(
    async (id: ExecutionId, experimentID: ExperimentId) => {
      try {
        const { data } = await apolloClient.query({
          query: QUERY_SIMULATION_FROM_EXECUTION_ID,
          variables: {
            id,
            withTasks: true,
            withVisualisations: true,
          },
        });
        await createBlockMutation({
          variables: {
            experimentID,
            type: ExperimentBlockType.SIMULATION,
            simulationID: data.execution.simulation.id,
          },
          refetchQueries: [
            {
              query: QUERY_BREADCRUMBS,
              variables: { id, entity: BreadcrumbsEntityEnum.EXECUTION },
            },
            {
              query: QUERY_FIND_WORK_TREES,
              variables: { simulationId: data.execution.simulation.id },
            },
          ],
        });
      } catch {
        showError('Could not add execution to the experiment');
      }
    },
    [apolloClient, createBlockMutation, showError],
  );

  return useMemo(
    () => ({
      createNewExperiment,
      addToExistingExperiment: {
        addBuilderWorkflow: addWorkflowToExistingExperiment(
          BreadcrumbsEntityEnum.WORKFLOW,
        ),
        addCherryPickerWorkflow: addWorkflowToExistingExperiment(
          BreadcrumbsEntityEnum.CHERRY_PICKER,
        ),
        addFormWorkflow: addWorkflowToExistingExperiment(BreadcrumbsEntityEnum.FORM),
        addSimulation: addSimulationToExistingExperiment,
        addExecution: addExecutionToExistingExperiment,
      },
    }),
    [
      addExecutionToExistingExperiment,
      addSimulationToExistingExperiment,
      addWorkflowToExistingExperiment,
      createNewExperiment,
    ],
  );
}

export type ExperimentAdditive = {
  addToNewExperiment?: (experimentName: string) => Promise<void>;
  addToExistingExperiment?: (experimentId: ExperimentId) => Promise<void>;
};
