import React, { createContext, PropsWithChildren, useContext, useMemo } from 'react';

import { useIsWorkflowReadonly } from 'client/app/apps/workflow-builder/lib/isWorkflowReadonly';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import {
  ConfiguredDevice,
  emptyWorkflowConfig,
  GlobalMixerConfig,
  Stage,
} from 'common/types/bundle';
import { isLiquidHandlerDevice } from 'common/types/bundleConfigUtils';

export default function WorkflowSettingsStateContextProvider({
  children,
}: PropsWithChildren<{}>) {
  const { workflowConfig, editMode, source, stages, selectedStageId } =
    useWorkflowBuilderSelector(s => {
      return {
        workflowConfig: s.config,
        editMode: s.editMode,
        source: s.source,
        stages: s.stages,
        selectedStageId: s.selectedStageId,
      };
    });

  const selectedStage = useMemo(
    () => stages.find(stage => stage.id === selectedStageId),
    [selectedStageId, stages],
  );

  const selectedConfiguredDevices = useMemo(
    () =>
      workflowConfig.configuredDevices?.filter(v =>
        selectedStage?.configuredDevices.includes(v.id),
      ) ?? [],
    [selectedStage?.configuredDevices, workflowConfig.configuredDevices],
  );

  const isWorkflowReadonly = useIsWorkflowReadonly();
  const isReadonly = isWorkflowReadonly(editMode, source);

  const showCustomLHPolicies = useMemo(() => {
    const allConfiguredDevices = workflowConfig.configuredDevices ?? [];
    return allConfiguredDevices.some(isLiquidHandlerDevice);
  }, [workflowConfig]);

  return (
    <WorkflowSettingsStateContext.Provider
      value={useMemo(
        () => ({
          stages,
          selectedStage,
          selectedConfiguredDevices,
          isReadonly,
          globalWorkflowConfig: workflowConfig.global,
          showCustomLHPolicies,
        }),
        [
          isReadonly,
          selectedConfiguredDevices,
          selectedStage,
          showCustomLHPolicies,
          stages,
          workflowConfig.global,
        ],
      )}
    >
      {children}
    </WorkflowSettingsStateContext.Provider>
  );
}

type WorkflowSettingsState = {
  stages: Stage[];
  selectedStage?: Stage;
  selectedConfiguredDevices: ConfiguredDevice[];
  globalWorkflowConfig: GlobalMixerConfig;
  isReadonly: boolean;
  showCustomLHPolicies: boolean;
};

const WorkflowSettingsStateContext = createContext<WorkflowSettingsState>({
  stages: [],
  selectedConfiguredDevices: [],
  globalWorkflowConfig: emptyWorkflowConfig().global,
  isReadonly: false,
  showCustomLHPolicies: false,
});

export function useWorkflowSettingsState() {
  return useContext(WorkflowSettingsStateContext);
}
