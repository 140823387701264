import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import { Breakpoint, styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import DOMPurify from 'dompurify';
import isEqual from 'lodash/isEqual';

import { ExampleWorkflowHandler } from 'client/app/apps/simulation-details/ExampleWorkflow/useExampleWorkflowHandlers';
import { isDefined } from 'common/lib/data';
import { Markdown } from 'common/lib/markdown';
import { alphanumericCompare } from 'common/lib/strings';
import { MessagePreview } from 'common/ui/components/MessagePreview';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import { TagInput } from 'common/ui/components/TagInput';
import { DialogProps } from 'common/ui/hooks/useDialog';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

type Props = DialogProps<{}> & {
  title: string;
  deleteExample?: boolean;
  exampleWorkflow: ExampleWorkflowHandler;
};

const TAGS_FOR_EXAMPLE_WORKFLOWS = [
  'Compound management',
  'DOE',
  'ELISA',
  'Flow Cytometry',
  'Protein Purification - Filter Plates',
  'Protein Purification - RoboColumns',
  'Protein Quantification Assays',
  'Sample Prep',
  'XNA Quantification Assays',
];

export default function ExampleWorkflowDialog({
  isOpen,
  onClose: handleClose,
  title,
  deleteExample,
  exampleWorkflow,
}: Props) {
  const snackbar = useSnackbarManager();
  const dialog = useSubmitDialog(exampleWorkflow, deleteExample);

  const [loading, setLoading] = useState(false);

  const [summary, setSummary] = useState<string | undefined>();
  const [description, setDescription] = useState<Markdown | undefined>();
  const [tags, setTags] = useState<string[]>();

  const handleSummaryChange = useTextFieldChange(setSummary);
  const handleDescriptionChange = useTextFieldChange((input: string) => {
    setDescription(input as Markdown);
  });
  const handleSubmit = async () => {
    try {
      setLoading(true);
      await dialog.submit(description, summary, tags);
      snackbar.showSuccess(dialog.successCopy);
      handleClose({});
    } catch (error) {
      snackbar.showError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    /**
     * Populate text fields with values depending on the operation: create or edit
     */
    if (isOpen) {
      if (exampleWorkflow.exist && exampleWorkflow.data) {
        setDescription(exampleWorkflow.data.description as Markdown);
        setSummary(exampleWorkflow.data.summary);
        setTags(exampleWorkflow.data.tags?.filter(isDefined).map(tag => tag.name));
      } else {
        setDescription(undefined);
        setSummary(undefined);
      }
    }
  }, [exampleWorkflow.data, exampleWorkflow.exist, isOpen, setDescription]);

  return (
    <DialogContainer open={isOpen} onClose={handleClose} maxWidth={dialog.size} fullWidth>
      <Stack gap={5} overflow="hidden">
        <Header>
          <Typography variant="h4" fontWeight={600} letterSpacing="0.2px">
            {title}
          </Typography>
        </Header>
        {deleteExample ? (
          <Main>
            <Typography variant="body1">
              Are you sure you would like to delete the “{exampleWorkflow.data?.name}”
              example?
            </Typography>
          </Main>
        ) : (
          <Main>
            <Stack gap={3}>
              <Typography variant="body1" fontWeight={500}>
                Summary
              </Typography>
              <TextField
                value={summary}
                onChange={handleSummaryChange}
                autoComplete="off"
              />
            </Stack>
            <TagInput
              tags={tags || []}
              allowFreeText={false}
              options={TAGS_FOR_EXAMPLE_WORKFLOWS}
              placeholder="Start typing to select a tag"
              onTagsChange={setTags}
            />
            <Stack gap={3}>
              <Typography variant="body1" fontWeight={500}>
                Description
              </Typography>
              <TextField
                value={description}
                onChange={handleDescriptionChange}
                rows={15}
                multiline
                autoComplete="off"
              />
            </Stack>
            {description ? (
              <MarkdownPreviewContainer>
                <MessagePreview message={description} messageType="markdown" />
              </MarkdownPreviewContainer>
            ) : (
              <MarkdownPreviewPlaceholder>
                <Typography
                  variant="body1"
                  fontWeight={300}
                  textTransform="uppercase"
                  textAlign="center"
                >
                  &lt; Preview description markdown here &gt;
                </Typography>
              </MarkdownPreviewPlaceholder>
            )}
          </Main>
        )}
        <Footer>
          <Button color="inherit" onClick={handleClose}>
            Cancel
          </Button>
          <Button color={dialog.submitColor} disabled={loading} onClick={handleSubmit}>
            {loading ? <CircularProgress size={20} /> : dialog.submitCopy}
          </Button>
        </Footer>
      </Stack>
    </DialogContainer>
  );
}

function useSubmitDialog(
  exampleWorkflow: ExampleWorkflowHandler,
  deleteExample?: boolean,
) {
  let size: Breakpoint;
  let successCopy: string;
  let submitColor: 'primary' | 'error';
  let submitCopy: string;

  if (deleteExample) {
    submitCopy = 'Delete';
    successCopy = 'Example successfully deleted';
    submitColor = 'error';
    size = 'sm';
  } else {
    submitCopy = exampleWorkflow.exist ? 'Save' : 'Create';
    successCopy = 'Saved changes';
    submitColor = 'primary';
    size = 'lg';
  }

  const submit = async (
    rawDescription?: Markdown,
    rawSummary?: string,
    rawTags?: string[],
  ) => {
    if (deleteExample) {
      await exampleWorkflow.delete();
    } else if (exampleWorkflow.exist) {
      if (!rawTags || rawTags?.length === 0) {
        throw new Error('Cannot update example with no "Tags"');
      }
      await exampleWorkflow.update({
        description:
          !rawDescription || rawDescription === exampleWorkflow.data?.description
            ? undefined
            : sanitize(rawDescription),
        summary:
          !rawSummary || rawSummary === exampleWorkflow.data?.summary
            ? undefined
            : sanitize(rawSummary),
        tags:
          !rawTags ||
          isEqual(
            [...rawTags].sort(alphanumericCompare),
            exampleWorkflow.data?.tags
              ?.filter(isDefined)
              .map(tag => tag.name)
              .sort(alphanumericCompare),
          )
            ? undefined
            : rawTags,
      });
    } else {
      if (!rawDescription) {
        throw new Error('Cannot create example with empty "Description"');
      }
      if (!rawSummary) {
        throw new Error('Cannot create example with empty "Summary"');
      }
      if (!rawTags || rawTags.length === 0) {
        throw new Error('Cannot create example with no "Tags"');
      }
      await exampleWorkflow.create(
        sanitize(rawDescription),
        sanitize(rawSummary),
        rawTags.map(sanitize),
      );
    }
  };

  return {
    size,
    successCopy,
    submitColor,
    submitCopy,
    submit,
  };
}

function sanitize<T extends string>(input: T): T {
  return DOMPurify.sanitize(input.trim()) as T;
}

const DialogContainer = styled(Dialog)(({ theme: { spacing } }) => ({
  '& .MuiDialog-paper': {
    padding: spacing(6),
  },
}));

const Header = styled('header')({
  display: 'flex',
});

const Main = styled('main')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),

  overflow: 'auto',
}));

const Footer = styled('footer')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(3),
}));

const MarkdownPreviewContainer = styled('section')(({ theme }) => ({
  padding: theme.spacing(3, 5),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: 10,
}));

const MarkdownPreviewPlaceholder = styled(Stack)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',

  color: theme.palette.grey['500'],
  backgroundColor: theme.palette.grey['100'],
  borderRadius: 10,
  height: 200,
}));
