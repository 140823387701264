import {
  ArrayAdditionalProps,
  AutocompleteAdditionalProps,
  MapAdditionalProps,
} from 'common/elementConfiguration/AdditionalEditorProps';
import { EditorType } from 'common/elementConfiguration/EditorType';
import {
  getArrayTypeFromAnthaType,
  getKeyTypeFromAnthaType,
  getValueTypeFromAnthaType,
} from 'common/elementConfiguration/parameterUtils';
import { ParameterEditorConfigurationSpec } from 'common/types/commonConfiguration';

/**
 * recursively inspects sub-editors within the given editor and applies
 * AutocompleteAdditionalProps anthaTypeOverride from the given anthaType if it
 * is not already set
 */
export function applyAnthaTypeOverrides(
  editor: ParameterEditorConfigurationSpec,
  anthaType: string,
): ParameterEditorConfigurationSpec {
  if (editor.type === EditorType.ARRAY && editor.additionalProps) {
    const arrayType = getArrayTypeFromAnthaType(anthaType);
    const props = editor.additionalProps as ArrayAdditionalProps;
    const itemEditor = props.itemEditor
      ? applyAnthaTypeOverrides(props.itemEditor, arrayType)
      : props.itemEditor;
    return { ...editor, additionalProps: { ...props, itemEditor } };
  }

  if (editor.type === EditorType.MAP) {
    const keyType = getKeyTypeFromAnthaType(anthaType);
    const valueType = getValueTypeFromAnthaType(anthaType);
    const props = editor.additionalProps as MapAdditionalProps;
    const keyEditor = props.keyEditor
      ? applyAnthaTypeOverrides(props.keyEditor, keyType)
      : props.keyEditor;
    const valueEditor = props.valueEditor
      ? applyAnthaTypeOverrides(props.valueEditor, valueType)
      : props.valueEditor;
    return { ...editor, additionalProps: { ...props, keyEditor, valueEditor } };
  }

  if (editor.type === EditorType.AUTOCOMPLETE && editor.additionalProps) {
    const props = editor.additionalProps as AutocompleteAdditionalProps;
    const override = props.anthaTypeOverride ? props.anthaTypeOverride : anthaType;
    return { ...editor, additionalProps: { ...props, anthaTypeOverride: override } };
  }

  return editor;
}
