import React, { ReactNode, useCallback, useState } from 'react';

import { useMutation } from '@apollo/client';
import HomeIcon from '@mui/icons-material/Home';
import SchoolIcon from '@mui/icons-material/School';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import MuiListItem from '@mui/material/ListItem';
import MuiListItemIcon from '@mui/material/ListItemIcon';
import MuiListItemText from '@mui/material/ListItemText';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';

import { useCreateWorkflow } from 'client/app/api/WorkflowsApi';
import { createAndNavigateToWorkflow } from 'client/app/apps/experiments/createAndNavigateToWorkflow';
import CreateButton, {
  CreateButtonAction,
} from 'client/app/apps/experiments/CreateButton';
import { MUTATION_CREATE_EXPERIMENT } from 'client/app/apps/experiments/gql/mutations';
import { QUERY_EXPERIMENTS } from 'client/app/apps/experiments/gql/queries';
import TemplateWorkflowsDialog from 'client/app/apps/experiments/Templates/TemplateWorkflowsDialog';
import useUploadFileBundle from 'client/app/apps/experiments/workflowCreationUtils';
import { LSKEY_HIDE_EXAMPLE_WORKFLOWS_DIALOG_ON_NEW_WORKFLOW } from 'client/app/components/ExampleWorkflows/exampleWorkflowsUtils';
import InventorySubmenu from 'client/app/components/nav/InventorySubmenu';
import { useEntitiesCount } from 'client/app/hooks/useEntitiesCount';
import { experimentsRoutes, workflowRoutes } from 'client/app/lib/nav/actions';
import { ScreenRegistry } from 'client/app/registry';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { IntercomTourIDs } from 'common/lib/intercom';
import { IntercomTarget } from 'common/lib/IntercomTarget';
import { EditorType } from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import ContainerWithIntersectionBar from 'common/ui/components/ContainerWithIntersectionBar/ContainerWithIntersectionBar';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import useDialog from 'common/ui/hooks/useDialog';
import useStateWithLocalStorage from 'common/ui/hooks/useStateWithLocalStorage';
import { BookAndBulbIcon, ExampleWorkflowIcon, ProtocolIcon } from 'common/ui/icons';
import DOETemplateIcon from 'common/ui/icons/DOETemplateIcon';
import { DraftIcon } from 'common/ui/icons/DraftIcon';
import { ExecutionIcon } from 'common/ui/icons/Execution';
import { IncomingWorkflowIcon } from 'common/ui/icons/IncomingWorkflowIcon';
import { NewExperimentsIcon } from 'common/ui/icons/NewExperimentsIcon';
import { WorkflowIcon } from 'common/ui/icons/Workflow';
import { navigateToAndKeepURLSearchParams } from 'common/ui/navigation';
import { trackHeapEvent } from 'common/ui/useHeapTracking';

export const NEW_EXPERIMENT_NAME = 'My experiment';

export function useCreateNew() {
  const isEnabledQuickStartActivation = useFeatureToggle('QUICK_START_ACTIVATION');
  const navigation = useNavigation();
  const snackbar = useSnackbarManager();
  const createWorkflow = useCreateWorkflow();
  const [createExperimentMutation, { loading: isCreatingExperiment }] = useMutation(
    MUTATION_CREATE_EXPERIMENT,
  );
  const [isCreatingWorkflow, setCreatingWorkflow] = useState(false);

  const [templateWorklowsDialog, openTemplateWorklowsDialog] = useDialog(
    TemplateWorkflowsDialog,
  );

  const [isPreferenceToHideExampleWorkflowsDialog] = useStateWithLocalStorage<boolean>(
    LSKEY_HIDE_EXAMPLE_WORKFLOWS_DIALOG_ON_NEW_WORKFLOW,
    false,
  );

  const launchExampleWorkflowsDialog =
    isEnabledQuickStartActivation && !isPreferenceToHideExampleWorkflowsDialog;

  const createNew = useCallback(
    async (action: CreateButtonAction) => {
      switch (action) {
        case 'new-protocol': {
          navigation.navigate(experimentsRoutes.protocols, undefined);
          break;
        }
        case 'new-experiment': {
          const { data } = await createExperimentMutation({
            variables: { name: NEW_EXPERIMENT_NAME },
            refetchQueries: [{ query: QUERY_EXPERIMENTS }],
          });
          const newExperimentId = data?.createExperiment?.id;
          if (newExperimentId) {
            // Navigate to the Experiment we just created
            navigation.navigate(experimentsRoutes.detail, {
              id: newExperimentId,
            });
          }
          break;
        }
        case 'new-workflow-from-template': {
          logEvent('open-template-dialog', ScreenRegistry.EXPERIMENTS);
          window.Intercom('trackEvent', 'open-template-dialog');
          await openTemplateWorklowsDialog({});
          break;
        }
        case 'new-workflow-builder': {
          logEvent('create-new-workflow', ScreenRegistry.EXPERIMENTS, 'builder');
          trackHeapEvent('new-workflow-builder-click', {
            source: 'NavigationSidepanel',
            launchExampleWorkflowsDialog: launchExampleWorkflowsDialog ? 'true' : 'false',
          });
          void createAndNavigateToWorkflow(
            'New Workflow',
            EditorType.WORKFLOW_EDITOR,
            snackbar,
            navigation,
            createWorkflow,
            setCreatingWorkflow,
            launchExampleWorkflowsDialog,
          );
          break;
        }
        case 'new-cherry-picker': {
          logEvent('create-new-workflow', ScreenRegistry.EXPERIMENTS, 'cherry-picker');
          await createAndNavigateToWorkflow(
            'New Cherry-Picker',
            EditorType.CHERRY_PICKER,
            snackbar,
            navigation,
            createWorkflow,
            setCreatingWorkflow,
          );
          break;
        }
        default: {
          console.error('Unknown action from the Create button:', action);
        }
      }
    },
    [
      createExperimentMutation,
      createWorkflow,
      launchExampleWorkflowsDialog,
      navigation,
      openTemplateWorklowsDialog,
      snackbar,
    ],
  );

  const uploadFileBundle = useUploadFileBundle();

  // The person picked a local file they want to upload.
  // The file contains a workflow and we'll create a new Workflow in Antha.
  const createWorkflowFromFile = useCallback(
    async (file: File) => {
      try {
        logEvent('create-new-workflow', ScreenRegistry.EXPERIMENTS, 'from-upload');
        const workflowId = await uploadFileBundle(file);
        navigation.navigate(workflowRoutes.openInWorkflowBuilder, {
          workflowId,
        });
      } catch (e) {
        console.error(e);
        snackbar.showError('Failed to import workflow');
      } finally {
        setCreatingWorkflow(false);
      }
    },
    [navigation, snackbar, uploadFileBundle],
  );

  return {
    createNew,
    createWorkflowFromFile,
    isCreating: isCreatingWorkflow || isCreatingExperiment,
    templateWorklowsDialog,
  };
}

export function NavigationPanel({ className }: { className?: string }) {
  const isEnabledLandingPage = useFeatureToggle('LANDING_PAGE');
  const isEnabledQuickStartActivation = useFeatureToggle('QUICK_START_ACTIVATION');
  const areProtocolsEnabled = useFeatureToggle('PROTOCOLS');

  const { createNew, createWorkflowFromFile, isCreating, templateWorklowsDialog } =
    useCreateNew();

  const { data: entityCounts, loading: entityCountsLoading } = useEntitiesCount();

  return (
    <>
      <Container className={className}>
        {isEnabledLandingPage || (
          <CreateWithProgressIndicator>
            <CreateButton
              onItemClick={createNew}
              onPickLocalWorkflowFile={createWorkflowFromFile}
            />
            {isCreating && <CreatingProgress size={24} variant="indeterminate" />}
          </CreateWithProgressIndicator>
        )}
        <ContainerWithIntersectionBar
          dense
          noHeader
          content={
            <>
              <IntercomTarget
                tour={IntercomTourIDs.EXPERIMENTS_HOME_PAGE}
                name="side-nav"
              >
                <List dense>
                  {isEnabledLandingPage && (
                    <NavItem route="/" icon={<HomeIcon />} text="Home" />
                  )}
                  <IntercomTarget
                    tour={IntercomTourIDs.EXPERIMENTS_HOME_PAGE}
                    name="side-nav-experiments"
                  >
                    <NavItem
                      route={experimentsRoutes.base.getPath()}
                      icon={<NewExperimentsIcon />}
                      text="Experiments"
                    />
                  </IntercomTarget>
                  <NavItem
                    route={experimentsRoutes.workflows.getPath()}
                    icon={<WorkflowIcon />}
                    text="Workflows"
                  />
                  <NavItem
                    route={experimentsRoutes.drafts.getPath()}
                    icon={<DraftIcon />}
                    text="Drafts"
                  />
                  <NavItem
                    route={experimentsRoutes.executions.getPath()}
                    icon={<ExecutionIcon />}
                    text="Executions"
                  />
                  {entityCountsLoading && <NavItemSkeleton />}
                  {!entityCountsLoading &&
                    entityCounts &&
                    entityCounts.doeWorkflows > 0 && (
                      <NavItem
                        route={experimentsRoutes.doeTemplates.getPath()}
                        icon={<DOETemplateIcon />}
                        text="DOE templates"
                      />
                    )}
                  {entityCountsLoading && <NavItemSkeleton />}
                  {!entityCountsLoading &&
                    entityCounts &&
                    entityCounts.incomingWorkflows > 0 && (
                      <NavItem
                        route={experimentsRoutes.incoming.getPath()}
                        icon={<IncomingWorkflowIcon />}
                        text="Incoming"
                      />
                    )}
                  <>
                    <Divider />
                    {isEnabledQuickStartActivation ? (
                      <>
                        <NavItem
                          route={experimentsRoutes.examples.getPath()}
                          icon={<BookAndBulbIcon />}
                          text="Example workflows"
                          onClick={() => {
                            trackHeapEvent('examples-click', {
                              source: 'NavigationSidepanel',
                            });
                          }}
                        />
                        {areProtocolsEnabled && (
                          <NavItem
                            route={experimentsRoutes.protocols.getPath()}
                            icon={<ProtocolIcon />}
                            text="Protocols"
                          />
                        )}
                        <NavButton
                          icon={<ExampleWorkflowIcon />}
                          text="Templates"
                          onClick={() => createNew('new-workflow-from-template')}
                        />
                        <NavItem
                          route={experimentsRoutes.tutorials.getPath()}
                          icon={<SchoolIcon />}
                          text="Tutorials"
                        />
                      </>
                    ) : (
                      <NavItem
                        route={experimentsRoutes.exampleGallery.getPath()}
                        icon={<BookAndBulbIcon />}
                        text="Example gallery"
                      />
                    )}
                  </>
                </List>
              </IntercomTarget>
              <InventorySubmenu />
            </>
          }
        />
      </Container>
      {templateWorklowsDialog}
    </>
  );
}

export default function NavigationSidepanel({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <Screen className={className}>
      <NavigationPanel />
      {children}
    </Screen>
  );
}

export function withNavigationSidepanel<T extends React.ComponentType<any>>(
  Component: T,
) {
  return function Decorator(props: React.ComponentProps<T>) {
    return (
      <NavigationSidepanel>
        <Component {...props} />
      </NavigationSidepanel>
    );
  };
}

type NavItemProps = {
  route: string;
  icon: ReactNode;
  text: string;
  onClick?: () => void;
};

function NavItem({ route, icon, text, onClick }: NavItemProps) {
  return (
    <ListItem>
      <NavLink
        activeStyle={{ color: Colors.PRIMARY_MAIN }}
        to={navigateToAndKeepURLSearchParams(route)}
        exact
        onClick={onClick}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
      </NavLink>
    </ListItem>
  );
}

function NavButton({ icon, text, onClick }: Partial<NavItemProps>) {
  return (
    <ListItem>
      <StyledNavButton variant="tertiary" onClick={onClick}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
      </StyledNavButton>
    </ListItem>
  );
}

function NavItemSkeleton() {
  return (
    <ListItem>
      <ListItemIcon>
        <Skeleton variant="circular" width={24} height={24} />
      </ListItemIcon>
      <ListItemText>
        <Skeleton variant="text" />
      </ListItemText>
    </ListItem>
  );
}

export const NAVIGATION_WIDTH = 200;

const Screen = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  height: '100%',
  padding: theme.spacing(8),
  background: Colors.GREY_5,
  overflowY: 'hidden',
}));

const StyledNavButton = styled(Button)(({ theme }) => ({
  padding: 0,
  textTransform: 'none',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  boxShadow: 'none',
  minWidth: 'auto',
  fontWeight: 'inherit',
  color: theme.palette.text.primary,
}));

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  width: NAVIGATION_WIDTH,
  marginRight: theme.spacing(8),
  flexDirection: 'column',
}));

const CreateWithProgressIndicator = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: theme.spacing(5),
}));

const CreatingProgress = styled(CircularProgress)(({ theme }) => ({
  marginLeft: theme.spacing(3),
}));

const ListItem = styled(MuiListItem)(({ theme }) => ({
  color: 'inherit',
  padding: theme.spacing(3, 0, 3, 3),
  '& a': {
    display: 'flex',
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
}));

const ListItemIcon = styled(MuiListItemIcon)(() => ({
  // Need to reset the minWidth since ListItemIcon has a default minWidth.
  minWidth: 'auto',
  color: 'inherit',
}));

const ListItemText = styled(MuiListItemText)(({ theme }) => ({
  marginLeft: theme.spacing(3),
}));

const Divider = styled('hr')(({ theme: { spacing } }) => ({
  border: '0',
  borderBottom: `1px solid rgba(0,0,0,.2)`,
  margin: spacing(4, 0),
}));
