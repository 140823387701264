import React, { useState } from 'react';

import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import { ExperimentsNavigation } from 'client/app/apps/experiments/ExperimentsNavigation';
import { ProtocolsList } from 'client/app/apps/protocols/ProtocolsList';
import { HeaderWithDescription } from 'client/app/components/ExampleWorkflows/HeaderWithDescription';
import { HasPermission } from 'client/app/components/Permissions';
import { useUserProfile } from 'client/app/hooks/useUserProfile';
import { useStateWithURLParams } from 'common/ui/hooks/useStateWithURLParams';

export default function ProtocolsScreen() {
  const currentUser = useUserProfile();
  const [searchQuery, setSearchQuery] = useStateWithURLParams({
    paramName: 'search',
    paramType: 'string',
    defaultValue: '',
  });

  const [tabIndex, setTabIndex] = useState(0);

  const handleSetTabIndex = (_: React.SyntheticEvent, index: number) => {
    setTabIndex(index);
  };

  return (
    <ExperimentsNavigation title="Protocols">
      <HeaderWithDescription
        title="Protocols"
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        descriptionSlot={
          <div>
            <Typography variant="body1">
              Explore your collection of ready-to-use protocols for your common scientific
              use cases.
            </Typography>
            <Typography variant="body1">
              You can configure these to fit your specific Protocol Run.
            </Typography>
          </div>
        }
      />
      <HasPermission
        permission="read:othersProtocol"
        renderItem={(hasPermission: boolean) => (
          <Grid>
            <StyledTabs value={tabIndex} onChange={handleSetTabIndex}>
              <Tab label="Public" />
              <Tab label="Authored by me" />
              <Tab label="Drafts" />
              {hasPermission && <Tab label="All" />}
            </StyledTabs>
            <ProtocolsList
              hidden={tabIndex !== 0}
              currentUserId={currentUser?.id}
              searchQuery={searchQuery}
              queryVariables={{
                isLatest: true,
                isDeleted: false,
                isPublic: true,
              }}
            />
            <ProtocolsList
              hidden={tabIndex !== 1}
              currentUserId={currentUser?.id}
              searchQuery={searchQuery}
              queryVariables={{
                isLatest: true,
                isDeleted: false,
                isPublished: true,
                userId: currentUser?.id,
              }}
            />
            <ProtocolsList
              hidden={tabIndex !== 2}
              currentUserId={currentUser?.id}
              searchQuery={searchQuery}
              queryVariables={{
                isPublished: false,
                isDeleted: false,
                userId: currentUser?.id,
              }}
            />
            {hasPermission && (
              <ProtocolsList
                hidden={tabIndex !== 3}
                currentUserId={currentUser?.id}
                searchQuery={searchQuery}
                queryVariables={{
                  isDeleted: false,
                }}
              />
            )}
          </Grid>
        )}
      />
    </ExperimentsNavigation>
  );
}

const StyledTabs = styled(Tabs)({
  gridArea: 'header',
});

const Grid = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'minmax(180px, 0.75fr) 4fr',
  gridTemplateRows: '48px 1fr',
  gridTemplateAreas: '". header" "sidebar contents"',
  columnGap: theme.spacing(8),
  rowGap: theme.spacing(6),
  overflow: 'hidden',
}));
