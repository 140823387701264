import React from 'react';

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import isEmpty from 'lodash/isEmpty';

import { useElementContext } from 'client/app/apps/workflow-builder/lib/useElementContext';
import { useElementInstance } from 'client/app/apps/workflow-builder/lib/useElementInstance';
import { getOutputVisualisationType } from 'client/app/components/ElementPlumber/ElementOutputs/helpers';
import OutputVisualisation from 'client/app/components/ElementPlumber/ElementOutputs/OutputVisualisation';
import { OutputEntity } from 'client/app/components/ElementPlumber/ElementOutputs/types';
import ElementParameterHelpIcon from 'client/app/components/Parameters/ElementParameterHelpIcon';
import { useElementErrors } from 'client/app/components/ValidationIndicator/ValidationIndicator';
import { getParameterDisplayName } from 'client/app/lib/workflow/elementConfigUtils';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
  WorkflowBuilderAction,
} from 'client/app/state/WorkflowBuilderStateContext';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { isDefined } from 'common/lib/data';
import Colors from 'common/ui/Colors';

export default function ElementOutputs() {
  const elementInstance = useElementInstance();
  const { context, loading } = useElementContext(elementInstance?.Id ?? '');
  const dispatch = useWorkflowBuilderDispatch();

  const focusOnElement = (elementId: string) => {
    dispatch({ type: 'centerToElement', payload: { elementId, selectElement: true } });
  };
  const { status } = useElementErrors(loading, focusOnElement);

  const isElementConfigDebugModeEnabled = useFeatureToggle(
    'ELEMENT_CONFIGURATION_DEBUG_MODE',
  );

  const entityView = useWorkflowBuilderSelector(
    state => state.outputPreviewProps.entityView,
  );
  const selectedOutputParameterName = useWorkflowBuilderSelector(
    state => state.outputPreviewProps.selectedOutputParameterName,
  );

  const onEntityClick = (
    entityView: OutputEntity | undefined,
    selectedOutputParameterName: string,
  ) => {
    const output = context?.outputs[selectedOutputParameterName];
    const outputType = getOutputVisualisationType(output?.parameter);
    const action: WorkflowBuilderAction = !entityView
      ? { type: 'closeOutputPreview' }
      : {
          type: 'openOutputPreview',
          payload: {
            selectedElementId: elementInstance?.Id,
            selectedOutputParameterName,
            outputType,
            entityView,
          },
        };
    dispatch(action);
  };

  const hasError = elementInstance?.Meta.errors?.some(e => e.severity === 'error');
  const hasOutputs = !isEmpty(context?.outputs);
  const someValidationNotifications = status === 'notification';

  const validationErrorContent = (
    <Alert severity="error">No outputs available due to workflow errors</Alert>
  );
  const noOutputsContent = (
    <Alert severity="info">This element has no outputs at the moment</Alert>
  );

  const outputsContent =
    elementInstance?.element.outputs
      .map(output => {
        const contextOutput = context?.outputs[output.name];

        if (!contextOutput) return null;

        const { parameter } = contextOutput;
        const displayName = parameter
          ? getParameterDisplayName(parameter, isElementConfigDebugModeEnabled)
          : output.name;

        const isActive = selectedOutputParameterName === output.name;
        const entity = isActive ? entityView : undefined;

        return (
          <OutputRow key={output.name} active={isActive}>
            <Header>
              <Typography variant="caption">{displayName}</Typography>
              {elementInstance && parameter && (
                <ElementParameterHelpIcon
                  elementId={elementInstance.element.id}
                  name={parameter.name}
                  output={output.name}
                />
              )}
            </Header>
            {elementInstance && parameter && (
              <OutputVisualisation
                elementId={elementInstance.Id}
                parameter={parameter}
                entity={entity}
                onClick={entity => {
                  onEntityClick(entity, output.name);
                }}
              />
            )}
          </OutputRow>
        );
      })
      .filter(isDefined) ?? null;

  let contents: JSX.Element | JSX.Element[] | null;

  if (someValidationNotifications) {
    if (hasError) {
      contents = validationErrorContent;
    } else if (hasOutputs) {
      contents = outputsContent;
    } else {
      contents = validationErrorContent;
    }
  } else {
    if (hasError) {
      contents = noOutputsContent;
    } else if (hasOutputs) {
      contents = outputsContent;
    } else {
      contents = noOutputsContent;
    }
  }

  return <Stack pt={3}>{contents}</Stack>;
}

const OutputRow = styled('section')<{ active: boolean }>(({ theme, active }) => ({
  display: 'grid',
  gridTemplateRows: '16px 36px',
  padding: theme.spacing(3, 4),

  backgroundColor: active ? Colors.BLUE_0 : 'inherit',
}));

const Header = styled('header')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});
