import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';

import { QUERY_INSTANCE_CONFIG_BY_DEVICE_ID } from 'client/app/api/gql/queries';
import CANCEL_CHOICE from 'client/app/components/Parameters/cancel';
import QueryHighlighter from 'client/app/components/QueryHighlighter';
import { useConfiguredDevicesContext } from 'client/app/state/ConfiguredDevicesProvider/ConfiguredDevicesProvider';
import { useWorkflowBuilderDispatch } from 'client/app/state/WorkflowBuilderStateContext';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import LinearProgress from 'common/ui/components/LinearProgress';
import GenericInputEditor from 'common/ui/components/ParameterEditors/GenericInputEditor';
import SearchField from 'common/ui/components/SearchField';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { DialogProps } from 'common/ui/hooks/useDialog';

const Cell = withStyles({
  root: {
    // MUI table has a border beneath each row by default, but that adds a lot
    // of lines to this dialog.
    borderBottom: 'none',
    // Keep the top lines of the key and label aligned
    verticalAlign: 'top',
  },
})(TableCell);

const LabelCell = withStyles({
  root: {
    // Big enough to fit the longest key ('Measurement Method') without
    // wrapping.
    width: '172px',
  },
})(Cell);

// Protocol type IDs, used by the plate reader element
export type ProtocolType =
  | 'PlateReaderAbsorbanceProtocol'
  | 'PlateReaderAbsorbanceSpectraProtocol'
  | 'PlateReaderFluorescenceProtocol'
  | 'PlateReaderLuminescenceProtocol';

// Structure of Protocol objects within plate reader device instance configs. Right now
// this is the data structure reported by the protocol definition from the device
// manufacturers. It's not ideal, but for for the robocolumns MVP this is what we're
// working with.
type ProtocolConfig = {
  Name: string;
  MeasurementMethod: string;
  Plate: {
    Name: string;
    Length: number;
    Width: number;
    NumCols: number;
    NumRows: number;
    WellDepth: number;
    WellShape: number;
    WellWidth: number;
  };
};

// The dialog can either be closed with CANCEL_CHOICE symbol (cancel button, escape key)
// or a string (protocol name or empty string for none)
type Props = {
  value: string;
  protocolType: ProtocolType;
  workflowId?: string;
} & DialogProps<string | typeof CANCEL_CHOICE>;

type ErrorDialog = {
  title: string;
  message: string;
  action?: JSX.Element;
};

// Map of MeasurementMethod IDs (from device instanceConfig) to protocol types
const MEASUREMENT_METHOD_TO_PROTOCOL_TYPE: {
  [key: string]: ProtocolType;
} = {
  Absorbance: 'PlateReaderAbsorbanceProtocol',
  'Absorbance Spectra': 'PlateReaderAbsorbanceSpectraProtocol',
  'Time resolved fluorescence': 'PlateReaderFluorescenceProtocol',
  'Fluorescence intensity': 'PlateReaderFluorescenceProtocol',
  'Fluorescence polarization': 'PlateReaderFluorescenceProtocol',
  Luminescence: 'PlateReaderLuminescenceProtocol',
};

// Textual descriptions of protocol types
const PROTOCOL_TYPE_NAME = {
  PlateReaderAbsorbanceProtocol: { article: 'an', noun: 'Absorbance Protocol' },
  PlateReaderAbsorbanceSpectraProtocol: {
    article: 'an',
    noun: 'Absorbance Spectra Protocol',
  },
  PlateReaderFluorescenceProtocol: {
    article: 'a',
    noun: 'Fluorescence Protocol',
  },
  PlateReaderLuminescenceProtocol: {
    article: 'a',
    noun: 'Luminescence Protocol',
  },
};

export default function PlateReaderProtocolDialog(props: Props) {
  const { onClose, value, isDisabled, isOpen, workflowId, protocolType } = props;
  const classes = useStyles();
  const dispatch = useWorkflowBuilderDispatch();
  const { activeConfiguredDevices } = useConfiguredDevicesContext();
  const [selectedId, setSelectedId] = useState<string | undefined>(value);
  const [searchQuery, setSearchQuery] = useState<RegExp | null>(null);

  const onOpenConfig = useCallback(() => {
    onClose(CANCEL_CHOICE);
    dispatch({ type: 'setActivePanel', payload: 'WorkflowSettings' });
  }, [dispatch, onClose]);

  const onConfirm = useCallback(() => onClose(selectedId || ''), [onClose, selectedId]);
  const onCancel = useCallback(() => onClose(CANCEL_CHOICE), [onClose]);
  const onKeyDown = useCallback(
    (e: { key: string }) => e.key === 'Enter' && onConfirm(),
    [onConfirm],
  );
  const onGenericInput = useCallback(
    (entry: React.SetStateAction<string | undefined>) => setSelectedId(entry),
    [],
  );
  const onSearch = useCallback((query: string | RegExp) => {
    setSearchQuery(query ? new RegExp(query, 'gi') : null);
  }, []);

  const protocolName = PROTOCOL_TYPE_NAME[protocolType];

  // Assume at most one plate reader is set up in the active devices
  const plateReaderDeviceId = activeConfiguredDevices?.find(
    d => d.type === 'PlateReader',
  )?.deviceId;

  // Retrieve the instanceConfig for a given device ID
  const {
    data,
    loading,
    error: queryError,
  } = useQuery(
    QUERY_INSTANCE_CONFIG_BY_DEVICE_ID,
    // Skip the query if there is no plate reader set up in the active devices
    plateReaderDeviceId
      ? { variables: { id: plateReaderDeviceId as DeviceId } }
      : { skip: true },
  );

  // We're assuming just one plate reader has been configured.
  const plateReaderDevice = data?.devices[0];

  // Get the list of protocols from the device instance config. Filter to just
  // protocols of the right type and matching user's search.
  const protocols = useMemo<ProtocolConfig[] | undefined>(
    () =>
      plateReaderDevice?.instanceConfig?.config?.InstanceConfig?.AvailableProtocols?.filter(
        (protocol: ProtocolConfig) =>
          MEASUREMENT_METHOD_TO_PROTOCOL_TYPE[protocol.MeasurementMethod] ===
            protocolType &&
          (!searchQuery || protocol.Name.match(searchQuery)),
      ),
    [plateReaderDevice, protocolType, searchQuery],
  );
  const areProtocols = protocols && protocols.length > 0;

  const error = useMemo<ErrorDialog | undefined>(() => {
    if (!plateReaderDevice) {
      return {
        title: `No plate reader has been selected`,
        message: `Please enter the name of a protocol to run manually:`,
        action: (
          <GenericInputEditor
            type=""
            placeholder="Protocol name..."
            value={value}
            onChange={onGenericInput}
            isDisabled={isDisabled}
          />
        ),
      };
    } else if (!areProtocols) {
      return {
        title: `No ${protocolName.noun}s available`,
        message: `Please select another plate reader or configure the current one with ${protocolName.noun}s.`,
        action:
          /* workflowId is set if accessed from the workflow builder, but not
            for example from the cherrypicker i.e. workflowId is not guaranteed
            to be set in the parameter editor. */
          workflowId ? (
            <Button variant="secondary" onClick={onOpenConfig}>
              Configure devices
            </Button>
          ) : undefined,
      };
    } else if (queryError) {
      return {
        title: `Problem getting ${protocolName.noun}s`,
        message: `There was an error fetching protocols for the configured devices. Please see below for details:`,
        action: <pre>{queryError.message}</pre>,
      };
    }
    return;
  }, [
    plateReaderDevice,
    areProtocols,
    queryError,
    value,
    onGenericInput,
    isDisabled,
    protocolName.noun,
    workflowId,
    onOpenConfig,
  ]);

  const selected = useMemo<ProtocolConfig | undefined>(
    () => protocols?.find(p => p.Name === selectedId),
    [protocols, selectedId],
  );

  const datatableRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    // When selection changes, scroll information box to top
    if (datatableRef.current) {
      datatableRef.current.scrollTo(0, 0);
    }
  }, [selectedId, datatableRef]);

  return (
    <Dialog open={isOpen} onClose={onCancel} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5" className={classes.title}>
            Choose {protocolName.article} {protocolName.noun}
          </Typography>
          {areProtocols && (
            <SearchField
              onQueryChange={onSearch}
              className={classes.searchField}
              placeholder="Search protocols..."
            />
          )}
        </Box>
      </DialogTitle>
      <DialogContent className={classes.dialogContent} dividers>
        {loading && <LinearProgress />}
        {error ? (
          <Box p={6}>
            <Typography variant="h5" paragraph>
              {error.title}
            </Typography>
            <Typography variant="body2" paragraph>
              {error.message}
            </Typography>
            <Typography variant="body2" paragraph>
              {error.action}
            </Typography>
          </Box>
        ) : (
          <Grid container className={classes.container}>
            <Grid item xs={4} className={classes.protocolList}>
              {/* MenuList allows navigation with up/down keys, unlike List */}
              <MenuList autoFocus dense>
                {protocols?.map(protocol => (
                  <MenuItem
                    key={protocol.Name}
                    className={classes.listItem}
                    classes={{ selected: classes.selected }}
                    selected={selectedId === protocol.Name}
                    autoFocus={selectedId === protocol.Name}
                    onFocus={() => setSelectedId(protocol.Name)} // use focus, not click, so that up/down keys change selection
                    onKeyDown={onKeyDown} // handle enter key
                  >
                    <QueryHighlighter text={protocol.Name} query={searchQuery} />
                  </MenuItem>
                ))}
              </MenuList>
            </Grid>
            <Grid item xs className={classes.protocolInfo} ref={datatableRef}>
              {selected && (
                <Table size="small">
                  <TableRow>
                    <Cell variant="head">Protocol</Cell>
                  </TableRow>
                  <TableRow>
                    <LabelCell>Name</LabelCell>
                    <Cell>{selected.Name}</Cell>
                  </TableRow>
                  <TableRow>
                    <LabelCell>Measurement Method</LabelCell>
                    <Cell>{selected?.MeasurementMethod}</Cell>
                  </TableRow>
                  <TableRow>
                    <LabelCell>Device</LabelCell>
                    <Cell>{plateReaderDevice?.name}</Cell>
                  </TableRow>
                  <TableRow>
                    <Cell variant="head">Required Plate</Cell>
                  </TableRow>
                  <TableRow>
                    <LabelCell>Name</LabelCell>
                    <Cell>{selected?.Plate.Name}</Cell>
                  </TableRow>
                  <TableRow>
                    <LabelCell>Number of wells</LabelCell>
                    <Cell>
                      {selected
                        ? selected.Plate.NumRows * selected.Plate.NumCols
                        : 'Unknown'}
                    </Cell>
                  </TableRow>
                </Table>
              )}
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="tertiary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="tertiary"
          onClick={onConfirm}
          color="primary"
          disabled={(!!plateReaderDevice && !areProtocols) || !!queryError}
        >
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStylesHook({
  container: {
    height: '100%',
  },
  searchField: {
    margin: 0,
  },
  title: {
    fontSize: '18px', // TODO: default too large, but at some point we should make this consistent across dialogs
  },
  protocolList: {
    borderRight: `1px solid ${Colors.GREY_20}`,
    overflowY: 'auto',
    height: '100%',
  },
  protocolInfo: {
    overflowY: 'auto',
    height: '100%',
  },
  selected: {}, // needed for '&$selected' material ui override below
  listItem: {
    paddingLeft: '24px', // horizontally align list item text with dialog title
    wordBreak: 'break-word', // protocol names can be long with underscores instead of spaces. make sure they can wrap.
    whiteSpace: 'normal',
    '&$selected': {
      backgroundColor: Colors.LIGHT_BLUE, // default grey is confusing
    },
  },
  dialogContent: {
    height: '320px', // height must be constrained or it will flow off the page when there is a long list of protocols
    padding: '0',
  },
});
