import React, { FC } from 'react';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

type Props = {
  width: number | undefined;
  options: string[];
  onChange: (tag: string) => void;
};

const Options: FC<Props> = ({ width, options, onChange }) => {
  return (
    <Paper elevation={8} sx={{ width }}>
      <OptionsList>
        {options.map((option, index) => (
          <OptionItem key={index} onClick={() => onChange(option)}>
            {option}
          </OptionItem>
        ))}
      </OptionsList>
    </Paper>
  );
};

export default Options;

const OptionsList = styled('ul')(({ theme }) => ({
  listStyle: 'none',
  margin: 0,
  padding: theme.spacing(3, 0),
  outline: 0,
  maxHeight: 200,
  overflowY: 'auto',
}));

const OptionItem = styled('li')(({ theme }) => ({
  padding: theme.spacing(3, 5),
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));
