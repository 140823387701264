import React, { FC } from 'react';

import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

type Props = {
  tags: string[];
  onDelete: (tag: string) => void;
};

const Tags: FC<Props> = ({ tags, onDelete }) => {
  return (
    <TagsContainer>
      {tags.map((tag, index) => (
        <Chip key={index} label={tag} onDelete={() => onDelete(tag)} />
      ))}
    </TagsContainer>
  );
};

export default Tags;

const TagsContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4),
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(3),
}));
