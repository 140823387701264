import React, { useCallback } from 'react';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import StatusBreadcrumbs from 'client/app/apps/landing-page/status/StatusBreadcrumbs';
import { useCanDeleteProtocolInstance } from 'client/app/apps/protocols/RecentProtocolInstanceList/useCanDeleteInstance';
import { ProtocolInstancesQuery } from 'client/app/gql';
import { formatDateTime } from 'common/lib/format';
import Colors from 'common/ui/Colors';
import IconButton from 'common/ui/components/IconButton';
import Tooltip from 'common/ui/components/Tooltip';

type Props = {
  instance: ProtocolInstancesQuery['protocolInstances']['items'][number];
  showVersion?: boolean;
  onClick: () => void;
  onDelete: () => void;
  gridRow: number;
};

const ProtocolInstancesListItem = ({
  instance,
  showVersion,
  gridRow,
  onClick,
  onDelete,
}: Props) => {
  const [canDelete, title] = useCanDeleteProtocolInstance(instance);

  const handleDelete = useCallback(
    async (event: React.MouseEvent) => {
      // Icon for delete is under <a> tag that causes redirect.
      // In order to prevent the redirect, the click event must not propagate up
      event.preventDefault();
      event.stopPropagation();

      if (canDelete) {
        onDelete();
      }
    },
    [canDelete, onDelete],
  );

  return (
    <Row key={instance.id} onClick={() => onClick()}>
      <Background gridRow={gridRow} />
      <Cell gridRow={gridRow} gridColumn="name">
        <Typography variant="overline">{instance.name}</Typography>
      </Cell>
      <Cell gridRow={gridRow} gridColumn="status">
        <Status breadcrumbs={instance.status} stages={['plan', 'run']} />
      </Cell>

      {showVersion && (
        <Cell gridRow={gridRow} gridColumn="version" justifySelf="right">
          <Typography variant="overline">{instance.protocol.version}</Typography>
        </Cell>
      )}
      <Cell gridRow={gridRow} gridColumn="author">
        <Typography variant="overline">{instance.createdBy.displayName}</Typography>
      </Cell>
      <Cell gridRow={gridRow} gridColumn="date">
        <Typography variant="overline">
          {formatDateTime(new Date(instance.createdAt))}
        </Typography>
      </Cell>
      <Cell gridRow={gridRow} gridColumn="action" justifySelf="center">
        <Tooltip title={title}>
          <StyledIconButton
            onClick={handleDelete}
            size="small"
            component="span"
            icon={<DeleteOutlineIcon />}
            disabled={!canDelete}
          />
        </Tooltip>
      </Cell>
    </Row>
  );
};

const StyledIconButton = styled(IconButton)({
  '&.Mui-disabled': {
    // avoid triggerring parent's onClick when clicking on disabled icon
    pointerEvents: 'auto',
  },
});

const Background = styled('div')<{ gridRow: number }>(({ gridRow }) => ({
  gridRow,
  gridColumn: '1 / -1',
  height: '100%',
  cursor: 'pointer',
  // :hover or sibling :hover
  '&:hover,&:has(~:hover)': {
    background: Colors.BLUE_0,
  },
  borderBottom: '1px solid',
  borderBottomColor: Colors.GREY_20,
}));

const Status = styled(StatusBreadcrumbs)({
  gridColumn: 'status',
  alignSelf: 'center',
});

export const Cell = styled('div', {
  shouldForwardProp: p => p !== 'gridColumn' && p !== 'gridRow',
})<{ gridColumn: string; gridRow: number; justifySelf?: string }>(
  ({ gridColumn, gridRow, justifySelf = 'auto' }) => ({
    gridRow,
    gridColumn,
    justifySelf,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    height: '64px',
  }),
);

const Row = styled('div')({
  display: 'contents',
});

export { ProtocolInstancesListItem };
