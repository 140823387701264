import React, { useCallback, useState } from 'react';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import StatusBreadcrumbs from 'client/app/apps/landing-page/status/StatusBreadcrumbs';
import { EntityCardExpander } from 'client/app/apps/protocols/EntityCardExpander';
import ConfirmDeletion from 'client/app/apps/protocols/RecentProtocolInstanceList/ProtocolInstancesDialog/ConfirmDeletion';
import { useCanDeleteProtocolInstance } from 'client/app/apps/protocols/RecentProtocolInstanceList/useCanDeleteInstance';
import { ProtocolInstancesQuery } from 'client/app/gql';
import Colors from 'common/ui/Colors';
import { EntityCard } from 'common/ui/components/EntityCard';
import IconButton from 'common/ui/components/IconButton';
import Tooltip from 'common/ui/components/Tooltip';
import { ExampleWorkflowIcon } from 'common/ui/icons';

type Props = {
  instance: ProtocolInstancesQuery['protocolInstances']['items'][number];
  showVersion?: boolean;
  onClick: () => void;
  onDelete: () => void;
};

const ProtocolInstancesListItem = ({
  instance,
  showVersion,
  onClick,
  onDelete,
}: Props) => {
  const [expanded, setExpanded] = useState(false);

  const [canDelete, title] = useCanDeleteProtocolInstance(instance);
  const handleDelete = useCallback(
    async (event: React.MouseEvent) => {
      // Icon for delete is under <a> tag that causes redirect.
      // In order to prevent the redirect, the click event must not propagate up
      event.preventDefault();
      event.stopPropagation();

      if (canDelete) {
        setExpanded(true);
      }
    },
    [canDelete],
  );

  return (
    <EntityCardExpander
      expanded={expanded}
      key={instance.id}
      whenExpanded={
        <ConfirmDeletion onDelete={onDelete} onCancel={() => setExpanded(false)} />
      }
      expandedBorderColor={Colors.RED}
    >
      <StyledEntityCard
        key={instance.id}
        icon={<ExampleWorkflowIcon />}
        nameColumn={{ label: 'Run Name', value: instance.name }}
        authorColumn={{
          label: 'Author',
          value: instance.createdBy.displayName,
        }}
        additionalColumn={
          showVersion
            ? {
                label: 'Version',
                value: `${instance.protocol.version}`,
              }
            : undefined
        }
        dateColumn={{
          label: 'Date',
          value: new Date(instance.createdAt),
        }}
        interaction={{
          onClick,
        }}
        rightSlot={
          <StyledStack direction="row">
            <StatusBreadcrumbs breadcrumbs={instance.status} stages={['plan', 'run']} />
            <Tooltip title={expanded ? '' : title}>
              <StyledIconButton
                onClick={handleDelete}
                size="small"
                component="span"
                icon={<DeleteOutlineIcon />}
                disabled={!canDelete || expanded}
              />
            </Tooltip>
          </StyledStack>
        }
      />
    </EntityCardExpander>
  );
};

const StyledStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(4),
  paddingLeft: theme.spacing(4),
  alignItems: 'center',
}));

const StyledEntityCard = styled(EntityCard)({
  border: 'none',
});

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  alignSelf: 'center',
  margin: theme.spacing(0, 4),
  '&.Mui-disabled': {
    // avoid triggerring parent's onClick when clicking on disabled icon
    pointerEvents: 'auto',
  },
}));

export default ProtocolInstancesListItem;
