import React, { useCallback, useMemo, useState } from 'react';

import PublishProtocolDialog from 'client/app/apps/protocols/dialogs/PublishProtocolDialog';
import { DefineProtocol } from 'client/app/apps/protocols/EditProtocol/DefineProtocol/';
import { DescribeProtocol } from 'client/app/apps/protocols/EditProtocol/DescribeProtocol';
import { PersonaliseProtocol } from 'client/app/apps/protocols/EditProtocol/PersonaliseProtocol';
import withProtocolReadOnlyBar from 'client/app/apps/protocols/EditProtocol/withProtocolReadOnlyBar';
import { workflowRoutes } from 'client/app/lib/nav/actions';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import useDialog from 'common/ui/hooks/useDialog';

const enum ProtocolCreationStage {
  DEFINE_PROTOCOL,
  PERSONALISE_PROTOCOL,
  DESCRIBE_PROTOCOL,
}

export type EditProtocolProps = { navigateBack: () => void };
export type EditProtocolFC = React.FC<EditProtocolProps>;

const STAGE_TO_COMPONENT: Record<ProtocolCreationStage, EditProtocolFC> = {
  [ProtocolCreationStage.DEFINE_PROTOCOL]: withProtocolReadOnlyBar(DefineProtocol),
  [ProtocolCreationStage.PERSONALISE_PROTOCOL]:
    withProtocolReadOnlyBar(PersonaliseProtocol),
  [ProtocolCreationStage.DESCRIBE_PROTOCOL]: withProtocolReadOnlyBar(DescribeProtocol),
};

type Props = {
  id: ProtocolId;
  version: ProtocolVersion;
  workflowId?: WorkflowId;
};

export const useProtocolNavigation = ({ id, version, workflowId }: Props) => {
  const { navigate } = useNavigation();
  const [currentStage, setCurrentStage] = useState(ProtocolCreationStage.DEFINE_PROTOCOL);
  const [publishProtocolDialog, openPublishProtocolDialog] =
    useDialog(PublishProtocolDialog);

  const handleOpenPublishProtocolDialog = useCallback(() => {
    void openPublishProtocolDialog({ id, version });
  }, [id, openPublishProtocolDialog, version]);

  const previous = useMemo(() => {
    switch (currentStage) {
      case ProtocolCreationStage.DEFINE_PROTOCOL:
        return {
          label: 'Back to Editing Workflow',
          onClick: () =>
            workflowId
              ? navigate(workflowRoutes.openInWorkflowBuilder, { workflowId })
              : undefined,
        };
      case ProtocolCreationStage.PERSONALISE_PROTOCOL:
        return {
          label: 'Back to Define Protocol',
          onClick: () => setCurrentStage(ProtocolCreationStage.DEFINE_PROTOCOL),
        };
      case ProtocolCreationStage.DESCRIBE_PROTOCOL:
        return {
          label: 'Back to Personalise Protocol',
          onClick: () => setCurrentStage(ProtocolCreationStage.PERSONALISE_PROTOCOL),
        };
    }
  }, [currentStage, navigate, workflowId]);

  const next = useMemo(() => {
    switch (currentStage) {
      case ProtocolCreationStage.DEFINE_PROTOCOL:
        return {
          label: 'Next',
          onClick: () => setCurrentStage(ProtocolCreationStage.PERSONALISE_PROTOCOL),
        };
      case ProtocolCreationStage.PERSONALISE_PROTOCOL:
        return {
          label: 'Next',
          onClick: () => setCurrentStage(ProtocolCreationStage.DESCRIBE_PROTOCOL),
        };
      case ProtocolCreationStage.DESCRIBE_PROTOCOL:
        return {
          label: 'Next',
          onClick: () => handleOpenPublishProtocolDialog(),
        };
    }
  }, [currentStage, handleOpenPublishProtocolDialog]);

  return {
    Content: STAGE_TO_COMPONENT[currentStage],
    previous,
    next,
    publishProtocolDialog,
  };
};
